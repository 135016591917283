<template>
  <a :id="id" :href="sanitizedHref" :class="['linkComponent', customClass]" :style="customStyle" @click="onClick" @keydown.enter.prevent="onClick" :rel="rel" :target="target" v-bind="extraAttributes">
    <slot />
  </a>
</template>

<script>
import { analyticsMixin } from '@/analytics.js'
export default {
  name: 'LinkComponent',
  mixins: [analyticsMixin],
  props: {
    id: { type: String, default: '' },
    href: {
      type: String,
      default: '',
      validator(value) {
        return typeof value === 'string'
      }
    },
    target: {
      type: String,
      default: '_blank'
    },
    rel: {
      type: String,
      default: 'noopener noreferrer'
    },
    type: { type: String, default: '' },
    customClass: {
      type: [String, Array, Object],
      default: ''
    },
    customStyle: {
      type: [String, Object],
      default: ''
    },
    extraAttributes: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isProcessing: false
    }
  },
  computed: {
    sanitizedHref() {
      const unsafeProtocols = ['javascript:', 'data:']
      const href = this.href.trim()
      const isUnsafe = unsafeProtocols.some(protocol => href.toLowerCase().startsWith(protocol))
      return isUnsafe ? '#' : href
    }
  },
  methods: {
    async onClick(event) {
      if (event.defaultPrevented || event.ctrlKey || event.shiftKey || event.altKey || event.metaKey || event.button !== 0) {
        return
      }

      event.preventDefault()

      if (this.isProcessing) return
      this.isProcessing = true

      try {
        await this.track('link_click', { id: this.id, href: this.href, type: this.type })
      } catch (error) {
        console.error('Tracking failed:', error)
      } finally {
        this.isProcessing = false
        this.navigateToLink()
      }
    },
    navigateToLink() {
      const href = this.href.trim()
      if (href.startsWith('http') || href.startsWith('//')) {
        window.open(href, this.target, 'noopener,noreferrer')
      } else if (href.startsWith('mailto:') || href.startsWith('tel:')) {
        window.location.href = href
      } else if (this.$router && !href.startsWith('#') && !href.startsWith('javascript:')) {
        this.$router.push(href).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err)
          }
        })
      } else {
        window.location.href = href
      }
    }
  }
}
</script>
