<template>
  <div class="success">
    <slot>
      <SuccessIcon class="successIcon" />
    </slot>
    <h2>{{ t.successHeading ?? defaultMessages.heading }}</h2>
    <p>{{ t.successMessage ?? defaultMessages.message }}</p>
  </div>
</template>

<script>
import SuccessIcon from '../../assets/check.svg'
export default {
  name: 'SuccessMessage',
  components: { SuccessIcon },
  props: {
    translationNode: { type: Object, default: () => {} }
  },
  computed: {
    t() {
      return this.translationNode ?? {}
    },
    defaultMessages() {
      return {
        heading: 'Thank you for your request',
        message: 'I wish to receive any marketing information or have my information shared with third parties for purposes of providing me with marketing information.'
      }
    }
  }
}
</script>

<style lang="scss">
.success {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  margin: 2rem auto;

  svg {
    width: 10rem;
  }
}
</style>
