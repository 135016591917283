<template>
  <div class="myTradeins">
    <h1>{{ t['my_tradeins'] }}</h1>
    <Loader v-if="loading" />
    <div v-else>
      <div v-if="acceptedOffers?.length">
        <h2>{{ t['accepted_offers'] }}</h2>
        <div class="tradeinCards">
          <TradeinCard :placeholder="placeholder" v-for="ti in acceptedOffers" :key="ti.id" :tradein="ti" :to-checkout="toCheckout" :to-listing="toListing" :lg="lg" @create-order="createOrder" />
        </div>
      </div>
      <div v-if="activeOffers?.length">
        <h2>{{ t['active_offers'] }}</h2>
        <div class="tradeinCards">
          <TradeinCard :placeholder="placeholder" v-for="ti in activeOffers" :key="ti.id" :tradein="ti" :to-checkout="toCheckout" :to-listing="toListing" :lg="lg" @create-order="createOrder" />
        </div>
      </div>
      <div v-if="tradeins?.length">
        <h2>{{ t['all_tradeins'] }}</h2>
        <div class="tradeinCards pb">
          <TradeinCard :placeholder="placeholder" v-for="ti in tradeins" :key="ti.id" :tradein="ti" :to-checkout="toCheckout" :to-listing="toListing" :lg="lg" @create-order="createOrder" />
        </div>
      </div>
      <NoTradeins v-if="!tradeins?.length" />
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import { langMixin } from '../lang'
import TradeinCard from './TradeinCard.ce.vue'
import NoTradeins from './NoTradeins.ce.vue'

export default {
  name: 'SeezTradeins',
  components: { TradeinCard, Loader, NoTradeins },
  mixins: [langMixin('TRADEINS_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    toCheckout: { type: String, default: '/checkout?order={id}' },
    toListing: { type: String, default: '/listing/{id}' },
    placeholder: { type: String, default: null },
    listingDetailsUrl: { type: String, default: null }
  },
  emits: ['createOrder'],
  data: () => ({
    tradeins: [],
    activeOffers: [],
    acceptedOffers: [],
    loading: false,
    selectedTradein: null
  }),
  mounted() {
    this.loadTradeins()
  },
  methods: {
    async loadTradeins() {
      this.loading = true
      try {
        const currentUser = await window.seezSdk.getCurrentUser()
        const query = `{tradeIns(independentOnly: true userId: ${currentUser.id}) { nodes { id state converted pictures { url } listingId listing { state brand { name } model { name } variant } order {id state} make model variant year kilometrage color fuelType registrationType registrationDate carCondition accident serviceType numberOfKeys currency offerAmount totalAmount outstandingFinanceAmount customer { id }}}}`
        const results = await this.queryApi(query)
        this.tradeins = results.tradeIns.nodes.filter(t => !t.converted && t.make != null && t.state !== 'offerSent' && t.state !== 'accepted')
        this.activeOffers = results.tradeIns.nodes.filter(t => !t.converted && t.state === 'offerSent')
        this.acceptedOffers = results.tradeIns.nodes.filter(t => !t.converted && t.state === 'accepted')
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    createOrder(args) {
      this.$emit('createOrder', { ...args })
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.myTradeins {
  @include theme;
  place-self: start stretch;

  h1 {
    text-align: left;
    font-size: 1.5em;
    margin: 1em 0 0;
    color: black;
  }

  > div > div > h2 {
    display: flex;
    gap: 1em;
    align-items: center;
    color: #333333;
    margin: 1.5rem 0 0.938rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.063rem;

    > span {
      flex: auto;
    }

    > :nth-child(3) {
      flex-grow: 1;
    }
    time {
      color: #1a2634;
      font-family: 'sysrem-ui', monospace, 'Courier New', Courier;
      font-weight: bold;
    }
    > button {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      color: #757575;
      font-family: 'Biennale', sans-serif;

      @media screen and (max-width: 28rem) {
        width: 40%;
      }
    }
  }
}

.tradeinCards {
  align-items: stretch;
  display: grid;
  gap: 1.375rem;
  grid-template-columns: 100%;

  @media screen and (min-width: 32rem) {
    width: calc(100% - 1.375rem);
    grid-template-columns: repeat(2, 50%);
  }
  @media screen and (min-width: 88rem) {
    width: calc(100% - 1rem);
    grid-template-columns: repeat(3, 33.3333%);
    gap: 1rem;
  }

  .tradeinCard {
    box-sizing: border-box;
    width: 100%;
  }

  &.pb {
    padding-block-end: 2.25rem;
  }
}

.noTradeinsWrapper {
  text-align: center;
  padding: 10.313rem 0;

  svg {
    vertical-align: top;
    margin-block-end: 0.813rem;
  }

  h3,
  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #000000;
  }

  h3 {
    margin-block-end: 2px;
  }

  p {
    margin-block-end: 3.375rem;
  }
}
</style>
