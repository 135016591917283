<template>
  <ul class="specsGrid">
    <li v-for="v in valuesArray" :key="v[0]">
      <span>{{ v[0] }}</span>
      <span>{{ v[1] }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'SpecsGrid',
  props: {
    values: { type: [Object, Array], default: () => [] }
  },
  computed: {
    valuesArray() {
      if (this.values == null) return []
      if (Array.isArray(this.values)) return this.values
      return Object.entries(this.values)
    }
  }
}
</script>
<style lang="scss">
.specsGrid {
  display: grid;
  list-style-type: none;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));

  > li {
    > span:first-child::after {
      content: ':';
      margin-inline-end: 0.5em;
      font-size: 0.8em;
    }

    > span:last-child {
      font-weight: bold;
    }
  }
}
</style>
