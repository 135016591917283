<template>
  <li class="savedSearch" @click="$emit('select')">
    <div class="header">
      <div class="title-box">
        <FlagBoxIcon />
        <div>
          <p>{{ search.name }}</p>
          <span>{{ createdOn }}</span>
        </div>
      </div>
      <div class="buttons">
        <button @Click="$emit('select')">
          <ExternalLinkIcon />
        </button>
        <button @click="$emit('remove')" class="close-btn">
          <TrashIcon />
        </button>
      </div>
    </div>

    <div class="filters">
      <p>{{ t.appliedFilters }}:</p>
      <div class="list">
        <span v-for="tag in tags?.filter(t => t.label)" :key="tag.id + '_' + tag.value">{{ tag.label }}</span>
      </div>
    </div>
  </li>
</template>

<script>
import { langMixin } from '../lang'
import ExternalLinkIcon from '../../assets/external-link.svg'
import TrashIcon from '../../assets/trash-bin.svg'
import FlagBoxIcon from '../../assets/flag-box.svg'

export default {
  name: 'SeezSavedSearch',
  components: { ExternalLinkIcon, TrashIcon, FlagBoxIcon },
  mixins: [langMixin('FILTER_PANEL_COMPONENT_TRANSLATIONS')],
  props: {
    search: { type: Object, required: true },
    catalogs: { type: Object, required: true },
    to: { type: String, default: '' },
    filters: { type: Object, default: () => {} }
  },
  emits: ['select', 'remove'],
  data() {
    return {
      showEditModal: false
    }
  },
  computed: {
    createdOn() {
      if (!this.search.createdOn) return null

      const d = new Date(this.search.createdOn)
      return `${this.t.createdOn} ${Intl.DateTimeFormat(this.language, { timeZone: 'Europe/Copenhagen' }).format(d)}`
    },
    tags() {
      const filters = this.search.filterParameters
      const result = []
      if (filters.brands) result.push({ id: 'brands', keys: ['brands'], label: this.catalogs.brands?.[filters.brands] })

      if (filters.families && filters.families.length > 0) {
        if (Array.isArray(filters.families)) result.push(...filters.families.map(f => ({ id: `families${f}`, keys: ['families'], value: f, label: this.families?.find(of => of.id === f)?.name })))
        else
          result.push({
            id: `families${filters.families}`,
            keys: ['families'],
            value: filters.families,
            label: this.families?.find(of => of.id === filters.families)?.name
          })
      }

      if (filters.models && filters.models.length > 0) {
        if (Array.isArray(filters.models)) result.push(...filters.models.map(m => ({ id: `models${m}`, keys: ['models'], value: m, label: this.families?.flatMap(f => f.models)?.find(om => om.id === m)?.name })))
        else
          result.push({
            id: `models${filters.models}`,
            keys: ['models'],
            value: filters.models,
            label: this.catalogs.models[filters.models]
          })
      }

      if (filters.yearMin && filters.yearMax) result.push({ id: 'year', keys: ['yearMin', 'yearMax'], label: this.t.year_range?.replace('{{min}}', filters.yearMin)?.replace('{{max}}', filters.yearMax) })
      else if (filters.yearMin) result.push({ id: 'yearMin', keys: ['yearMin'], label: this.t.min_year?.replace('{{minYear}}', filters.yearMin) })
      else if (filters.yearMax) result.push({ id: 'yearMax', keys: ['yearMax'], label: this.t.max_year?.replace('{{maxYear}}', filters.yearMax) })

      if (filters.kilometrageMin && filters.kilometrageMax)
        result.push({
          id: 'kilometrage',
          keys: ['kilometrageMin', 'kilometrageMax'],
          label: this.t.kilomet_range?.replace('{{minKilomet}}', filters.kilometrageMin)?.replace('{{maxKilomet}}', filters.kilometrageMax)
        })
      else if (filters.kilometrageMin) result.push({ id: 'kilometrageMin', keys: ['kilometrageMin'], label: this.t.min_kilomet?.replace('{{minKilomet}}', filters.kilometrageMin.toLocaleString(this.language)) })
      else if (filters.kilometrageMax) result.push({ id: 'kilometrageMax', keys: ['kilometrageMax'], label: this.t.max_kilomet?.replace('{{maxKilomet}}', filters.kilometrageMax.toLocaleString(this.language)) })

      if (filters.prices?.minPrice && filters.prices?.maxPrice)
        result.push({
          id: 'price',
          keys: ['minPrice', 'maxPrice'],
          label: this.t.price_range?.replace('{{minPrice}}', filters.prices?.minPrice.toLocaleString(this.language))?.replace('{{maxPrice}}', filters.prices?.maxPrice.toLocaleString(this.language))
        })
      else if (filters.prices?.minPrice) result.push({ id: 'minPrice', keys: ['minPrice'], label: this.t.min_price?.replace('{{minPrice}}', filters.prices?.minPrice.toLocaleString(this.language)) })
      else if (filters.prices?.maxPrice) result.push({ id: 'maxPrice', keys: ['maxPrice'], label: this.t.max_price?.replace('{{maxPrice}}', filters.prices?.maxPrice.toLocaleString(this.language)) })

     
      if (filters.bodyTypes && filters.bodyTypes.length > 0) result.push(...filters.bodyTypes.map(bt => {
        const selectedBodyName = this.catalogs.bodyTypes?.find(b => +b.id === bt)?.name
        const translatedBodyName = this.languageResources?.Common?.BodyTypes?.[selectedBodyName] || selectedBodyName
      
        return ({ id: `bodyType${bt}`, keys: ['bodyTypes'], value: bt, label: translatedBodyName })
      }))

      if (filters.transmissions) {
        const transmissionName = this.catalogs.transmissions?.find(b => +b.id === filters.transmissions[0])?.name
        const translatedTransmission = this.languageResources?.Common?.Transmissions?.[transmissionName] || transmissionName
        result.push({ id: 'transmissions', keys: ['transmissions'], label: translatedTransmission })
      }

      if (filters.fuelTypes && filters.fuelTypes.length > 0) {
        result.push(...filters.fuelTypes.map(f => {
          const fuelTypeName = this.catalogs.fuelTypes?.find(ft => +ft.id === f)?.name
          const translatedFuelType = this.languageResources?.Common?.FuelTypes?.[fuelTypeName] || fuelTypeName
          return ({ id: `fuelTypes${f}`, keys: ['fuelTypes'], value: f, label: translatedFuelType })
        }))
      }

      if (filters.colors && filters.colors.length > 0) {
        result.push(...filters.colors.map(c => ({ id: `color${c}`, keys: ['colors'], value: c, label: this.t[c] })))
      }

      if (filters.engineSizeMax) result.push({ id: 'engineSizeMax', keys: ['engineSizeMax'], label: `${filters.engineSizeMax} L` })

      if (filters.driveTypes && filters.driveTypes.length > 0) {
        result.push(...filters.driveTypes.map(d => ({ id: `driveTypes${d}`, keys: ['driveTypes'], value: d, label: this.t[`drive_type_${d}`] })))
      }

      if (filters.numberOfDoorsMin || filters.numberOfDoorsMax) {
        let label
        if (filters.numberOfDoorsMin === filters.numberOfDoorsMax) {
          label = `${this.t.number_of_doors}: ${filters.numberOfDoorsMin}`
        } else {
          label = `${this.t.number_of_doors}: ${filters.numberOfDoorsMin} - ${filters.numberOfDoorsMax}`
        }

        result.push({ id: 'doors', keys: ['doors'], label })
      }

      if (filters.freeText && filters.freeText.length > 0) {
        result.push(...filters.freeText.split(' ').map(w => ({ id: `freeText${w}`, keys: ['freeText'], value: w, label: w })))
      }

      if (Number(filters?.numberSeat)) result.push({ id: 'seats', keys: ['numberSeat'], label: this.t.seats?.replace('{n}', filters.numberSeat) })

      if (filters?.hasPromotion) result.push({id: 'hasPromotion', keys: ['hasPromotion'], label: this.t.promozione })

      if (filters?.isNoviceDrivable && filters.isNoviceDrivable !== 'false') result.push({id: 'isNoviceDrivable', keys: ['isNoviceDrivable'], label: this.t.neopatentati  })



      return result
    }
  },
  methods: {}
}
</script>

<style lang="scss">
li.savedSearch {
  border-radius: 18px;
  border: 1px solid #9a9a9a;
  margin-block-end: 1em;
  padding: 14px;
  padding-block-end: 4px;
  cursor: pointer;
  text-align: start;
  background-color: white;

  > h2 {
    margin: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    @media screen and (min-width: 50rem) {
      justify-content: space-between;
      flex-direction: row;
    }

    .title-box {
      display: flex;
      align-items: center;
      gap: 12px;

      svg {
        color: #cc0605;
      }

      p {
        font-weight: 700;
        margin: 0;
      }

      span {
        font-size: 0.625rem;
        color: #9a9a9a;
      }
    }

    .buttons {
      display: flex;
      gap: 4px;

      > button,
      > a {
        border: 1px solid #9a9a9a;
        cursor: pointer;
        min-width: 1.625rem;
        height: 1.625rem;
        border-radius: 6px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .close-btn svg * {
        fill: red;
      }
    }
  }

  .filters {
    > p {
      font-size: 12px;
      margin: 0;
      margin-block-start: 12px;
    }

    .list {
      > span {
        padding: 5.5px 10px;
        background-color: #1b2021;
        font-size: 12px;
        font-weight: 600;
        border-radius: 20px;
        color: white;
        margin: 8px;
        margin-inline-start: 0;
        display: inline-flex;
        align-items: center;
        gap: 8px;

        svg {
          * {
            fill: white;
          }
        }
      }
    }
  }
}
</style>
