<template>
  <div :class="['pricingSummary', { showMobile: showSummaryMobile }]" v-if="order">
    <section>
      <div class="summaryMobile">
        <img :src="picture" @error="imageError = true" />
        <div class="content">
          <div>
            <h4>{{ t.viewOrderSummary ?? 't.viewOrderSummary' }}</h4>
            <div v-if="order.reservation && order.state === 'ongoing'" @click="extendReservation">
              <div class="reservationIcon" />
              <span> {{ countdown }}</span>
            </div>
          </div>
          <ChevronDownIcon @click="toggleSummary" />
        </div>
      </div>
      <div class="summaryHeader">
        <h3>{{ t.orderSummary ?? 't.orderSummary' }}</h3>
        <div v-if="order.reservation && order.state === 'ongoing'" @click="extendReservation">
          <div class="reservationIcon" />
          <span> {{ countdown }}</span>
        </div>
      </div>
      <div class="orderSummary">
        <img :src="picture" @error="imageError = true" />
        <div class="carDetails">
          <button class="listingLink" @click="goToListing">
            <div>
              <span>{{ order.listing.name }}</span>
              <span>{{ order.listing.variant }}</span>
            </div>
            <ShareIcon />
          </button>
          <div class="specs">
            <div>
              <div class="fuelType" />
              <span>{{ translatedFuelType }}</span>
            </div>
            <div>
              <div class="year" />
              <span>{{ order.listing.year }}</span>
            </div>
            <div>
              <div class="engine" />
              <span>{{ order.listing.hp }}</span>
            </div>
            <div>
              <div class="mileage" />
              <span>{{ formatNumber(order.listing.kilometrage) }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h3>{{ t.pricing ?? 't.pricing' }}</h3>
      <div class="pricingSection" v-if="pricingBreakdown?.vehiclePricing">
        <div v-for="product in pricingBreakdown.vehiclePricing.products.filter(p => p.price !== null)" :key="product.key" class="cost">
          <span>{{ t[product.name] ?? t[product.key] ?? product.name }}</span>
          <span>{{ formatNumber(product.price, 2, currency) }}</span>
        </div>
        <div class="cost emphasis" v-if="pricingBreakdown?.vehiclePricing?.total">
          <span>{{ t.totalListingPrice ?? 't.totalListingPrice' }}</span>
          <span>{{ formatNumber(pricingBreakdown?.vehiclePricing?.total, 2, currency) }}</span>
        </div>
      </div>
    </section>
    <section v-if="pricingBreakdown?.servicesPricing?.products?.length">
      <h3>{{ t.services ?? 't.services' }}</h3>
      <div class="pricingSection">
        <div class="cost" v-for="product in pricingBreakdown.servicesPricing.products.filter(p => p.price !== null)" :key="product.key">
          <span>{{ product.name }}</span>
          <span v-if="product.price">{{ formatNumber(product.price, 2, currency) }}</span>
          <span v-if="!product.price" class="included">{{ t.included ?? 't.included' }}</span>
        </div>
        <div class="cost emphasis" v-if="pricingBreakdown?.servicesPricing?.total !== null">
          <span>{{ t.totalServicesPrice ?? 't.totalServicesPrice' }}</span>
          <span>{{ formatNumber(pricingBreakdown.servicesPricing.total, 2, currency) }}</span>
        </div>
      </div>
    </section>
    <section v-if="pricingBreakdown?.tradeInPricing?.products?.find(p => p.price !== null)">
      <div class="tradeInDes">
        <h3>{{ t.tradeIn ?? 't.tradeIn' }}</h3>
        <div class="tradeInDesTooltip">
          <TooltipComponent :message="t.tradeInDes ?? 't.tradeInDes'">
            <InfoIcon />
          </TooltipComponent>
        </div>
      </div>
      <div class="pricingSection">
        <div class="cost" v-for="product in pricingBreakdown.tradeInPricing.products.filter(p => p.price !== null)" :key="product.key">
          <span v-if="product.key === 'tradeInExternalValuation'">{{ t.indicataOffer ?? 'indicataOffer' }}</span>
          <span v-else>{{ product.name }}</span>
          <span>{{ formatNumber(product.price, 2, currency) }}</span>
        </div>
      </div>
    </section>
    <section>
      <div class="pricingSection">
        <div class="pricingSubSection" v-if="pricingBreakdown?.totalPricing">
          <div class="cost" v-if="order?.payment?.type === 'financing' && pricingBreakdown?.totalPricing?.products?.find(p => p.key === 'financeableProducts')">
            <span class="provider-name">
              {{ t.totalAmount ?? 't.totalAmount' }}
              <TooltipComponent :message="t.totalFinancingAmountDisclaimer ?? 't.totalFinancingAmountDisclaimer'">
                <InfoIcon />
              </TooltipComponent>
            </span>
            <h2>{{ formatNumber(pricingBreakdown?.totalPricing?.products.find(p => p.key === 'financeableProducts').price, 2, currency) }}</h2>
          </div>
          <div class="cost" v-else-if="pricingBreakdown?.initialTransferable?.total">
            <span class="provider-name">
              {{ t.totalAmount ?? 't.totalAmount' }}
              <TooltipComponent :message="t.totalAmountDisclaimer ?? 't.totalAmountDisclaimer'">
                <InfoIcon />
              </TooltipComponent>
            </span>
            <h2>{{ formatNumber(pricingBreakdown?.initialTransferable?.total, 2, currency) }}</h2>
          </div>
        </div>
        <div class="pricingSubSection" v-if="selectedFinancingOption">
          <div class="cost" v-if="selectedFinancingOption?.calculation?.downPayment">
            <span>{{ t.downpayment ?? 't.downpayment' }}</span>
            <h2>{{ formatNumber(selectedFinancingOption?.calculation?.downPayment, 2, currency) }}</h2>
          </div>
          <div class="cost" v-if="selectedFinancingOption?.calculation?.loanAmount">
            <span>{{ t.totalFinancingAmount ?? 't.totalFinancingAmount' }}</span>
            <h2>{{ formatNumber(selectedFinancingOption?.calculation?.loanAmount, 2, currency) }}</h2>
          </div>
          <h4 v-if="selectedInsurances.length > 0" class="financingHeading">{{ t.amountWithInsurance ?? 't.amountWithInsurance' }}</h4>
          <div class="cost" v-if="selectedFinancingOption?.config?.provider?.name && monthlyPayment">
            <span class="provider-name" v-if="selectedFinancingOption?.calculation?.disclaimer">
              {{ t[order?.payment?.financing?.config?.provider?.name] ?? `t.${order?.payment?.financing?.config?.provider?.name}` }}
              <TooltipComponent :message="selectedFinancingOption?.calculation?.disclaimer ?? ''">
                <InfoIcon />
              </TooltipComponent>
            </span>
            <h2>{{ monthlyPayment }}</h2>
          </div>
          <div class="cost" v-if="showAdminFee">
            <span class="provider-name" v-if="selectedFinancingOption?.calculation?.disclaimer">
              {{ adminFeeKey || t['conditionalAdminFees'] || 't.conditionalAdminFees' }}
              <TooltipComponent :message="t['disclaimerAdminFee'] || 't.disclaimerAdminFee'">
                <InfoIcon />
              </TooltipComponent>
            </span>
            <h2>{{ adminFee }}</h2>
          </div>
          <div class="rate">
            <div v-for="(rate, key) in selectedFinancingOption?.calculation?.rates" :key="key">
              <div>{{ t[rate?.key] ?? rate?.key }} {{ Number(rate?.value) }}%</div>
            </div>
          </div>

          <section v-for="(item, key) in selectedInsurances" :key="key" class="insuranceSection">
            <span class="insuranceName">{{ t[item.name] }}</span>
            <h2 class="insuranceValue">{{ item.value?.replace('{ monthly }', t.months ?? 't.months') }}</h2>
          </section>
        </div>
      </div>
    </section>
    <div class="pricingSummaryActionBtns">
      <slot />
    </div>
    <section class="contactUs">
      <h3>{{ t.contactUs ?? 't.contactUs' }}</h3>
      <span>{{ t.contactUsWindow ?? 't.contactUsWindow' }}</span>
      <div>
        <a class="contactPhone" :href="`tel:${order.listing?.owner?.phone}`">
          {{ t.contactPhone ?? 't.contactPhone' }}
          <div />
        </a>
        <a class="contactEmail" :href="`mailto:${order.listing?.owner?.email}`">
          {{ t.contactEmail ?? 't.contactEmail' }}
          <div />
        </a>
      </div>
    </section>
    <button @click="emitCancelOrderModalToggle" class="cancel">
      <div />
      {{ t.cancelOrder ?? 't.cancelOrder' }}
    </button>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import ShareIcon from '../../assets/external-link.svg'
import ChevronDownIcon from '../../assets/chevron-down.svg'
import { calculateCountdown } from '../../logic'
import InfoIcon from '../../assets/information.svg'
import TooltipComponent from '../Tooltip.ce.vue'

export default {
  name: 'PricingSummary',
  components: { ShareIcon, InfoIcon, TooltipComponent, ChevronDownIcon },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: { order: { type: Object, required: true } },
  emits: ['showExtensionModal', 'reservationWarning', 'toggle-cancel-modal', 'toggle-pause-order-modal'],
  data() {
    let timeDifference = null
    if (this.order?.reservation?.endDate) {
      timeDifference = new Date(this.order?.reservation?.endDate) - new Date()
    }

    return {
      imageError: false,
      reservationEndDate: this.order?.reservation?.endDate,
      timeDifference: timeDifference,
      reservationLabel: null,
      showSummaryMobile: false
    }
  },
  computed: {
    selectedInsurances() {
      if (!this.order || !this.order.insuranceData?.selectedOptionIds) return []
      const selectedIds = this.order.insuranceData.selectedOptionIds

      const selectedOptions = this.order.insuranceData.options.filter(option => selectedIds.includes(option.id))
      const mappedOptions = selectedOptions.map(option => {
        const calculatePrice = item => {
          const paymentTerms = this.order.payment?.financing?.calculation?.paymentTerms
          const monthlyAmount = Number(item.amount / paymentTerms).toFixed(2)
          return `${this.formatNumber(monthlyAmount, 2, this.currency)} X ${paymentTerms} { monthly }`
        }

        const handleName = item => {
          if (item.parentId) {
            const p = this.order.insuranceData.options.filter(o => o.id === item.parentId)
            return p[0].name
          }
          return item.name
        }

        return {
          name: handleName(option),
          value: calculatePrice(option.price)
        }
      })

      return mappedOptions
    },
    selectedFinancingOption() {
      if (this.order?.payment?.type === 'financing') {
        return this.order.payment?.financingOptions.find(f => f.selected === true)
      }
      return null
    },
    pricingBreakdown() {
      const breakdowns = {
        financing: {
          initialTransferable: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'financingInitialTransferableAmount'),
          vehiclePricing: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'buyingFlowVehiclePricing'),
          servicesPricing: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'buyingFlowServicesPricing'),
          tradeInPricing: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'buyingFlowTradeInPricing'),
          totalPricing: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'buyingFlowTotalPricing')
        },
        bankTransfer: {
          initialTransferable: this.order?.pricing?.cashPayment?.breakdowns?.find(bd => bd.key === 'cashPaymentInitialTransferableAmount'),
          vehiclePricing: this.order?.pricing?.cashPayment?.breakdowns?.find(bd => bd.key === 'buyingFlowVehiclePricing'),
          servicesPricing: this.order?.pricing?.cashPayment?.breakdowns?.find(bd => bd.key === 'buyingFlowServicesPricing'),
          tradeInPricing: this.order?.pricing?.cashPayment?.breakdowns?.find(bd => bd.key === 'buyingFlowTradeInPricing'),
          totalPricing: this.order?.pricing?.cashPayment?.breakdowns?.find(bd => bd.key === 'buyingFlowTotalPricing')
        }
      }

      const tradeInExternalValuation = breakdowns[this.order?.payment?.type ?? 'bankTransfer']?.tradeInPricing?.products?.find(p => p.key === 'tradeInExternalValuation')
      const tradeInInitialOffer = breakdowns[this.order?.payment?.type ?? 'bankTransfer']?.tradeInPricing?.products?.find(p => p.key === 'tradeInOffer')

      if (tradeInExternalValuation?.price && tradeInInitialOffer?.price) {
        breakdowns[this.order?.payment?.type ?? 'bankTransfer'].tradeInPricing.products.find(p => p.key === 'tradeInExternalValuation').price = null
      } else if (!tradeInInitialOffer?.price) {
        if (breakdowns[this.order?.payment?.type ?? 'bankTransfer']?.tradeInPricing?.products?.find(p => p.key === 'tradeInFinancing')) {
          breakdowns[this.order?.payment?.type ?? 'bankTransfer'].tradeInPricing.products.find(p => p.key === 'tradeInFinancing').price = null
        }
        if (breakdowns[this.order?.payment?.type ?? 'bankTransfer']?.tradeInPricing?.products?.find(p => p.key === 'tradeInBalance')) {
          breakdowns[this.order?.payment?.type ?? 'bankTransfer'].tradeInPricing.products.find(p => p.key === 'tradeInBalance').price = null
        }
      }

      return breakdowns[this.order?.payment?.type ?? 'bankTransfer'] ?? {}
    },
    translatedFuelType() {
      const fuelName = this.order?.listing?.fuelType?.name
      return this.languageResources?.Common?.FuelTypes?.[fuelName] || fuelName
    },
    currency() {
      return this.order?.pricing?.currency
    },
    adminFeeData() {
      return this.order?.pricing?.financing?.breakdowns?.find(bk => bk.key == 'buyingFlowTotalPricing')?.products?.find(p => p.key == 'conditionalAdminFees')
    },
    showAdminFee() {
      return Boolean(this.adminFeeData)
    },
    adminFeeKey() {
      return this.adminFeeData ? this.adminFeeData.name : ''
    },
    adminFee() {
      return this.adminFeeData ? this.formatNumber(this.adminFeeData.price, 2, this.currency) : '-'
    },
    monthlyPayment() {
      const monthlyPayment = this.selectedFinancingOption?.calculation?.monthlyPayment
      const paymentTerms = this.selectedFinancingOption?.calculation?.paymentTerms
      if (monthlyPayment && paymentTerms) {
        return `${this.formatNumber(monthlyPayment, 2, this.currency)} x ${paymentTerms} ${this.t.months ?? 't.months'}`
      }
      return ' - '
    },
    picture() {
      const url = this.order.listing?.images?.[0]
      if (!url) return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      return `${import.meta.env.VITE_SEEZ_BASE_URL}/image/500x0/${url}.webp`
    },
    listingUrl() {
      return `/listing/${this.order.listing.id}`
    },
    paymentTerms() {
      return this.order?.payment?.financing?.calculation?.paymentTerms
    },
    countdown() {
      if (this.reservationEndDate) {
        if (this.timeDifference <= 0) {
          return this.t.timeIsOver
        }
        let { remainingDays, remainingHours, remainingMinutes, remainingSeconds } = calculateCountdown(this.timeDifference)
        const remainingDaysText = remainingDays > 0 ? `${remainingDays} ${this.t.days ?? 'this.t.days'} ` : ''
        const remainingHoursText = remainingHours > 0 ? `${remainingHours < 10 ? `0${remainingHours}` : remainingHours}:` : ''
        const remainingMinutesText = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes
        const remainingSecondsText = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds

        if (remainingDays === 0 && remainingHours === 0 && remainingMinutes < 5 && remainingMinutes >= 4) {
          this.$emit('reservationWarning')
        }

        const remaining = `${remainingDaysText}${remainingHoursText}${remainingMinutesText}:${remainingSecondsText} ${this.t.left ?? 'this.t.left'}`

        return remaining
      }

      return null
    }
  },
  watch: {
    timeDifference: {
      immediate: true,
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timeDifference = new Date(this.order?.reservation?.endDate) - new Date()
          }, 1000)
        }
      }
    }
  },
  methods: {
    emitCancelOrderModalToggle() {
      this.$emit('toggle-cancel-modal')
    },
    goToListing() {
      window.open(this.listingUrl, '_blank').focus()
    },
    extendReservation() {
      this.$emit('showExtensionModal')
    },
    addonPrice(addon) {
      if (addon.price) {
        return `${this.formatNumber(addon.price, 2, this.currency) ?? 't.TBD'}`
      }
      return null
    },
    addonMonthlyPrice(addon) {
      if (addon.price && addon.financeable && this.paymentTerms) {
        return `${this.formatNumber(addon.price / this.paymentTerms, 2, this.currency) ?? 't.TBD'} / ${this.t.month ?? 't.month'}`
      }
      return null
    },
    toggleSummary() {
      this.showSummaryMobile = !this.showSummaryMobile
    }
  }
}
</script>

<style lang="scss">
.pricingSummary .summaryMobile {
  display: none;
}

.pricingSummary .insuranceSection {
  justify-content: space-between;
  align-items: center;
  display: none;

  h2 {
    flex-shrink: 0;
    font-size: 18px;
    margin: 0px;
  }
}

.pricingSummary .rate {
  display: flex;
  flex-direction: row !important;
  > div {
    text-align: center;
    border-radius: 20px;
    background-color: #575959;
    margin-right: 4px;
    margin-top: 4px;
    padding: 4px 12px;
  }
}

.pricingSummary .financingHeading {
  margin: 0.5rem 0;
  font-size: 1rem;
  display: none;
}

.pricingSummary .cost {
  .provider-name {
    display: flex;
    .tooltip-container {
      margin-left: 4px;
    }
  }
  h2 {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 36rem) {
  .buyingFlow {
    .pricingSummary {
      --header-space: 100px;
      padding: 16px;
      position: fixed;
      inset: auto 0 0 0;
      height: 72px;
      padding: 1rem;
      transition: height 0.3s;
      box-sizing: border-box;

      &.showMobile {
        height: calc(100svh - var(--header-space));
        overflow-y: auto;

        .summaryMobile .content svg {
          transform: unset;
        }
      }

      .summaryHeader {
        display: none;
      }

      .orderSummary {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > img {
          display: none;
        }

        .carDetails {
          margin-top: 2rem;
          gap: 0.5rem;
        }
      }
    }

    .pricingSummary .summaryMobile {
      margin: -1rem;
      display: flex;
      gap: 10px;
      img {
        width: 106px;
        height: 72px;
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex: 1;
        padding-inline-end: 1rem;

        > div {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          > h4 {
            margin-block: 0;
          }

          > span {
            font-size: 0.75rem;
          }
        }
        svg {
          transform: scaleY(-1);
        }
      }
    }
  }
}
</style>
