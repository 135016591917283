
const CustomTypes = {
  BRAND_CONTENT: 'brand_content'
}

Object.freeze(CustomTypes)

export const prismicCms = {
  methods: {
    handleLang(selectedLanguage) {
      const languageOptions = {
        en: 'en-gb',
        da: 'da-dk'
      }
      return languageOptions[selectedLanguage] ?? 'en-gb'
    },
    async queryByUid(type = CustomTypes.BRAND_CONTENT, uid = 'mercedes', language) {
      const selectedLang = this.handleLang(language)
      const apiUrl = new URL(import.meta.env.VITE_PRISMIC_URL)
      const params = {
        page: 1,
        pageSize: 1,
        lang: selectedLang,
        ref: process.env.VUE_APP_PRISMIC_TOKEN,
        q: `[[at(my.${type}.uid, "${uid}")]]`
      }
      Object.entries(params).map(item => apiUrl.searchParams.append(item[0], item[1]))

      const response = await fetch(apiUrl)
      const result = await response.json()

      if (result.errors) throw new Error('Error loading data from prismic cms')

      return result.results[0]
    }
  }
}
