<template>
  <div class="toolTip" :class="positionClass">
    <slot />
    <div class="arrow" :class="positionClass" />
  </div>
</template>

<script>
const TooltipPosition = {
  TOP_RIGHT: 'topRight',
  TOP_LEFT: 'topLeft',
  BOTTOM_RIGHT: 'bottomRight',
  BOTTOM_LEFT: 'bottomLeft'
}

export default {
  name: 'ToolTip',
  props: {
    position: {
      type: String,
      default: '',
      validator(value) {
        return [TooltipPosition.TOP_RIGHT, TooltipPosition.TOP_LEFT, TooltipPosition.BOTTOM_RIGHT, TooltipPosition.BOTTOM_LEFT, ''].includes(value)
      }
    }
  },
  computed: {
    positionClass() {
      return `toolTip-${this.position}`
    }
  }
}
</script>

<style scoped>
.toolTip {
  position: relative;
  background-color: #f9f9f9;
  border-radius: 0.25em;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.18);
  z-index: 100;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border: 1px solid #0000001A;
}
</style>
