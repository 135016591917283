<template>
  <div :class="type ? `toast ${type}` : 'toast'">
    <div class="content">
      <p>{{ content }}</p>
    </div>
    <div v-if="content" class="close" @click="closeToast">
      x
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastComponent',
  props: {},
  data() {
    return {
      type: '',
      content: ''
    }
  },
  watch: {
    type(v) {
      if(v) {
        setTimeout(() => {
          this.type = ''
          this.content = ''
        }, 2500)
      }
    }
  },
  methods: {
    showToast(data) {
      const { type, content } = data
      this.type = type
      this.content = content
    },
    closeToast() {
      this.type = ''
      this.content = ''
    }
  }
}
</script>
