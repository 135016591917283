<template>
  <section :class="{ collapsablePanel: true, expanded: isExpanded }">
    <h3 @click="isExpanded = !isExpanded">
      {{ title }}
      <RightIcon />
    </h3>
    <div>
      <slot />
    </div>
  </section>
</template>
<script>
import RightIcon from '@/assets/right.svg'

export default {
  name: 'CollapsablePanel',
  components: { RightIcon },
  props: {
    title: { type: String, default: '' },
    expanded: { type: Boolean, default: false }
  },
  data() {
    return {
      isExpanded: this.expanded
    }
  },
  watch: {
    expanded(v) {
      this.isExpanded = v
    }
  },
  methods: {}
}
</script>
<style lang="scss">
.collapsablePanel {
  display: grid;
  grid-template-rows: 1.2em 0fr;
  transition: grid-template-rows 250ms;

  > h3 {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    cursor: pointer;

    > svg {
      transform: rotateZ(-90deg);
      stroke: color-mix(in oklab, var(--text-color) 25%, var(--background));
      width: 0.8em;
      min-height: 0.8em;
      transition: transform 250ms;
    }
  }

  > div {
    overflow: hidden;
  }

  &.expanded {
    grid-template-rows: 1.2em 1fr;

    > h3 > svg {
      transform: rotateZ(90deg);
    }
  }
}
</style>
