<template>
  <section v-if="requiredDocument">
    <div class="documentWrapper">
      <div>
        <h3>{{ t[requiredDocument.name] ?? requiredDocument.name }}</h3>
        <p>{{ t[requiredDocument.label] ?? requiredDocument.label }}</p>
        <div class="extraInfo" v-if="requiredDocument.extraInfo">
          <div class="warningIcon" />
          <span>{{ t[requiredDocument.extraInfo] ?? `t.${requiredDocument.extraInfo}` }}</span>
        </div>
      </div>
      <button data-test-id="downloadDocumentButton" class="downloadDocumentButton" v-if="requiredDocument.downloadUrl" @click="viewDocument">{{ t.viewDocument ?? 't.viewDocument' }}</button>
    </div>
    <label class="drop-area" @dragover.prevent="onDragOver" @drop.prevent="onFileDrop">
      <div>
        <div class="uploadIcon" />
        <span>{{ t.dragAndDrop ?? 't.dragAndDrop' }} </span>
        <span>{{ t.browse ?? 't.browse' }} </span>
      </div>
      <input type="file" name="photoId" id="uploadPhotoId" data-file-type="photoID" @change="fileSelected" multiple accept=".png,.jpg,.jpeg,.gif,.webp,.heic,.heif,.pdf" />
    </label>
    <ul v-if="documentsOfType.length">
      <li v-for="d in documentsOfType" :key="d.name">
        <div class="icon" />
        <span>{{ d.name }}</span>
        <a @click.prevent="handleDocumentDownload(d)" class="downloadDocumentButton"><DownloadDocument /></a>
        <button data-test-id="removeDocumentButton" class="removeDocumentButton" @click="$emit('documentsRemoved', [d])"><CrossIcon /></button>
      </li>
    </ul>
  </section>
</template>

<script>
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'
import CrossIcon from '../../assets/cross.svg'
import DownloadDocument from '../../assets/download-document.svg'

export default {
  name: 'DocumentSection',
  components: { DownloadDocument, CrossIcon },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    orderId: { type: [Number, String], required: true },
    requiredDocument: { type: Object, required: true },
    documents: { type: Object, required: true }
  },
  emits: ['fileSelected', 'documentsRemoved'],
  data() {
    return {
      loading: false,
      documentsOfType: [],
    }
  },
  watch: {
    documents() {
      this.getDocumentsOfType(this.documents)
    },
    documentType() {
      this.getDocumentsOfType(this.documents)
    }
  },
  mounted() {
    this.getDocumentsOfType()
  },
  methods: {
    async handleDocumentDownload(file) {
      const res = await this.queryApi(`mutation {getSecureDownloadUrls(fileNames: ["${file.fileName}"])}`)
      const downloadUrl = res.getSecureDownloadUrls[0]

      if (downloadUrl) {
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', file.fileName || 'download')
        link.setAttribute('target', '_blank')

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    async getDocumentsOfType() {
      if (this.documents == null || !Array.isArray(this.documents)) {
        this.documentsOfType = []
      } else {
        const compatibles = this.documents.filter(d => d.type === this.requiredDocument.type).map(d => ({ ...d }))
        const urls = await this.getDocumentUrls(compatibles)
        for (let i = 0; i < compatibles.length; i++) {
          compatibles[i].url = urls[i]
        }
        this.documentsOfType = compatibles
      }
    },
    async getDocumentUrls(files) {
      if (!files.length) {
        return []
      }
      try {
        const documents = await Promise.all(files.map(f => this.queryApi(`{getOrderDocumentUploadUrl(orderId: ${this.orderId}, document:{name:"${f.name}", type: ${f.type}}) {url}}`)))
        return documents.map(d => d.getOrderDocumentUploadUrl.url)
      } catch (e) {
        console.error(e)
        return []
      }
    },
    onDragOver(event) {
      event.preventDefault()
      event.target.closest('.drop-area').classList.add('dragover')
    },
    onFileDrop(event) {
      event.target.closest('.drop-area').classList.remove('dragover')
      this.fileSelected(event)
    },
    fileSelected(e) {
      let files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      this.$emit('fileSelected', files, this.requiredDocument.type)
      this.resetInputFile()
    },
    resetInputFile() {
      const input = this.$el.querySelector('#uploadPhotoId')
      input.value = ''
    },
    viewDocument() {
      window.open(this.requiredDocument.downloadUrl, '_blank')
    }
  }
}
</script>
