<template>
  <div class="skeleton-wrapper">
    <div class="skeleton-header pulse" />
    <div class="skeleton-selector pulse" />
    <div v-for="n in 5" :key="n" class="skeleton-fieldset">
      <div class="skeleton-legend pulse" />
      <div class="skeleton-input pulse" />
      <div class="skeleton-input short pulse" />
    </div>
    <div class="skeleton-submit pulse" />
    <div class="skeleton-disclaimer pulse" />
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoader'
}
</script>

<style scoped>
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  animation: fadeIn 2s ease-in-out;
  background-color: white;
  min-width: 40rem;
}

.skeleton-header,
.skeleton-selector,
.skeleton-legend,
.skeleton-input,
.skeleton-submit,
.skeleton-disclaimer {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.skeleton-header {
  width: 60%;
  height: 24px;
}

.skeleton-selector {
  width: 100%;
  height: 40px;
}

.skeleton-fieldset {
  display: flex;
  flex-direction: column;
}

.skeleton-legend {
  width: 40%;
  height: 20px;
  margin-bottom: 0.5rem;
}

.skeleton-input {
  width: 100%;
  height: 36px;
}

.skeleton-input.short {
  width: 50%;
}

.skeleton-submit {
  width: 30%;
  height: 40px;
}

.skeleton-disclaimer {
  width: 80%;
  height: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1s infinite ease-in-out;
}
</style>
