<template>
  <div class="paymentDetails">
    <div class="bankTransfer">
      <div class="total" v-if="pricingBreakdown.intitialTransferableAmount">
        <h3>{{ pricingBreakdown.intitialTransferableAmount.name }}</h3>
        <h3>{{ formatNumber(pricingBreakdown.intitialTransferableAmount.total, 2, currency) ?? 't.TBD' }}</h3>
      </div>
      <div class="details" v-if="pricingBreakdown.intitialTransferableAmount?.products">
        <div v-for="product in pricingBreakdown.intitialTransferableAmount.products.filter(p => p.price)" :key="product.key">
          <span>{{ product.name }}</span>
          <span>{{ formatNumber(product.price, 2, currency) ?? 't.TBD' }}</span>
        </div>
      </div>
      <div class="total" v-if="pricingBreakdown.finalTransferableAmount && order.payment?.type === 'bankTransfer'">
        <h3>{{ pricingBreakdown.finalTransferableAmount.name }}</h3>
        <h3>{{ formatNumber(pricingBreakdown.finalTransferableAmount.total, 2, currency) ?? 't.TBD' }}</h3>
      </div>
      <div class="details" v-if="pricingBreakdown.finalTransferableAmount?.products && order.payment?.type === 'bankTransfer'">
        <div v-for="product in pricingBreakdown.finalTransferableAmount.products.filter(p => p.price)" :key="product.key">
          <span>{{ product.name }}</span>
          <span>{{ formatNumber(product.price, 2, currency) ?? 't.TBD' }}</span>
        </div>
      </div>
    </div>
    <div class="transferDetails">
      <div class="paymentMethods">
        <span>{{ t.selectPaymentMethod ?? 't.selectPaymentMethod' }}</span>
        <div class="transferButtons">
          <button v-if="externalPaymentComplete !== 'true'" :disabled="loading" @click="showTransferInfo = !showTransferInfo" :class="{ active: showTransferInfo }">{{ t.bankTransferTitle ?? 't.bankTransferTitle' }}</button>
          <button :disabled="loading || !allowCreditCardTransfer" class="creditCardTransfer" @click="allowCreditCardTransfer ? $emit('creditCardTransfer') : null">
            <span>{{ t.creditCardTransfer ?? 't.creditCardTransfer' }}</span>
            <ShareIcon />
          </button>
        </div>
        <div v-if="externalPaymentComplete === 'true'">
          <div class="icon" />
          <span>{{ t.transferCompleted ?? 't.transferCompleted' }}</span>
        </div>
      </div>
      <template v-if="showTransferInfo">
        <div class="transferDetailTitle">
          <h4>{{ t.transferDetails ?? 't.transferDetails' }}</h4>
          <button class="copyDetails" @click="copyToClipboard">
            <span>{{ t.copyDetails ?? 't.copyDetails' }}</span>
            <CopyIcon />
          </button>
        </div>
        <div>
          <div v-if="order.listing?.locatedAt?.name">
            <span>{{ t.bankTransferTo ?? 't.bankTransferTo' }}</span>
            <span>{{ order.listing.locatedAt.name }}</span>
          </div>
          <div v-if="order.listing?.locatedAt?.bank?.swiftCode">
            <span>{{ t.swiftCode ?? 't.swiftCode' }}</span>
            <span>{{ order.listing.locatedAt.bank?.swiftCode }}</span>
          </div>
          <div v-if="order.listing?.locatedAt?.bank?.iban">
            <span>{{ t.ibanNumber ?? 't.ibanNumber' }}</span>
            <span>{{ order.listing.locatedAt.bank?.iban }}</span>
          </div>
          <div v-if="order.listing?.locatedAt?.bank?.account">
            <span>{{ t.bankTransferAccountNumber ?? 't.bankTransferAccountNumber' }}</span>
            <span>{{ order.listing.locatedAt.bank?.account }}</span>
          </div>
          <div v-if="order.listing?.locatedAt?.bank?.name">
            <span>{{ t.bankTransferBankName ?? 't.bankTransferBankName' }}</span>
            <span>{{ order.listing.locatedAt.bank?.name }}</span>
          </div>
          <div v-if="order.listing?.locatedAt?.bank?.registration">
            <span>{{ t.bankTransferRegistrationNumber ?? 't.bankTransferRegistrationNumber' }}</span>
            <span>{{ order.listing.locatedAt.bank?.registration }}</span>
          </div>
          <div v-if="order.id">
            <span>{{ t.bankTransferReference ?? 't.bankTransferReference' }}</span>
            <span>{{ order.id }}</span>
          </div>
        </div>
        <div class="bankTransferButton">
          <div v-if="bankTransferState === 'initiated' || bankTransferState === 'received'">
            <span>{{ t.transferInitiated ?? 't.transferInitiated' }}</span>
            <div class="icon" />
          </div>
          <button v-if="!loading && bankTransferState !== 'initiated' && bankTransferState !== 'received'" @click="initiateTransfer">{{ t.initiateBankTransfer ?? 't.initiateBankTransfer' }}</button>
          <Loader v-if="loading" />
        </div>
      </template>
    </div>
    <div v-if="requiredDocuments?.length">
      <LegalDocumentsUpload :order="order" :required-documents="requiredDocuments" :lg="language" />
    </div>
  </div>
</template>

<script>
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'
import ShareIcon from '../../assets/external-link.svg'
import LegalDocumentsUpload from './LegalDocumentsUpload.ce.vue'
import Loader from '../Loader.ce.vue'

export default {
  name: 'PaymentDetails',
  components: { Loader, LegalDocumentsUpload, ShareIcon },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true },
    externalPaymentComplete: { type: String, default: '' }
  },
  emits: ['paymentUpdated', 'creditCardTransfer', 'onCopyTransferDetails'],
  data() {
    return {
      loading: false,
      bankTransferState: this.order?.payment?.bankTransfer?.state,
      showTradeInContent: true,
      showTransferInfo: false,
      contentHeight: 0,
      pricingBreakdowns: {
        financing: {
          intitialTransferableAmount: this.order?.pricing?.financing?.breakdowns?.find(s => s.key === 'financingInitialTransferableAmount'),
          finalTransferableAmount: this.order?.pricing?.financing?.breakdowns?.find(s => s.key === 'financingFinalTransferableAmount')
        },
        bankTransfer: {
          intitialTransferableAmount: this.order?.pricing?.cashPayment?.breakdowns?.find(s => s.key === 'cashPaymentInitialTransferableAmount'),
          finalTransferableAmount: this.order?.pricing?.cashPayment?.breakdowns?.find(s => s.key === 'cashPaymentFinalTransferableAmount')
        }
      }
    }
  },
  computed: {
    allowCreditCardTransfer() {
      if (this.order?.state !== 'confirmed') {
        return false
      } else {
        if (this.order?.payment?.type === 'bankTransfer') {
          return true
        } else {
          const acceptedFinancingStates = ['approved', 'completed']
          return acceptedFinancingStates.includes(this.order?.payment?.financing?.state)
        }
      }
    },
    pricingBreakdown() {
      return this.pricingBreakdowns[this.order?.payment?.type] ?? []
    },
    currency() {
      return this.order?.pricing?.currency
    },
    requiredDocuments() {
      return this.order?.listing?.owner?.preDeliveryDocuments?.documents ?? []
    }
  },
  mounted() {
    this.contentHeight = this.$refs.contentRef?.offsetHeight
  },
  methods: {
    copyToClipboard() {
      const accountName = this.order.listing.locatedAt.name
      const accountNumber = this.order.listing.locatedAt.bank?.account
      const bankName = this.order.listing.locatedAt.bank?.name

      const text = `${this.t.bankTransferTo}: ${accountName}\n${this.t.bankTransferBankName}: ${bankName}\n${this.t.bankTransferAccountNumber}: ${accountNumber}`

      navigator.clipboard.writeText(text)

      this.$emit('onCopyTransferDetails', text)
    },
    toggleTradeInContent() {
      this.showTradeInContent = !this.showTradeInContent
      const contentHeight = this.$refs.contentRef?.offsetHeight

      if (this.showTradeInContent) {
        this.contentHeight = contentHeight
      } else {
        this.contentHeight = 0
      }
    },
    async initiateTransfer() {
      try {
        this.loading = true

        const mutation = `
        mutation initiateBankTransfer {
          initiateBankTransfer(orderId: ${this.order.id}) { bankTransfer { state }}
        }
        `
        const { initiateBankTransfer } = await this.queryApi(mutation)
        if (initiateBankTransfer) {
          this.bankTransferState = initiateBankTransfer?.bankTransfer?.state
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.toggleTradeIn {
  transform: unset;
  cursor: pointer;
  &.reverse {
    transform: scaleY(-1);
  }
}

.tradeInContent {
  transition: all 0.3s;
  overflow: hidden;
}
</style>
