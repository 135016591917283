<template>
  <ul class="linkCards">
    <li v-for="(model, index) in selectedModels" :key="index" @click="() => onClick(model)">
      <a class="linkCard" target="_blank">
        <div>
          <div v-if="logoError" class="brandInitials">{{ getInitials(model.family.brand.name) }}</div>
          <img v-else :src="getLogo(model)" :alt="getName(model)" @error="setLogoError" />
          <p>{{ getName(model) }}</p>
        </div>
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 0.5H11.25H12V1.25V5V5.75H10.5V5V3.07812L5.76562 7.78906L5.25 8.32812L4.17188 7.25L4.71094 6.73438L9.42188 2H7.5H6.75V0.5H7.5ZM0.75 1.25H3.75H4.5V2.75H3.75H1.5V11H9.9375V8.75V8H11.4375V8.75V11.75V12.5H10.6875H0.75H0V11.75V2V1.25H0.75Z" fill="black" />
        </svg>
      </a>
    </li>
    <Loader v-if="loading" />
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </ul>
</template>
<script>
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import { analyticsMixin } from '@/analytics.js'

export default {
  name: 'LinkCards',
  components: { Loader },
  mixins: [SeezSdk.vueQueryMixin, analyticsMixin],
  props: { modelIds: { type: String, default: '' }, searchUrl: { type: String, default: '' } },
  data() {
    return {
      loading: true,
      selectedModels: [],
      errorMessage: '',
      logoError: false
    }
  },
  computed: {
    modelName() {
      if (!this.selectedModel) return ''
      return `${this.selectedModel.family.brand.name} ${this.selectedModel.name}`
    }
  },
  mounted() {
    this.fetchFilters()
  },
  methods: {
    setLogoError() {
      this.logoError = true
    },
    onClick(model) {
      const baseURL = this.searchUrl ? this.searchUrl : `${import.meta.env.VITE_SEEZ_BASE_URL}/search/`

      if (this.searchUrl) {
        this.track('link_card_click', { url: this.searchUrl })
        return window.open(this.searchUrl, '_blank')
      }

      const brandName = model.family.brand.name
      const modelFilter = `models=${model.id}`
      const finalURL = `${baseURL}${brandName.toLowerCase()}?${modelFilter}`
      this.track('link_card_click', { url: finalURL })
      window.open(finalURL, '_blank')
    },
    getName(model) {
      const brandName = model.family && model.family.brand && model.family.brand.name ? model.family.brand.name : 'Unknown Brand'
      const modelName = model.name ? model.name : 'Unknown Model'
      return `${brandName} ${modelName}`
    },
    cleanBrandName(name) {
      return name
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/\s+/g, '')
    },
    getLogo(model) {
      const brandName = this.cleanBrandName(model.family.brand.name)
      return `${import.meta.env.VITE_SEEZ_BASE_URL}/brands/${brandName}.webp`
    },
    getInitials(name) {
      const words = name.split(' ')
      if (words.length === 1) {
        return words[0].slice(0, 2).toUpperCase()
      } else if (words.length >= 2) {
        return words[0].charAt(0).toUpperCase() + words[words.length - 1].charAt(0).toUpperCase()
      }
      return words[0].charAt(0).toUpperCase()
    },
    parseFilterString(filterString) {
      const filterObject = {}

      const filterParams = filterString.split('&')

      for (const param of filterParams) {
        const [key, value] = param.split('=')
        filterObject[key] = parseInt(value, 10)
      }

      return {
        modelId: filterObject.model
      }
    },
    parseStringIdsToArray(stringIds) {
      const ids = stringIds.split(',')
      const modelIds = ids.map(id => `model=${id.trim()}`)

      return modelIds
    },
    async fetchFilters() {
      try {
        const query = '{ models { id name family { brand { id name }} }}'
        const results = await this.queryApi(query)

        if (results && results.models) {
          const parsedFilters = this.parseStringIdsToArray(this.modelIds)

          const models = parsedFilters.map(filterString => {
            const filters = this.parseFilterString(filterString)
            const modelId = filters.modelId
            return results.models.find(item => item.id == modelId)
          })
          if (models.every(item => item === undefined)) {
            this.loading = false
            this.errorMessage = 'The model of this vehicle is not available'
            return
          }

          this.selectedModels = models
        } else {
          this.errorMessage = 'No models found in the response'
        }
      } catch (error) {
        console.error('Error fetching filters:', error)
        this.errorMessage = 'Failed to load models. Please try again later.'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style>
.linkCards {
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
  list-style: none;
  margin: 2rem 0;
  padding: 0;

  .linkCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20.438rem;
    padding: 0px 0.688rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    text-decoration: none;
    font-size: 0.875rem;
    cursor: pointer;
    background-color: white;
    color: black;

    > div {
      display: flex;
      align-items: center;
      gap: 0.813rem;

      img {
        width: 1.875rem;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .brandInitials {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 999px;
  }

  .error {
    color: red;
  }
}
</style>
