<template>
  <Loader v-if="loading" />
  <ListingCard v-else :details="details" :lg="language" @click-payment="e => $emit('click-payment', e)" @on-toggle-favorite="e => $emit('on-toggle-favorite', e)" />
</template>
<script>
import ListingCard from './ListingCard2.ce.vue'
import { langMixin } from './lang'
import Loader from './Loader.ce.vue'
import SeezSdk from '@/sdk.js'
export default {
  components: { ListingCard, Loader },
  mixins: [SeezSdk.vueQueryMixin, langMixin()],
  props: {
    id: { type: Number, required: true },
    data: {type: Object, default: null }
  },
  emits: ['on-toggle-favorite', 'click-payment'],
  data() {
    return {
      loading: true,
      details: this.data
    }
  },
  mounted() {
    this.loadListing()
  },
  methods: {
    async loadListing() {
      if(!this.details) {
        const query = `{listingsByIds(ids: ${this.id}) ${ListingCard.requiredFields}}`
        const result = await this.queryApi(query)
        this.details = result.listingsByIds[0]
      } 
      this.loading = false
    }
  }
}
</script>
<style lang="scss"></style>
