<template>
  <div class="ctaNextStep">
    <b>{{ t.whatsNext ?? 't.whatsNext' }}</b>
    <span>{{ t.startUploadingDocuments ?? 't.startUploadingDocuments' }}</span>
  </div>
  <div v-for="psComp in postSubmitComponents.filter(c => c.condition)" :key="psComp.ref" :class="`postSubmit${psComp.ref} ${env}`">
    <details v-if="psComp.condition" :ref="psComp.ref" :open="psComp.open === true" @toggle="e => (psComp.ref === 'postSubmitCustomerInfo' ? (customerInfoClosed = false) : null)">
      <summary :class="{ complete: psComp.completedCondition }">
        <div :class="`${psComp.ref}icon`"><component :is="psComp.icon" /></div>
        <div>
          <h3>{{ psComp.title }}</h3>
          <p>{{ psComp.subtitle }}</p>
        </div>
        <span v-if="psComp.state" :class="['state', `${psComp.completedCondition ? 'complete' : 'incomplete'}`]">{{ t[psComp.state] ?? `t.${psComp.state}` }}</span>
      </summary>
      <div>
        <AdditionalCustomerInfo v-if="psComp.ref === 'postSubmitCustomerInfo'" :order="order" @on-app-state-update="onOnAppStateUpdate" @on-customer-info-update="order => $emit('onCustomerInfoUpdate', order)" :lg="language" />
        <LegalDocumentsUpload v-if="psComp.ref === 'personalDocumentsUpload'" :order="order" :required-documents="personalDocumentsRequired" @documents-updated="docs => $emit('onDocumentsUpdated', docs)" :lg="language" />
        <LegalDocumentsUpload v-if="psComp.ref === 'tradeInDocumentsUpload'" :order="order" :required-documents="tradeInDocumentsRequired" @documents-updated="docs => $emit('onDocumentsUpdated', docs)" :lg="language" />
        <LegalDocumentsUpload v-if="psComp.ref === 'financingDocumentsUpload'" :order="order" :required-documents="financingDocumentsRequired" @documents-updated="docs => $emit('onDocumentsUpdated', docs)" :lg="language" />
        <TradeInOfferDetails v-if="psComp.ref === 'tradeInOffer'" :order="order" @on-trade-in-offer-updated="$emit('onTradeInOfferUpdate')" :lg="language" />
        <InsuranceDetails v-if="psComp.ref === 'insuranceDetails'" :order="order" :lg="language" />
        <InsuranceInfo v-if="psComp.ref === 'insuranceInfo'" :order="order" :lg="language" />
        <FinancingDetails v-if="psComp.ref === 'financingDetails'" :order="order" @on-financing-updated="f => $emit('onFinancingUpdated', f)" :lg="language" />
        <SpaDetails v-if="psComp.ref === 'spaDetails'" :order="order" :lg="language" />
        <PaymentDetails v-if="orderLoaded && psComp.ref === 'bankTransferDetails'" :order="order" :lg="language" @credit-card-transfer="$emit('creditCardTransfer', order)" @on-copy-transfer-details="copiedText => $emit('onCopyTransferDetails', copiedText)" :external-payment-complete="externalPaymentComplete" />
      </div>
    </details>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import { analyticsMixin } from '../../analytics.js'
import AdditionalCustomerInfo from './Forms/AdditionalCustomerInfo.ce.vue'
import LegalDocumentsUpload from '../Orders/LegalDocumentsUpload.ce.vue'
import InsuranceDetails from '../Orders/InsuranceDetails.ce.vue'
import InsuranceInfo from '../Orders/InsuranceInfo.vue'
import FinancingDetails from '../Orders/FinancingDetails.ce.vue'
import SpaDetails from '../Orders/SpaDetails.ce.vue'
import PaymentDetails from '../Orders/PaymentDetails.ce.vue'
import TradeInOfferDetails from './Forms/TradeIn/TradeInOfferDetails.ce.vue'
import TwoCarIcon from '../../assets/two-car.svg'
import FolderIcon from '../../assets/folder.svg'
import FinancingDocumentsIcon from '../../assets/financingDocuments.svg'
import NotePenIcon from '../../assets/note-pen.svg'
import NoteFileIcon from '../../assets/note-file.svg'
import EuroCircleIcon from '../../assets/euro-circle.svg'
import UserCircleIcon from '../../assets/user-circle.svg'
import CarCrashIcon from '../../assets/car-crash.svg'

export default {
  name: 'PostSubmitView',
  components: {
    AdditionalCustomerInfo,
    LegalDocumentsUpload,
    InsuranceDetails,
    InsuranceInfo,
    FinancingDetails,
    TradeInOfferDetails,
    SpaDetails,
    PaymentDetails,
    TwoCarIcon
  },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    order: { type: Object, required: true },
    orderLoaded: { type: Boolean, default: false }
  },
  emits: ['onDocumentsUpdated', 'onFinancingUpdated', 'onTradeInOfferUpdate', 'creditCardTransfer', 'onCopyTransferDetails', 'persistCustomerInfo', 'onCustomerInfoUpdate'],
  data() {
    return {
      loading: false,
      layoutTemplate: this.order?.listing?.owner?.layoutTemplates.find(sc => sc.name === 'postSubmitCustomerInfo'),
      customerInfoClosed: true,
      customerInfoAppState: null
    }
  },
  computed: {
    postSubmitComponents() {
      return [
        {
          ref: 'postSubmitCustomerInfo',
          open: this.customerInfoAppState?.state !== 'completed' && !this.customerInfoClosed,
          condition: this.layoutTemplate?.fields,
          completedCondition: this.customerInfoAppState?.state === 'completed',
          icon: UserCircleIcon,
          title: this.t.postSubmitCustomerInfoTitle ?? 't.postSubmitCustomerInfoTitle',
          subtitle: this.t.postSubmitCustomerInfoSubtitle ?? 't.postSubmitCustomerInfoSubtitle',
          state: this.customerInfoAppState?.state
        },
        {
          ref: 'personalDocumentsUpload',
          condition: this.order?.listing?.owner?.personalDocuments,
          completedCondition: this.personalDocumentsState === 'completed',
          icon: UserCircleIcon,
          title: this.t[this.order?.listing?.owner?.personalDocuments?.name] ?? this.order?.listing?.owner?.personalDocuments?.name,
          subtitle: this.t[this.order?.listing?.owner?.personalDocuments?.label] ?? this.order?.listing?.owner?.personalDocuments?.label,
          state: this.personalDocumentsState
        },
        {
          ref: 'tradeInDocumentsUpload',
          condition: this.order?.tradeIn && this.order?.listing?.owner?.tradeInDocuments,
          completedCondition: this.tradeInDocumentsState === 'completed',
          icon: FolderIcon,
          title: this.t[this.order.listing.owner?.tradeInDocuments?.name] ?? this.order.listing.owner?.tradeInDocuments?.name,
          subtitle: this.t[this.order.listing.owner?.tradeInDocuments?.label] ?? this.order.listing.owner?.tradeInDocuments?.label,
          state: this.tradeInDocumentsState
        },
        {
          ref: 'financingDocumentsUpload',
          condition: this.order?.payment?.type === 'financing' && this.order?.listing?.owner?.financingDocuments,
          completedCondition: this.financingDocumentsState === 'completed',
          icon: FinancingDocumentsIcon,
          title: this.t[this.order.listing.owner?.financingDocuments?.name] ?? this.order.listing.owner?.financingDocuments?.name,
          subtitle: this.t[this.order.listing.owner?.financingDocuments?.label] ?? this.order.listing.owner?.financingDocuments?.label,
          state: this.financingDocumentsState
        },
        {
          ref: 'tradeInOffer',
          condition: this.order.tradeIn,
          completedCondition: ['accepted', 'buyerRejected', 'dealerRejected', 'vehicleReceived'].includes(this.order.tradeIn?.state),
          icon: TwoCarIcon,
          title: this.t.tradeInOfferTitle ?? 't.tradeInOfferTitle',
          subtitle: this.t.tradeInOfferSubtitle ?? 't.tradeInOfferSubtitle',
          state: this.order.tradeIn?.state
        },
        {
          ref: 'insuranceDetails',
          condition: this.order.listing.targetSite?.insuranceProviders ?? this.order.listing.owner?.insuranceProviders,
          completedCondition: ['success'].includes(this.order.customerInfo.insuranceDetails?.state),
          icon: NotePenIcon,
          title: this.t.insuranceTitle ?? 't.insuranceTitle',
          subtitle: this.t.insuranceSubtitle ?? 't.insuranceSubtitle',
          state: ''
        },
        {
          ref: 'insuranceInfo',
          condition: !!this.order.insuranceData?.selectedOptionIds?.length,
          completedCondition: ['submitted', 'approved', 'rejected', 'completed'].includes(this.order.payment?.financing?.state),
          icon: CarCrashIcon,
          title: this.t.insuranceTitle ?? 't.insuranceTitle',
          subtitle: this.t.insuranceSubtitle ?? 't.insuranceSubtitle',
          state: ''
        },
        {
          ref: 'financingDetails',
          condition: this.order.payment?.type === 'financing',
          completedCondition: ['submitted', 'approved', 'rejected', 'completed'].includes(this.order.payment?.financing?.state),
          icon: NotePenIcon,
          title: this.t.financing ?? 't.financing',
          subtitle: this.t.financingSubtitle ?? 't.financingSubtitle',
          state: this.order.payment?.financing?.state
        },
        {
          ref: 'spaDetails',
          condition: true,
          completedCondition: ['signed', 'completed'].includes(this.order.spa?.state),
          icon: NoteFileIcon,
          title: this.t.spaTitle ?? 't.spaTitle',
          subtitle: this.t.spaSubtitle ?? 't.spaSubtitle',
          state: this.order.spa?.state
        },
        {
          ref: 'bankTransferDetails',
          condition: true,
          completedCondition: true,
          icon: EuroCircleIcon,
          title: this.t.bankTransferDetailsTitle ?? 't.bankTransferDetailsTitle',
          subtitle: this.t.bankTransferDetailsSubtitle ?? 't.bankTransferDetailsSubtitle',
          state: ''
        }
      ]
    },
    personalDocumentsRequired() {
      const filteredDocuments =
        this.order?.listing?.owner?.personalDocuments?.documents?.filter(d => {
          if ((d.requiredFor === 'tradeIn' && !this.order.tradeIn) || (d.requiredFor === 'coOwnerInfo' && !this.order.coOwnerInfo)) {
            return false
          }

          return true
        }) ?? []
      return filteredDocuments
    },
    personalDocumentsState() {
      let state = 'completed'
      if (this.personalDocumentsRequired) {
        for (const doc of this.personalDocumentsRequired) {
          if (!this.order.documents.find(d => doc.type === d.type)) {
            state = 'pending'
          }
        }
      }
      return state
    },
    tradeInDocumentsRequired() {
      return this.order?.listing?.owner?.tradeInDocuments?.documents
    },
    tradeInDocumentsState() {
      let state = 'completed'
      if (this.tradeInDocumentsRequired) {
        for (const doc of this.tradeInDocumentsRequired) {
          if (!this.order?.documents.find(d => doc.type === d.type)) {
            state = 'pending'
          }
        }
      }
      return state
    },
    financingDocumentsRequired() {
      return this.order?.listing?.owner?.financingDocuments?.documents
    },
    financingDocumentsState() {
      let state = 'completed'
      if (this.financingDocumentsRequired) {
        for (const doc of this.financingDocumentsRequired) {
          if (!this.order?.documents.find(d => doc.type === d.type)) {
            state = 'pending'
          }
        }
      }
      return state
    }
  },
  methods: {
    onOnAppStateUpdate(appState) {
      this.customerInfoAppState = appState
    }
  }
}
</script>

<style lang="scss"></style>
