<template>
  <div class="formTemplate">
    <div>
      <div class="formContainer">
        <div v-for="field in fields?.filter(f => isVisible(f))" :key="field.name" :style="isVisible(field) ? { 'grid-area': field.area } : {}" :class="errorsDictionary?.[field.name]?.isRequired === true || errorsDictionary?.[field.name]?.isValidated === false ? 'dynamicInputField error' : 'dynamicInputField'">
          <div v-if="field.question">
            <div v-if="field.type === 'boolean'" class="question">
              <label>
                <span v-html="t[field.question] ?? field.question" />
                <div v-if="field.values">
                  <div v-for="value in field.values" :key="value">
                    <input :name="field.name" :data-test-id="`${field.name}_${value}`" v-model="dataModel[field.name]" type="radio" :id="`${field.name}_${value}`" :value="value" />
                    <label :for="`${field.name}_${value}`">{{ t[value] ?? value }}</label>
                  </div>
                </div>
                <div v-else>
                  <input :name="field.name" :data-test-id="`${field.name}True`" v-model="dataModel[field.name]" type="radio" :id="`${field.name}True`" :value="true" />
                  <label :for="`${field.name}True`">{{ t.yes ?? 't.yes' }}</label>
                  <input :name="field.name" :data-test-id="`${field.name}False`" v-model="dataModel[field.name]" type="radio" :id="`${field.name}False`" :value="false" />
                  <label :for="`${field.name}False`">{{ t.no ?? 't.no' }}</label>
                </div>
              </label>
            </div>
            <div v-else class="question">
              <label>
                <span v-html="t[field.question] ?? field.question" />
                <multi-select v-if="field.type === 'dropdown'" :test-id="field.name" v-model="dataModel[field.name]" :multi="false" :clear-placeholder="field.name" :options="field.values?.map(v => ({ id: v, name: t[v] ?? v }))" :placeholder="handleSelectPlaceholder(field, t)" :required="field.required && isVisible(field)" />
                <input v-else :name="field.name" :data-test-id="field.name" :id="field.name" v-model="dataModel[field.name]" :required="field.required && isVisible(field)" :type="field.type" :placeholder="t[field.name] ?? field.name" />
              </label>
            </div>
          </div>
          <div v-else-if="field.type === 'lookup'">
            <label>
              <span v-html="t[field.name] ?? field.name" />
              <!-- TODO: remove the lookup-field on a separated task  -->
              <search-dropdown v-if="useSearchDropdown(field)" :multi="false" :name="field.name" :data-test-id="field.name" v-model="dataModel[field.name]" :options="field.values" :placeholder="handleSelectPlaceholder(field, t)" @update:model-value="e => evaluateFunction(e, field)" />
              <lookup-field v-else :name="field.name" :data-test-id="field.name" v-model="dataModel[field.name]" :search-options="field.values" :required="field.required && isVisible(field)" :placeholder="handleSelectPlaceholder(field, t)" @on-select="e => evaluateFunction(e, field)" />
            </label>
          </div>
          <div class="dropdown" v-else-if="field.type === 'dropdown'">
            <label>
              <span class="dropdownLabel" v-html="t[field.name] ?? field.name" />
              <multi-select :name="field.name" :test-id="field.name" v-model="dataModel[field.name]" :multi="false" :clear-placeholder="field.name" :options="field.values?.map(v => ({ id: v, name: t[v] ?? v })) ?? []" :required="field.required && isVisible(field)" :placeholder="handleSelectPlaceholder(field, t)" @on-select="e => assignValueKey(e, field)" />
            </label>
          </div>
          <div v-else-if="field.type === 'date'" class="dateTime">
            <span v-html="t[field.name] ?? field.name" />
            <date-time-slot :show-slot="false" :test-id="field.name" v-model="dataModel[field.name]" :start-date-delay-in-days="field.startDateDelay" :required="field.required && isVisible(field)" />
          </div>
          <div v-else-if="field.type === 'dateTimeSlot' && field.fields?.find(f => f.name === 'preferredTimeSlot' && f.values)" class="dateTime">
            <span v-html="t[field.name] ?? field.name" />
            <date-time-slot :test-id="field.name" v-model="dataModel[field.name]" :time-slots="field.fields.find(f => f.name === 'preferredTimeSlot' && f.values).values" :start-date-delay-in-days="field.startDateDelay" class="dateTimeSlot" :required="field.required && isVisible(field)" />
          </div>
          <div v-else-if="field.type === 'textArea'" class="textArea">
            <span v-html="t[field.name] ?? field.name" />
            <textarea :data-test-id="field.name" v-model="dataModel[field.name]" :required="field.required && isVisible(field)" :style="{ width: '100%' }" rows="3" :placeholder="handleInputPlaceholder(field, t)" />
          </div>
          <!-- Checkbox type handling -->
          <div v-else-if="field.type === 'checkbox'" class="question">
            <label class="checkboxWrapper">
              <input :name="field.name" :data-test-id="`${field.name}Checkbox`" v-model="dataModel[field.name]" :required="field.required" type="checkbox" :id="`${field.name}Checkbox`" true-value="true" false-value="false" />
              <div v-if="field.values" class="description" v-html="field.values" />
              <div v-else>{{ t[field.default] ?? `t.${field.default}` }}</div>
            </label>
          </div>
          <div class="default" v-else-if="!field.type?.toLowerCase().includes('upload')">
            <label>
              <span v-html="t[field.name] ?? field.name" />
              <input
                :name="field.name"
                :data-test-id="field.name"
                :class="{ 'has-value': dataModel[field.name] }"
                v-model="dataModel[field.name]"
                :required="field.required && isVisible(field)"
                :type="field.type ?? 'text'"
                :pattern="field.regex"
                :placeholder="handleInputPlaceholder(field, t)"
                :disabled="field.enabled === false"
                :min="minDate(field.name)"
                @invalid="
                  e => {
                    myError(e, field)
                  }
                "
              />
            </label>
          </div>
          <div v-if="field.infoLabel" class="description">
            <InfoIcon />
            {{ t[field.infoLabel] ?? 't.' + field.infoLabel }}
          </div>
          <div v-if="errorsDictionary?.[field.name]?.isValidated === false" class="description error">
            <ErrorIcon />
            {{ t[`${field.name}Invalid`] ?? `t.${field.name}Invalid` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import MultiSelect from '../MultiSelect.ce.vue'
import DateTimeSlot from '../DateTimeSlot.ce.vue'
import InfoIcon from '../../assets/info.svg'
import ErrorIcon from '../../assets/error-warning.svg'
import SearchDropdown from '../SearchDropdown.ce.vue'
import LookupField from '../LookupField.ce.vue'
import { formatDate } from '@/logic.js'

export default {
  name: 'FormTemplate',
  components: { MultiSelect, DateTimeSlot, SearchDropdown, LookupField, InfoIcon, ErrorIcon },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    preloadedData: { type: Object, default: null },
    fields: { type: Object, required: true },
    fieldsPerRow: { type: Number, default: 6 },
    errors: { type: Map, default: null },
    translationNode: { type: Object, default: null }
  },
  emits: ['updated'],
  data() {
    return {
      loading: false,
      dataModel: { ...this.preloadedData },
      formFields: this.fields
    }
  },
  computed: {
    t() {
      return this.translationNode ?? this.languageResources?.BUYING_FLOW_COMPONENT_TRANSLATIONS ?? {}
    },
    errorsDictionary() {
      return this.flattenErrors(this.errors)
    }
  },
  watch: {
    // This probably needs to go (use setCustomValidity on change instead)
    dataModel: {
      deep: true,
      handler: function () {
        this.$emit('updated', { ...this.dataModel })
      }
    }
  },
  async mounted() {
    for (const field of this.fields) {
      if (['lookup', 'checkbox'].includes(field.type) && field.lookupGeneratorFunction && window[field.lookupGeneratorFunction] && !field.values) {
        await this.getLookupFieldValues(field)
      }
    }
  },
  methods: {
    handleSelectPlaceholder(field, t) {
      const basePlaceholder = t[field.placeholder] ?? t.selectOption ?? 't.selectOption'
      const requiredIndicator = field.required ? ' *' : ''
      return `${basePlaceholder}${requiredIndicator}`
    },

    handleInputPlaceholder(field, t) {
      if (!t) return field.name
      const label = t[field.name]?.label || field.name
      return field.required ? `${t[label]} *` : t[label]
    },
    flattenErrors(errorsObject) {
      let errorsDictionary = {}

      if (errorsObject?.length) {
        const addErrors = errors => {
          const ret = []
          for (const error of errors) {
            if (error?.value.nestedErrors) {
              ret.push(...addErrors(error.value.nestedErrors))
            } else {
              ret.push(error)
            }
          }
          return ret
        }

        const newErrors = addErrors(errorsObject)

        errorsDictionary = newErrors.reduce((acc, cur) => {
          acc[cur.key] = cur.value
          return acc
        }, {})
      }

      return errorsDictionary
    },
    myError(e, field) {
      if (field.required && !this.dataModel[field.name]) {
        e.target.setCustomValidity(this.t.required ?? 't.required')
      } else if (field.regex) {
        e.target.setCustomValidity('t[field.errorMessage]')
      } else {
        e.target.setCustomValidity('')
      }
    },
    async getLookupFieldValues(field) {
      const vals = await window[field.lookupGeneratorFunction]()
      const alteredField = this.fields.find(f => f.name === field.name)
      alteredField.values = vals
    },
    evaluateFunction(e, field) {
      this.dataModel[field.name] = e
      if (field.function && window[field.function]) {
        this.dataModel = window[field.function](this.dataModel)
      }
    },
    isVisible(field) {
      if (field?.visible === false) {
        return false
      }
      if (field?.fieldEnablerFunction?.args && field?.fieldEnablerFunction?.function) {
        const visibilityFunction = new Function(...field.fieldEnablerFunction.args, field.fieldEnablerFunction?.function)
        return visibilityFunction(this.dataModel)
      }
      return true
    },
    minDate(fieldName) {
      if (fieldName === 'nationalIdExpiry') {
        return formatDate(new Date(), 'yyyy-mm-dd')
      }
    },
    useSearchDropdown(field) {
      return field.lookupGeneratorFunction === 'generateCountries' || field.lookupGeneratorFunction === 'generateNationalities' || field.lookupGeneratorFunction === 'generatePostalCodes'
    }
  }
}
</script>

<style lang="scss">
.formTemplate {
  --fieldsPerRow: 6;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .formContainer {
    display: grid;
    grid-template-columns: repeat(var(--fieldsPerRow), 1fr);
  }

  .formContainer {
    > div {
      > div {
        > label {
          display: flex;
          flex-direction: column;
          text-align: start;
        }
      }
    }

    .textArea {
      span {
        display: none;
      }
    }
  }
}
</style>
