<template>
  <div :id="id" class="userAvatar" :style="avatarStyles">
    <img v-if="avatarTheme.userAvatarIcon" :src="imageUrl" />
    <div v-else>
      <svg v-if="id === 'user'" width="22" height="25" viewBox="0 0 22 25" fill="none" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
        <path d="M 21.1 24.1 V 21.6 C 21.1 20.3 20.6 19 19.6 18 C 18.7 17.1 17.4 16.5 16.1 16.5 H 5.9 C 4.6 16.5 3.3 17.1 2.4 18 C 1.4 19 0.9 20.3 0.9 21.6 V 24.1" />
        <path d="M 11 11.47 C 13.8 11.47 16.07 9.2 16.07 6.4 C 16.07 3.6 13.8 1.33 11 1.33 C 8.2 1.33 5.93 3.6 5.93 6.4 C 5.93 9.2 8.2 11.47 11 11.47 Z" />
      </svg>
      <svg v-else width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.410902 10.9948L0.410902 17.9137L2.79961 17.9137L2.98329 17.9137L3.10579 18.0506C5.0432 20.2152 7.85984 21.5786 10.9947 21.5786C16.8399 21.5786 21.5785 16.8401 21.5785 10.9948C21.5785 5.1495 16.8399 0.411024 10.9947 0.411025C5.14938 0.411025 0.410901 5.1495 0.410902 10.9948Z" stroke="#00FFC2" stroke-width="0.821816" />
        <path d="M5.6977 13.0728C5.35859 13.0728 5.08337 12.7982 5.08337 12.4599V9.06433C5.08337 8.726 5.35859 8.45142 5.6977 8.45142C6.03681 8.45142 6.31203 8.726 6.31203 9.06433V12.4599C6.31203 12.7982 6.03681 13.0728 5.6977 13.0728Z" fill="#00FFC2" />
        <path d="M16.3256 13.0728C15.9865 13.0728 15.7113 12.7982 15.7113 12.4599V9.06433C15.7113 8.726 15.9865 8.45142 16.3256 8.45142C16.6647 8.45142 16.94 8.726 16.94 9.06433V12.4599C16.94 12.7982 16.6647 13.0728 16.3256 13.0728Z" fill="#00FFC2" />
        <path d="M7.66352 10.2535C7.32441 10.2535 7.04919 9.97889 7.04919 9.64056V7.47083C7.04919 7.1325 7.32441 6.85791 7.66352 6.85791C8.00263 6.85791 8.27785 7.1325 8.27785 7.47083V9.64056C8.27785 9.97889 8.00263 10.2535 7.66352 10.2535Z" fill="#00FFC2" />
        <path d="M14.1755 7.55638C13.8364 7.55638 13.5612 7.28179 13.5612 6.94346V6.3993C13.5612 6.06096 13.8364 5.78638 14.1755 5.78638C14.5146 5.78638 14.7898 6.06096 14.7898 6.3993V6.94346C14.7898 7.28179 14.5146 7.55638 14.1755 7.55638Z" fill="#00FFC2" />
        <path d="M9.75227 16.6277C9.41316 16.6277 9.13794 16.3532 9.13794 16.0148V13.8451C9.13794 13.5068 9.41316 13.2322 9.75227 13.2322C10.0914 13.2322 10.3666 13.5068 10.3666 13.8451V16.0148C10.3666 16.3532 10.0914 16.6277 9.75227 16.6277Z" fill="#00FFC2" />
        <path d="M7.66352 14.9484C7.32441 14.9484 7.04919 14.6738 7.04919 14.3354V11.3934C7.04919 11.0551 7.32441 10.7805 7.66352 10.7805C8.00263 10.7805 8.27785 11.0551 8.27785 11.3934V14.3354C8.27785 14.6738 8.00263 14.9484 7.66352 14.9484Z" fill="#00FFC2" />
        <path d="M11.9024 14.458C11.5633 14.458 11.2881 14.1834 11.2881 13.8451V8.08362C11.2881 7.74529 11.5633 7.4707 11.9024 7.4707C12.2415 7.4707 12.5167 7.74529 12.5167 8.08362V13.8451C12.5167 14.1834 12.2415 14.458 11.9024 14.458Z" fill="#00FFC2" />
        <path d="M14.114 16.2329C13.7749 16.2329 13.4996 15.9583 13.4996 15.62V8.69666C13.4996 8.35833 13.7749 8.08374 14.114 8.08374C14.4531 8.08374 14.7283 8.35833 14.7283 8.69666V15.62C14.7283 15.9583 14.4531 16.2329 14.114 16.2329Z" fill="#00FFC2" />
        <path d="M9.69087 12.6194C9.35176 12.6194 9.07654 12.3448 9.07654 12.0065V5.99988C9.07654 5.66155 9.35176 5.38696 9.69087 5.38696C10.03 5.38696 10.3052 5.66155 10.3052 5.99988V12.0065C10.3052 12.3448 10.03 12.6194 9.69087 12.6194Z" fill="#00FFC2" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    url: { type: String, default: '' },
    avatarTheme: {
      type: Object,
      required: true,
      default: () => ({
        userAvatarWidth: '38px',
        userAvatarHeight: '38px',
        userAvatarBgColor: '',
        userAvatarBorderRadius: '2px'
      })
    },
    id: { type: String, required: true },
    botConfig: { type: Object, default: () => {} }
  },
  computed: {
    imageUrl() {
      const avatarPath = this.url || this.avatarTheme.userAvatarIcon
      return `${import.meta.env.VITE_CHAT_IMAGE_URL}/image/0x0/${avatarPath}.webp`
    },
    avatarStyles() {
      const defaultStyles = {
        '--avatar-width': this.avatarTheme.userAvatarWidth,
        '--avatar-height': this.avatarTheme.userAvatarHeight,
        '--avatar-border-radius': this.getBorderRadius()
      }

      return defaultStyles
    }
  },
  methods: {
    getBorderRadius() {
      //TODO: remove when we implement all config fields on marketplace
      if (!this.botConfig.borderRadius) return '3px'

      if (this.botConfig?.borderRadius === 'square') {
        return '6px'
      }
      return '999px'
    }
  }
}
</script>

<style>
.userAvatar {
  border-radius: var(--avatar-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: auto;
    height: auto;
    max-width: 28px;
    object-fit: contain;
  }

  > div {
    margin-top: 0.1em;
  }
}
</style>
