<template>
  <div class="inputCode" dir="ltr">
    <input v-for="(item, index) in items" :key="index" :disabled="disabled" :class="{ errorFlag, disabled }" type="text" maxlength="1" />
  </div>
</template>

<script>
export default {
  name: 'InputCode',
  props: {
    length: { type: Number, required: true, default: 6 },
    error: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  emits: ['value'],
  data() {
    return {
      items: [1, 2, 3, 4, 5, 6],
      errorFlag: false
    }
  },
  watch: {
    error(value) {
      if (value) this.errorFlag = true
    }
  },
  mounted() {
    const inputLength = Array.from(Array(this.length).keys())
    this.items = inputLength

    const inputs = this.$el.querySelectorAll('input')

    inputs.forEach((inputs, i) => {
      inputs.dataset.index = i
      inputs.addEventListener('paste', this.handleOnPasteCode)
      inputs.addEventListener('keyup', this.handleCodeInput)
    })
  },
  methods: {
    handleOnPasteCode(e) {
      const data = e.clipboardData.getData('text')
      const inputs = this.$el.querySelectorAll('input')
      const value = data.split('')

      if (value.length === inputs.length) {
        inputs.forEach((input, index) => (input.value = value[index]))
        this.saveCode()
      }
    },
    saveCode() {
      const inputs = this.$el.querySelectorAll('input')
      let code = ''
      inputs.forEach(input => (code += input.value))
      this.$emit('value', code)
    },
    handleCodeInput(e) {
      const inputs = this.$el.querySelectorAll('input')
      const input = e.target

      let value = input.value
      input.value = ''
      input.value = value ? value[0] : ''

      const fieldIndex = input.dataset.index

      if (this.error) {
        inputs.forEach(input => (input.value = ''))
      }

      if (value.length > 0 && fieldIndex < inputs.length - 1) {
        input.nextElementSibling.focus()
      }

      if (e.keyCode == '37') {
        input.previousElementSibling.focus()
      }

      if (e.keyCode == '39') {
        input.nextElementSibling.focus()
      }

      if (e.key === 'Backspace' && fieldIndex > 0) {
        inputs[fieldIndex].value = ''
        input.previousElementSibling.focus()

        if (this.error) {
          this.errorFlag = !this.errorFlag
          inputs[0].focus()
        }
      }

      if (fieldIndex == inputs.length - 1) {
        this.saveCode()
      }
    }
  }
}
</script>

<style lang="scss">
.inputCode {
  width: 100%;
  display: flex;
  margin: auto;
  gap: 0.6rem;
  justify-content: space-around;

  > input {
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    text-align: center;
    font-family: var(--base-font);
    font-weight: 700;
    width: 2.875rem;
    height: 2.875rem;
    font-size: 2.1rem;

    @media screen and (max-width: 36rem) {
      width: 2rem;
      height: 2rem;
      font-size: 1.25rem;
    }
  }

  > input:focus {
    outline: none;
    border: 1px solid var(--highlight);
    box-shadow: 0px 0px 2px var(--highlight);
  }

  > input.errorFlag {
    border: 1px solid red;
    box-shadow: 0px 0px 2px red;
  }

  > input.disabled {
    opacity: 0.5;
    background-color: lightgray;
    cursor: not-allowed;
  }
}
</style>
