<template>
  <div class="myOrders">
    <h1>{{ t['my_orders'] }}</h1>
    <Loader v-if="loading" />
    <div v-else>
      <div v-if="active != null">
        <h2>
          <span>{{ t['current_order'] }}</span>
        </h2>
        <div class="orderCards">
          <order-card :listing-details-url="listingDetailsUrl" :placeholder="placeholder" @confirm-cancel="cancelActiveOrder" @track="p => track(p)" :order="active" :to="to" :lg="lg" />
        </div>
      </div>
      <div v-if="orders?.length">
        <h2>{{ t['previous_orders'] }}</h2>
        <div class="orderCards pb">
          <order-card :listing-details-url="listingDetailsUrl" :placeholder="placeholder" @track="p => track(p)" v-for="order in orders" :key="order.id" :order="order" :to="to" :lg="lg" />
        </div>
      </div>
      <NoOrders v-if="!orders?.length && !active" />
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import OrderCard from './OrderCard.ce.vue'
import Loader from '../Loader.ce.vue'
import NoOrders from './NoOrders.ce.vue'
import { langMixin } from '../lang'
import { analyticsMixin } from '../../analytics.js'

export default {
  name: 'SeezOrders',
  components: { OrderCard, Loader, NoOrders },
  mixins: [langMixin('ORDERS_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    to: { type: String, default: '/checkout?order={id}' },
    placeholder: { type: String, default: null },
    listingDetailsUrl: { type: String, default: null }
  },
  data: () => ({
    orders: [],
    active: null,
    loading: false,
    askConfirmation: false
  }),
  mounted() {
    this.loadOrders()
  },
  methods: {
    async loadOrders() {
      this.loading = true
      try {
        const query = 'fragment orderFields on Order { id state createdOn listing { id model { name family { brand { name }}} name year kilometrage retailPrice { value currency } images variant }} { user { activeOrder { ...orderFields } orders { ...orderFields }}}'
        const { user } = await this.queryApi(query)
        this.active = user.activeOrder
        const orders = user.orders.filter(o => !['ongoing', 'confirmed', 'submitted'].includes(o.state))
        orders.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
        this.orders = orders
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    async cancelActiveOrder() {
      this.loading = true
      this.track('pj_purchase_canceled')
      await this.queryApi(`mutation {cancelOrder(orderId: ${this.active.id}) {id} }`)
      this.loadOrders()
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.myOrders {
  @include theme;
  place-self: start stretch;

  h1 {
    text-align: left;
    font-size: 1.5em;
    margin: 1em 0 0;
    color: black;
  }

  > div > div > h2 {
    display: flex;
    gap: 1em;
    align-items: center;
    color: #333333;
    margin: 1.5rem 0 0.938rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.063rem;

    > span {
      flex: auto;
    }

    > :nth-child(3) {
      flex-grow: 1;
    }
    time {
      color: #1a2634;
      font-family: 'sysrem-ui', monospace, 'Courier New', Courier;
      font-weight: bold;
    }
    > button {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      color: #757575;
      font-family: 'Biennale', sans-serif;

      @media screen and (max-width: 28rem) {
        width: 40%;
      }
    }
  }
}

.orderCards {
  align-items: stretch;
  display: grid;
  gap: 1.375rem;
  grid-template-columns: 100%;

  @media screen and (min-width: 32rem) {
    width: calc(100% - 1.375rem);
    grid-template-columns: repeat(2, 50%);
  }
  @media screen and (min-width: 88rem) {
    width: calc(100% - 1rem);
    grid-template-columns: repeat(3, 33.3333%);
    gap: 1rem;
  }

  .orderCard {
    box-sizing: border-box;
    width: 100%;
  }

  &.pb {
    padding-block-end: 2.25rem;
  }
}

.noOrdersWrapper {
  text-align: center;
  padding: 10.313rem 0;

  svg {
    vertical-align: top;
    margin-block-end: 0.813rem;
  }

  h3,
  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #000000;
  }

  h3 {
    margin-block-end: 2px;
  }

  p {
    margin-block-end: 3.375rem;
  }
}
</style>
