<template>
  <div class="orderAddons">
    <div v-if="addons">
      <div v-for="category in sortedAddons" :key="category.category">
        <h2>{{ category.category }}</h2>
        <div class="addons">
          <div v-for="addon in category.addons" :key="addon.name" class="addonCard" @click="selectedAddonToView = addon">
            <h3>{{ addon.name }}</h3>
            <span>{{ addon.description }}</span>
            <div :class="`addonPrice ${addon.price ? '' : 'included'}`">
              <div>
                <div v-if="addon.price">
                  <span>{{ t.addonPrice ?? 't.addonPrice' }}</span>
                  <span>{{ addonPrice(addon) }}</span>
                </div>
                <div v-if="addon.price && addon.financeable && paymentTerms">
                  <span>{{ t.addonMonthlyPrice ?? 't.addonMonthlyPrice' }}</span>
                  <span>{{ addonMonthlyPrice(addon) }}</span>
                </div>
              </div>
              <button v-if="!addon.loading" @click.stop.prevent="selectAddon(addon)" :class="input.addonIds.includes(Number(addon.id)) ? 'selected' : ''">
                <div />
                {{ buttonDisplay(addon) }}
              </button>
              <Loader v-if="addon.loading" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-if="!addons" />
    <Modal v-if="selectedAddonToView" @close="selectedAddonToView = null">
      <div :class="`addonDetailModal ${selectedAddonToView.price ? '' : 'included'}`">
        <div class="addonName">{{ selectedAddonToView.name }}</div>
        <div class="addonDescription">{{ selectedAddonToView.description }}</div>
        <div class="addonPrice" v-if="selectedAddonToView.price">{{ t.addonPrice ?? 't.addonPrice' }}: {{ addonPrice(selectedAddonToView) }}</div>
        <div class="addonMonthlyPrice" v-if="selectedAddonToView.price && selectedAddonToView.financeable && paymentTerms">{{ t.addonMonthlyPrice ?? 't.addonMonthlyPrice' }}: {{ addonMonthlyPrice(selectedAddonToView) }}</div>
        <button v-if="!selectedAddonToView.loading" @click.stop.prevent="selectAddon(selectedAddonToView)" :class="input.addonIds.includes(Number(selectedAddonToView.id)) ? 'selected' : ''">{{ buttonDisplay(selectedAddonToView) }}</button>
        <Loader v-if="selectedAddonToView.loading" />
      </div>
    </Modal>
  </div>
</template>

<script>
import SeezSdk from '../../../sdk.js'
import { langMixin } from '../../lang'
import Modal from './../../Modal.ce.vue'
import Loader from './../../Loader.ce.vue'

export default {
  name: 'OrderAddons',
  components: { Modal, Loader },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true },
    addons: { type: Array, default: null }
  },
  emits: ['persistInput', 'addonsUpdated'],
  data() {
    return {
      loading: false,
      selectedAddonToView: null,
      input: {
        addonIds: this.order.customization.addons?.map(addon => Number(addon.id)) ?? []
      }
    }
  },

  computed: {
    calculationQuery() {
      return 'apr aopBeforeTax financedAmount loanAmount downPayment downPaymentPct totalPayable totalLoanCost paymentTerms monthlyPayment nominalInterestRate interestType interestRate disclaimer rates { key value }'
    },
    paymentMethodQuery() {
      return 'key name breakdowns {key name products {key name price description} total}'
    },
    sortedAddons() {
      const groupedAddons = {}

      if (this.addons) {
        this.addons.forEach(addon => {
          if (!groupedAddons[addon.category.name]) {
            groupedAddons[addon.category.name] = []
          }

          addon.loading = false

          groupedAddons[addon.category.name].push(addon)
        })
      }

      const result = Object.entries(groupedAddons).map(([category, addonsArray]) => ({
        category,
        addons: addonsArray
      }))

      return result
    },
    paymentTerms() {
      return this.order?.payment?.financing?.calculation?.paymentTerms
    },
    currency() {
      return this.order.listing.owner.currency
    }
  },
  watch: {
    input: {
      deep: true,
      handler: function () {
        this.$emit('persistInput', { ...this.input })
      }
    }
  },
  methods: {
    addonPrice(addon) {
      return `${this.formatNumber(addon.price, 2, this.currency) ?? 't.TBD'}`
    },
    addonMonthlyPrice(addon) {
      return `${this.formatNumber(addon.price / this.paymentTerms, 2, this.currency) ?? 't.TBD'} / ${this.t.month ?? 't.month'}`
    },
    buttonDisplay(addon) {
      if (addon.price) {
        if (this.input.addonIds.includes(Number(addon.id))) {
          return this.t.selected ?? 't.selected'
        }
        return this.t.add ?? 't.add'
      }
      return this.t.included ?? 't.included'
    },
    selectAddon(addon) {
      if (addon.price) {
        if (this.input.addonIds.includes(Number(addon.id))) {
          const addonIndex = this.input.addonIds.indexOf(Number(addon.id))
          this.input.addonIds.splice(addonIndex, 1)
        } else {
          this.input.addonIds.push(Number(addon.id))
        }
        this.updateCustomization(addon)
      } else {
        return
      }
    },
    async updateCustomization(addon) {
      try {
        addon.loading = true

        const input = {
          orderId: this.order.id,
          input: { addonIds: this.input.addonIds }
        }
        const mutation = `
          mutation uc($orderId: ID!, $input: OrderCustomizationInput) {
            updateCustomization(orderId: $orderId, input: $input) {
              pricing { addonsPrice financing {${this.paymentMethodQuery}} cashPayment {${this.paymentMethodQuery}}}
              addons { id name price}
            }
          }
        `
        const { updateCustomization } = await this.queryApi(mutation, input)

        if (this.order?.payment?.type === 'financing') {
          const loanCalculation = await this.loanCalculation()
          this.$emit('addonsUpdated', updateCustomization, loanCalculation)
        } else {
          this.$emit('addonsUpdated', updateCustomization)
        }
      } catch (e) {
        console.error(e)
      } finally {
        addon.loading = false
      }
    },
    async loanCalculation() {
      const loanCalculationInput = {
        input: {
          selectedProvider: this.order.payment?.financing?.config?.provider?.name,
          downPayment: Number(this.order.payment?.financing?.calculation?.downPayment),
          paymentTerms: this.order.payment?.financing?.calculation?.paymentTerms,
          interestType: !this.order?.payment?.financing?.config?.interestTypes?.length ? undefined : this.order.financing?.calculation?.interestType
        }
      }
      const loanCalculationQuery = `
        query loanCalculations($input: LoanCalculationInput) {
          loanCalculations(
            orderId: ${this.order.id}
            input: $input
          ) {
            selected
            calculation {${this.calculationQuery}}
            errors { field errorCode }
          }
        }
      `
      const { loanCalculations } = await this.queryApi(loanCalculationQuery, loanCalculationInput)
      return loanCalculations
    }
    // async updateOrderFinancing() {
    //   const loanCalculationInput = {
    //     id: this.order.id,
    //     payment: {
    //       financing: {
    //         selectedProvider: this.order.payment?.financing?.config?.provider?.name,
    //         downPayment: Number(this.order.payment?.financing?.calculation?.downPayment),
    //         paymentTerms: this.order.payment?.financing?.calculation?.paymentTerms,
    //         interestType: !this.order?.payment?.financing?.config?.interestTypes?.length ? undefined : this.order.financing?.calculation?.interestType
    //       }
    //     }
    //   }

    //   const updateOrderMutation = `
    //     mutation updateOrder($id: ID!, $payment: UpdatePaymentInput) {
    //       updateOrder(orderId: $id payment: $payment) {
    //         payment {
    //           financing {
    //             selected
    //             errors { field errorCode }
    //             userInput { selectedProvider downPayment interestType paymentTerms }
    //             config { interestTypes terms downPayment { min max default } provider { name logo }}
    //             calculation {${this.calculationQuery}}
    //           }
    //           financingOptions {
    //             config { interestTypes terms downPayment { min max default } provider { name logo }}
    //             selected
    //             calculation {${this.calculationQuery}}
    //           }
    //         }
    //       }
    //     }
    //   `
    //   const { updateOrder } = await this.queryApi(updateOrderMutation, loanCalculationInput)
    //   return updateOrder?.payment
    // }
  }
}
</script>
