<template>
  <button class="delete-button" @click="showConfirmModal = true">
    <slot>Delete user</slot>
  </button>
  <DeleteUserModal :is-open="showConfirmModal" @close="handleCloseConfirm" :lg="lg" />
</template>

<script>
import DeleteUserModal from './DeleteUserModal.ce.vue'

export default {
  name: 'DeletingAccount',
  components: {
    DeleteUserModal
  },
  props: {
    lg: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      showConfirmModal: false
    }
  },
  methods: {
    handleCloseConfirm() {
      this.showConfirmModal = false
    }
  }
}
</script>

<style lang="scss"></style>
