<template>
  <div class="spaDetails">
    <strong v-if="order.spa">{{ t.spaExcited ?? 't.spaExcited' }}</strong>
    <p v-if="order.spa">{{ t.spaBinding ?? 't.spaBinding' }}</p>
    <p v-else>{{ t.spaNotGeneratedYet ?? 't.spaNotGeneratedYet' }}</p>
    <iframe frameborder="0" v-if="order?.spa?.previewHTML" :srcdoc="order?.spa?.previewHTML" />
    <div class="apply">
      <button v-if="!loading" @click="signNow" :disabled="!canSign">{{ t.spaSignNow ?? 't.spaSignNow' }}<ExternalLinkIcon /></button>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import ExternalLinkIcon from '../../assets/external.svg'
export default {
  name: 'SpaDetails',
  components: {
    Loader,
    ExternalLinkIcon
  },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: { order: { type: Object, required: true } },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    canSign() {
      if (['sent', 'salesRepSigned'].includes(this.order?.spa?.state)) {
        return true
      }
      return false
    }
  },
  methods: {
    async signNow() {
      try {
        this.loading = true
        const query = 'mutation sign($orderId: ID!, $returnUrl: String) { createSigningView(orderId: $orderId, returnUrl: $returnUrl) { redirectUrl documentId }}'
        const { createSigningView } = await this.queryApi(query, { orderId: this.order.id, returnUrl: window.location.href })
        // eslint-disable-next-line require-atomic-updates
        window.location = createSigningView.redirectUrl
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.spaDetails {
  padding: 2rem;

  > p {
    text-align: center;
    margin: 0;

    & + p {
      margin-block-start: 1rem;
    }
  }

  > iframe {
    width: 100%;
    aspect-ratio: 100 / 117;
    overflow: auto;
    border: 1px solid; // var(--border-color);
    margin-block-start: 2rem;
  }

  > .apply {
    // background-color: var(--accented-background);
    margin: 2rem -2rem -2rem;
    padding: 2rem;
    text-align: center;

    > button {
      // color: var(--background);
      // background-color: var(--highlight);
      border: none;
      border-radius: 3em;
      font-size: 1em;
      padding: 0.5em 1em;
    }
  }
}
</style>
