<template>
  <div class="orderTradeIns">
    <span>{{ t.doYouWantToTradeIn ?? 't.doYouWantToTradeIn' }}</span>
    <div class="selector">
      <input type="radio" id="yes" :value="true" v-model="input.willTradeIn" />
      <label for="yes"> {{ t.applyForTradeIn ?? 't.applyForTradeIn' }} </label>
      <input type="radio" id="no" :value="false" v-model="input.willTradeIn" />
      <label for="no"> {{ t.skipTradeIn ?? 't.skipTradeIn' }} </label>
      <Loader v-if="loading" />
    </div>
    <div v-if="input.willTradeIn" class="tradeInDetailCards">
      <div v-if="tradeIns?.length">{{ t.existingTradeIns ?? 't.existingTradeIns' }}</div>
      <section v-if="showTradeInInfoBanner" class="tradeInInfoBanner">
        <InfoIcon />
        <p>
          {{ t.editIncomplete || 't.editIncomplete' }}
          <button data-test-id="tradeInSectionButton" class="goToTradeInButton" @click="$emit('click-go-to-tradeIn')">
            {{ t.tradeInSection || 't.tradeInSection' }}
            <div class="linkIcon" /></button
          ><span>{{ t.underAccount }}</span>
        </p>
      </section>
      <div v-if="tradeIns">
        <div v-for="tradeIn in tradeIns" :key="tradeIn">
          <TradeInDetailCard @edit-trade-in="editTradeIn" :trade-in="tradeIn" :selected="assignedTradeIn ? assignedTradeIn.id === tradeIn.id : tradeIn.orderId === order.id" @on-select="onTradeInSelect(tradeIn)" @on-view-details="onViewTradeIn(tradeIn)" />
        </div>
      </div>
      <Loader v-if="!tradeIns" />
      <div>{{ t.newTradeIn ?? 't.newTradeIn' }}</div>
      <div>
        <div>
          <TradeInDetailCard @edit-trade-in="editTradeIn" :trade-in="newTradeIn" :selected="newTradeIn ? true : false" @on-select="onTradeInSelect(newTradeIn)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../../sdk.js'
import { langMixin } from '../../../lang'
import Loader from './../../../Loader.ce.vue'
import TradeInDetailCard from './TradeInDetailCard.ce.vue'
import InfoIcon from '../../../../assets/info.svg'

export default {
  name: 'OrderTradeIns',
  components: { Loader, InfoIcon, TradeInDetailCard },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true },
    tradeIns: { type: Array, default: null },
    showTradeIns: { type: Boolean, default: null },
    newTradeIn: { type: Object, default: null }
  },
  emits: ['persistInput', 'createNewTradeIn', 'assignTradeIn', 'assignedTradeIn', 'editTradeIn', 'click-go-to-tradeIn'],
  data() {
    return {
      loading: false,
      openTradeInForm: false,
      showTradeInInfoBanner: true,
      input: {
        willTradeIn: this.order.tradeIn ? true : this.showTradeIns,
        tradeIn: this.order.tradeIn
      }
    }
  },
  computed: {
    tradeInQuery() {
      return 'id state orderId converted pictures { url type } listingId listing { state brand { name } model { name } variant } order {id state} make model variant year kilometrage color engine fuelType transmission seats registrationType registrationDate carCondition accident accidentType numberOfAccidents serviceType numberOfKeys customerComment currency offerAmount totalAmount deduction maxExternalValuationPrice outstandingFinanceAmount customer { id }'
    },
    assignedTradeIn() {
      if (this.tradeIns?.find(ti => ti.orderId === this.order.id)) {
        return this.tradeIns?.find(ti => ti.orderId === this.order.id)
      }
      return null
    },
    tradeInIsIncomplete() {
      if (this.tradeIns?.find(ti => ti.orderId === this.order.id)) {
        const selected = this.tradeIns?.find(ti => ti.orderId === this.order.id)
        return ['draft', 'pending', 'inReview'].includes(selected.state)
      }
      return false
    }
  },
  watch: {
    tradeIns(v) {
      if (v && v.length > 0) {
        this.showTradeInInfoBanner = this.tradeInIsIncomplete
      }
    },
    input: {
      deep: true,
      handler: function () {
        this.$emit('persistInput', { ...this.input })
      }
    }
  },

  methods: {
    onTradeInSelect(tradeIn) {
      if (!['draft', 'pending', 'inReview'].includes(tradeIn?.state)) {
        this.showTradeInInfoBanner = false
      } else {
        this.showTradeInInfoBanner = true
      }

      if (tradeIn) {
        this.assignTradeIn(tradeIn.id)
        if (['offerSent', 'accepted'].includes(tradeIn?.state)) {
          this.showTradeInInfoBanner = false
        }
      } else {
        this.$emit('createNewTradeIn')
      }
    },
    editTradeIn(tradeInId) {
      this.$emit('editTradeIn', tradeInId)
    },
    onViewTradeIn(tradeIn) {
      if (tradeIn) {
        this.assignTradeIn(tradeIn.id)
      }
    },
    async assignTradeIn(tradeInId) {
      if (this.tradeIns?.find(ti => ti.orderId === this.order.id)) {
        this.tradeIns.find(ti => ti.orderId === this.order.id).orderId = null
      }

      if (this.tradeIns?.find(ti => ti.id === tradeInId)) {
        this.tradeIns.find(ti => ti.id === tradeInId).orderId = this.order.id
      }

      this.loading = true

      try {
        const query = `mutation {assignTradeInToOrder(orderId: ${this.order.id} tradeInId: ${tradeInId}) { ${this.tradeInQuery} }}`
        const result = await this.queryApi(query)

        if (result) {
          const pricingQuery = `
          {
            order(id: ${this.order.id}) {
              pricing {
                financing {key name breakdowns {key name products {key name price description} total}}
                cashPayment {key name breakdowns {key name products {key name price description} total}}
              }
            }
          }`
          const { order } = await this.queryApi(pricingQuery)
          this.input.tradeIn = result.assignTradeInToOrder

          if (this.tradeIns.find(ti => ti.id === result.assignTradeInToOrder.id).orderId) {
            this.tradeIns.find(ti => ti.id === result.assignTradeInToOrder.id).orderId = this.order.id
          }

          this.$emit('assignedTradeIn', result.assignTradeInToOrder, order.pricing)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
.orderTradeIns {
  .tradeInInfoBanner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: none;

    .goToTradeInButton {
      border: none;
      background-color: none;
      background: transparent;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
    }

    svg {
      min-width: 1.5rem;
    }
  }
}
</style>
