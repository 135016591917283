<template>
  <div class="filter-box">
    <p>{{ sortedListings.length }} {{ languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.vehicles }}</p>
    <div>
      <multi-select :options="sortOptions" :multi="false" v-model="selectedSort" data-test-id="sort" :placeholder="languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.sort_by" :clear-placeholder="languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.clear" />
    </div>
  </div>
  <div class="favorites">
    <div v-if="!loading && !error && sortedListings.length > 0" class="list">
      <ListingCard :placeholder="placeholder" v-for="(l, i) in sortedListings" :key="l.id" :ref="'vehicle' + i" :index="i" :details="l" :to="to" @click="$emit('select', parseInt(l.id), l)" @click-payment="e => $emit('click-payment', e)" @track="e => $emit('track', e)" @on-toggle-favorite="e => $emit('on-toggle-favorite', e)"/>
    </div>

    <template v-if="!loading && sortedListings.length === 0">
      <slot v-if="replaceEmpty" name="empty" />
      <EmptySearch v-else :to="urlSearch" :description="t['you_have_not_favorites']" :lg="lg" />
    </template>

    <p v-if="!loading && error" class="error">{{ error }}</p>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import ListingCard from '../ListingCard2.ce.vue'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import EmptySearch from '../EmptySearch.ce.vue'
import MultiSelect from '../MultiSelect.ce.vue'
import { langMixin } from '../lang'

export default {
  name: 'SeezFavorites',
  components: { ListingCard, Loader, MultiSelect, EmptySearch },
  mixins: [langMixin('EMPTY_SEARCH_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, SeezSdk.vueFavoritesMixin],
  props: {
    to: { type: String, default: null },
    urlSearch: { type: String, default: '/search' },
    placeholder: { type: String, default: null },
    replaceEmpty: { type: Boolean, default: false }
  },
  emits: ['select', 'click-payment', 'track', 'on-toggle-favorite'],
  data() {
    return {
      loadingDetails: false,
      listings: [],
      error: null,
      selectedSort: null
    }
  },
  computed: {
    loading() {
      return this.loadingDetails
    },
    sortOptions() {
      return [
        {
          name: this.languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.price_ascending,
          id: 'price'
        },
        {
          name: this.languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.price_descending,
          id: '-price'
        },
        {
          name: this.languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.age_descending,
          id: '-year'
        },
        {
          name: this.languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.age_ascending,
          id: 'year'
        },
        {
          name: this.languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.kilometrage_ascending,
          id: 'kilometrage'
        },
        {
          name: this.languageResources?.RESULT_PANEL_COMPONENT_TRANSLATIONS?.kilometrage_descending,
          id: '-kilometrage'
        }
      ]
    },
    sortedListings() {
      const sortedList = [...this.listings]

      if (this.selectedSort === 'year') {
        sortedList.sort((a, b) => a.year - b.year)
      }

      if (this.selectedSort === '-year') {
        sortedList.sort((a, b) => b.year - a.year)
      }

      if (this.selectedSort === 'price') {
        sortedList.sort((a, b) => a.retailPrice?.value - b.retailPrice?.value)
      }

      if (this.selectedSort === '-price') {
        sortedList.sort((a, b) => b.retailPrice?.value - a.retailPrice?.value)
      }

      if (this.selectedSort === 'kilometrage') {
        sortedList.sort((a, b) => a.kilometrage - b.kilometrage)
      }

      if (this.selectedSort === '-kilometrage') {
        sortedList.sort((a, b) => b.kilometrage - a.kilometrage)
      }

      return sortedList
    }
  },
  watch: {
    favorites() {
      this.loadListings()
    }
  },
  mounted() {
    this.loadListings()
  },
  methods: {
    async loadListings() {
      this.error = null
      if (this.favorites.length > 0) {
        this.loadingDetails = true
        try {
          const query = 'query favorites($ids:[ID]){listingsByIds(ids:$ids)' + ListingCard.requiredFields + '}'
          const result = await this.queryApi(query, { ids: this.favorites.map(i => i.toString()) })
          this.listings = result.listingsByIds.filter(l => l != null)
        } catch (error) {
          console.error(error)
          this.error = 'Error getting favorites'
        }
        this.loadingDetails = false
      } else {
        this.listings = []
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../base';
.filter-box {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 1.25rem;
    margin-inline-start: 0.5rem;
    color: var(--text-color);
  }

  > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .multiselect {
      width: 12rem;
    }
  }
}

.favorites {
  @include theme;

  > .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 17em);
    justify-content: start;
    gap: 1em;
    padding: 1em 0 0 0.5em;

    @media screen and (max-width: 36rem) {
      grid-template-columns: repeat(auto-fill, 100%);
      padding: 1em 1em 0 1em;
    }
  }

  > .error {
    color: red;
    text-align: center;
    margin: 0;
    font-size: 2em;
  }
}
</style>
