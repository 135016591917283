<template>
  <a class="listingCard" :href="toUrl" @click="select" :target="openInNewTab ? '_blank' : '_self'" :rel="openInNewTab ? 'noopener noreferrer' : null">
    <div :class="{ images: true, loading: loadingImages }">
      <img :src="imageUrl" :alt="details.name" @load="loadingImages = false" @error="loadingImages = false" ref="image" loading="lazy" />
      <button :class="{ prev: true, flipped: textDirection !== 'rtl' }" @click.stop.prevent="prevImage"><RightIcon /></button>
      <button :class="{ next: true, flipped: textDirection !== 'ltr' }" @click.stop.prevent="nextImage"><RightIcon /></button>
      <VideoIcon class="videoIcon" v-if="details?.videos?.length > 0" />
      <LoadingIcon class="loadingIcon" v-if="loadingImages" />
      <span v-if="details.leasePrice?.lease?.audience === 'business' && currentFilters?.priceType === 'lease'" class="businessLeasing">{{ t.erhvervleasing ?? 't.erhvervleasing' }}</span>
      <span v-else-if="details.leasePrice?.lease?.audience === 'private'  && currentFilters?.priceType === 'lease'" class="privateLeasing">{{ t.privateleasing ?? 't.privateleasing' }}</span>
      <span :class="{ badge: true, promotion: hasPromotion, customBadge: !!details.customBadge }" v-if="details.customBadge || hasPromotion">{{ hasPromotion ? t.promotionBadge : details.customBadge ? details.customBadge : '' }}</span>
    </div>
    <span class="name">{{ details.name }}</span>
    <span class="variant">{{ details.variant }}</span>
    <ul class="tags">
      <li class="year"><CalendarIcon />{{ details.year }}</li>
      <li class="mileage">
        <MileageIcon />{{ formatNumber(details.kilometrage) }} <span>{{ kmUnit }}</span>
      </li>
      <li class="fuelType" v-if="details.fuelType"><FuelIcon />{{ languageResources?.Common?.FuelTypes?.[details.fuelType.name] || details.fuelType.name }}</li>
      <li class="transmission" v-if="details.transmission"><GearsIcon />{{ languageResources?.Common?.Transmissions?.[details.transmission.name] || details.transmission.name }}</li>
      <li class="color" v-if="details.color"><ColorIcon />{{ languageResources?.Common?.Colors?.[details.color] || details.color }}</li>
      <li class="interior" v-if="details.interiorMaterial"><InteriorIcon />{{ details.interiorMaterial }}</li>
      <li class="location" v-if="location"><LocationIcon />{{ location }}</li>
      <li class="isNoviceDrivable" v-if="details.isNoviceDrivable">
        <div class="icon" />
        {{ t.neopatentati ?? ' Neopatentati' }}
      </li>
      <li v-if="details.licensePlate" class="licensePlate"><LicensePlateIcon />{{ details.licensePlate }}</li>
    </ul>
    <section class="prices">
      <div class="retail" v-if="details.retailPrice?.value && !hasPromotion && !hasListPrice && currentFilters?.priceType !== 'lease'">
        <span>{{ t.retailPrice }}</span>
        <span>{{ formatPrice(details.retailPrice, details.currency) }}</span>
      </div>
      <div class="lease" v-if="details.leasePrice?.value">
        <span>{{ t.leasePrice }}</span>
        <span>{{ formatPrice(details.leasePrice, details.currency) }} {{ t.perMonth }}</span>
        <button @click.stop.prevent="modalInfo = 'leasing'"><HelpIcon /></button>
      </div>
      <div class="retail-discount" v-else-if="hasPromotion && details.retailPrice?.value">
        <span>{{ t.retailPrice }}</span>
        <span>{{ isDifferentPrice ? formatPrice(details.retailPrice, details.currency) : '' }}</span>
        <span class="discounted-price">{{ formatPrice({ currency: details.currency, value: details.promotions?.[0].discounted_price }, details.currency) }}</span>
      </div>
      <div class="retail-discount" v-else-if="hasListPrice && details.retailPrice?.value">
        <span>{{ t.retailPrice }}</span>
        <span>{{ formatPrice(details.listPrice, details.currency) }}</span>
        <span class="discounted-price">{{ formatPrice(details.retailPrice, details.currency) }}</span>
      </div>

      <div v-if="currentFilters?.priceType === 'lease'" class="leasing-info">
        <div>
          <span>{{ t.downPayment ?? 't.downPayment' }}:</span>
          <span>{{ formatNumber(details.leasePrice.lease.downPayment, 0, details.currency) }}</span>
        </div>
        <div v-if="details.leasePrice?.lease?.audience === 'private'">
          <span>{{ t.kmIncluded ?? 't.kmIncluded' }}</span>
          <span>{{ details.leasePrice.lease.yearlyKmtrage === -1 ? '-' : detail.leasePrice.lease.yearlyKmtrage }}</span>
        </div>
        <div v-else-if="details.leasePrice?.lease?.audience === 'business'">
          <span>{{ t.residualvalue ?? 't.residualvalue' }}</span>
          <span>{{ formatNumber(details.leasePrice.lease.residualValue, 0, details.currency) }}</span>
        </div>
        <div>
          <span>{{ t.duration ?? 't.duration' }}</span>
          <span>{{ details.leasePrice.lease.duration }} {{ t.months ?? 't.months' }}</span>
        </div>

        <div class="vat" v-if="details.leasePrice?.lease.audience === 'private'">{{ t.priceIncludeVAT ?? 't.priceIncludeVAT' }}</div>
        <div class="vat" v-else>{{ t.priceExcludeVAT ?? 't.priceExcludeVAT' }}</div>
      </div>

      <div class="emi" v-if="details.emiPrice?.value && currentFilters?.priceType !== 'lease'">
        <span>{{ t.financingPrice }}</span>
        <span>{{ formatPrice(details.emiPrice, details.currency) }} {{ t.perMonth }}</span>
        <button @click.stop.prevent="modalInfo = 'financing'"><HelpIcon /></button>
      </div>

      <div class="emi-w-rate" v-if="details.emiPrice?.value" style="display: none">
        <span>{{ t.apr }}: {{ details.emiPrice?.emi?.apr }}%</span>
        <span>{{ t.monthlyPaymentPrice?.replace('{price}', formatPrice(details.emiPrice, details.currency)) ?? `t.monthlyPaymentPrice` }}</span>
        <button @click.stop.prevent="modalInfo = 'financing'"><HelpIcon /></button>
      </div>

      <div class="wholesale lease" v-if="details.wholesaleLeasePrice?.value">
        <span>{{ t.wholesaleLeasePrice }}</span>
        <span>{{ formatPrice(details.wholesaleLeasePrice, details.currency) }} / {{ t.month }}</span>
        <button @click.stop.prevent="modalInfo = 'wholesaleLeasing'"><HelpIcon /></button>
      </div>

      <div class="wholesale" v-if="details.wholesalePrice?.value">
        <span>{{ t.wholesalePrice }}</span>
        <span>{{ formatPrice(details.wholesalePrice, details.currency) }}</span>
      </div>
    </section>
    <span :class="['status', status]" v-if="status">{{ t[status] }}</span>
    <span :class="['feature', feature]" v-if="feature">
      <ElectricIcon v-if="feature === 'electric'" />
      <HybridIcon v-if="feature === 'hybrid'" />
      <span>{{ t[feature] }}</span>
    </span>
    <span class="km0" v-if="details.usage === 'km0'">
      <div />
      <span>{{ t.km0 }}</span>
    </span>
    <span class="certified" v-if="details.certified" :title="t.certified">
      <div class="certified-badge" />
    </span>
    <button class="favorite" @click.stop.prevent="toggleFavorite"><FavoriteIcon :class="{ on: favorited }" /></button>
    <button :class="compareClass" @click.stop.prevent="compare"><ComapreIcon /></button>
    <button class="image360" v-if="details?.image360?.exteriorClosedDoors || details?.image360?.interiorPanorama"><Image360Icon /></button>

    <slot name="extra" />
    <Modal v-if="modalInfo" @close="modalInfo = null">
      <div class="pricingModal financing" v-if="modalInfo === 'financing'" @click.stop.prevent="doNothing">
        <h3>{{ t.financialInformation }}</h3>
        <ul class="emiDetailsList">
          <li>
            <dt>{{ t.monthlyPayment }}</dt>
            <dd>{{ formatPrice(details.emiPrice, details.currency) }}</dd>
          </li>
          <li>
            <dt>{{ t.downpayment }}</dt>
            <dd>{{ formatNumber(details.emiPrice.emi.downPayment, 0, getCurrency(details.emiPrice)) }}</dd>
          </li>
          <li class="interestRate">
            <dt>{{ t.interestRate }}</dt>
            <dd>{{ details.emiPrice.emi.interestRate ? details.emiPrice.emi.interestRate + '%' : '' }}</dd>
          </li>
          <li>
            <dt>{{ t.terms }}</dt>
            <dd>{{ details.emiPrice.emi.paymentTerms }}</dd>
          </li>
          <li>
            <dt>{{ t.loanAmount }}</dt>
            <dd>{{ formatNumber(details.emiPrice.emi.loanAmount, 0, getCurrency(details.emiPrice)) }}</dd>
          </li>
          <li class="aopBeforeTax">
            <dt>{{ t.aopBeforeTax }}</dt>
            <dd>{{ details.emiPrice?.emi?.aopBeforeTax ? details.emiPrice.emi.aopBeforeTax + '%' : '' }}</dd>
          </li>
          <li class="apr">
            <dt>{{ t.apr }}</dt>
            <dd>{{ details.emiPrice.emi.apr ? details.emiPrice.emi.apr + '%' : '' }}</dd>
          </li>
        </ul>
        <p class="disclaimer" v-html="details.emiPrice.emi.disclaimer" />
        <slot name="financingTerms" />
        <div class="cta">
          <p>{{ t.useCalculator }}</p>
          <button @click.stop.prevent="calculate"><CalculatorIcon />{{ t.startCalculator }}</button>
        </div>
      </div>
      <div class="pricingModal leasing" v-if="['wholesaleLeasing', 'leasing'].includes(modalInfo)" @click.stop.prevent="doNothing">
        <h3>{{ t.leasingInformation }}</h3>
        <ul>
          <li>
            <dt>{{ t.duration }}</dt>
            <dd>{{ selectedLeasePrice?.lease.duration }}</dd>
          </li>
          <li>
            <dt>{{ t.downpayment }}</dt>
            <dd>{{ formatNumber(selectedLeasePrice?.lease.downPayment, 0, getCurrency(selectedLeasePrice)) }}</dd>
          </li>
          <li>
            <dt>{{ t.monthlyPayment }}</dt>
            <dd>{{ formatPrice(selectedLeasePrice, details.currency) }}</dd>
          </li>
          <li>
            <dt>{{ t.residualValue }}</dt>
            <dd>{{ formatNumber(selectedLeasePrice?.lease.residualValue, 0, getCurrency(selectedLeasePrice)) }}</dd>
          </li>
          <li>
            <dt>{{ t.leaseType }}</dt>
            <dd>{{ t[selectedLeasePrice?.lease.type] }} {{ t[selectedLeasePrice?.lease.audience] }}</dd>
          </li>
        </ul>
        <p>
          {{ selectedLeasePrice?.lease.type === 'operational' ? t.vat_include_leasing : t.vat_excluded }}
        </p>
        <div class="cta">
          <p>{{ t.monthlyInstallment }}</p>
          <button @click.stop.prevent="contactUs"><ChatIcon />{{ t.contactUs }}</button>
        </div>
      </div>
    </Modal>
  </a>
</template>

<script>
import { analyticsMixin, analyticsEvents } from '../analytics.js'
import { slugify } from '../logic.js'
import { langMixin } from './lang'
import SeezSdk from '../sdk.js'
import Modal from '@/components/Modal.ce.vue'
import VideoIcon from '@/assets/video-icon.svg'
import ComapreIcon from '@/assets/compare.svg'
import CalculatorIcon from '@/assets/calculator-icon.svg'
import RightIcon from '@/assets/right.svg'
import ChatIcon from '@/assets/chat-icon.svg'
import FavoriteIcon from '@/assets/favorite.svg'
import MileageIcon from '@/assets/mileage.svg'
import CalendarIcon from '@/assets/calendar.svg'
import FuelIcon from '@/assets/fueltype.svg'
import GearsIcon from '@/assets/gear.svg'
import ColorIcon from '@/assets/color.svg'
import LocationIcon from '@/assets/location.svg'
import InteriorIcon from '@/assets/interior2.svg'
import ElectricIcon from '@/assets/electric.svg'
import Image360Icon from '@/assets/360.svg'
import HybridIcon from '@/assets/hybrid.svg'
import LoadingIcon from '@/assets/waitBlock.svg'
import HelpIcon from '@/assets/help.svg'
import LicensePlateIcon from '@/assets/car-license-plate.svg'

const getDeepValue = (obj, path) => path.split('.').reduce((t, c) => t?.[c], obj) // Dig through deep objects

export default {
  name: 'SeezListingCard',
  components: { Image360Icon, RightIcon, FavoriteIcon, MileageIcon, CalendarIcon, FuelIcon, GearsIcon, ColorIcon, LocationIcon, InteriorIcon, ElectricIcon, HybridIcon, VideoIcon, ComapreIcon, Modal, CalculatorIcon, ChatIcon, LoadingIcon, HelpIcon, LicensePlateIcon },
  mixins: [langMixin('LISTING_CARD_COMPONENT_TRANSLATIONS'), SeezSdk.vueFavoritesMixin, analyticsMixin],
  inheritAttrs: false,
  props: {
    details: { type: Object, required: true },
    to: { type: String, default: null },
    index: { type: Number, default: 0 },
    placeholder: { type: String, default: null },
    withSlider: { type: Boolean, default: true },
    compareIds: { type: String, default: '' },
    openInNewTab: { type: Boolean, default: false },
    currentFilters: { type: Object, default: null }
  },
  emits: ['click', 'compare', 'click-payment', 'on-toggle-favorite'], //TODO: replace click-payment with something better
  data() {
    return {
      currentImage: 0,
      modalInfo: null,
      showFinancing: false,
      showLeasing: false,
      loadingImages: false,
      resizeObserver: new ResizeObserver(this.checkImageSize),
      imageWidth: 0
    }
  },
  computed: {
    kmUnit() {
      return this.details?.kmUnit ?? 'km'
    },
    hasListPrice() {
      return this.details.usage === 'km0' && this.details?.listPrice?.value && this.details?.retailPrice?.value
    },
    isDifferentPrice() {
      return this.details?.promotions?.[0]?.discounted_price !== this.details.retailPrice.value
    },
    hasPromotion() {
      if (this.details?.promotions?.[0]?.discounted_price && this.details?.promotions?.[0]?.start_date && this.details?.promotions?.[0]?.end_date) {
        const today = new Date()
        const startDate = new Date(this.details?.promotions[0]?.start_date)
        const endDate = new Date(this.details?.promotions[0]?.end_date)
        return today >= startDate && today <= endDate
      }
      return false
    },
    imageUrl() {
      if (this.imageWidth === 0) return null
      const d = this.details
      const pixelRatio = window.devicePixelRatio ?? 1
      const size = Math.min(Math.ceil((this.imageWidth * pixelRatio) / 100) * 100, 1600)
      if (d.images && d.images.length > 0) return `${import.meta.env.VITE_IMAGES_URL}/image/${size}x0/${d.images[this.currentImage]}.webp`
      return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
    },
    toUrl() {
      if (this.details == null) return null
      if (this.details.url) return this.details.url
      const templateUrl = this.to || this.details.targetSite?.urlConfig?.baseUrl + this.details.targetSite?.urlConfig?.listingUrl
      if (templateUrl == null || templateUrl === '') return null
      const legacyFields = {
        listingId: 'id',
        brand: 'model.family.brand.name',
        model: 'model.name',
        brandName: 'model.family.brand.name',
        modelName: 'model.name',
        year: 'year'
      }
      return templateUrl.replace(/\{([\w.-]+)\}/g, (m, g) => {
        return slugify(getDeepValue(this.details, legacyFields[g] ?? g)?.toString())
      })
    },
    location() {
      const loc = this.details?.locatedAt
      return loc?.address?.city ?? loc?.plainAddress?.split(',')[1] ?? loc?.plainAddress?.split(',')[0]
    },
    favorited() {
      return this.favorites.includes(parseInt(this.details.id))
    },
    status() {
      if (['reserved', 'reservedOnSeez'].includes(this.details.state)) return 'reserved'
      if (this.details.state === 'unavailable') return 'sold'
      return null
    },
    feature() {
      if (['El', 'Electricity'].includes(this.details.fuelType?.name)) return 'electric'
      if (['Hybrid', 'Hybrid (Benzin)', 'Hybrid (Diesel)'].includes(this.details.fuelType?.name)) return 'hybrid'
      return null
    },
    selectedLeasePrice() {
      if (this.modalInfo === 'wholesaleLeasing') return this.details?.wholesaleLeasePrice
      if (this.modalInfo === 'leasing') return this.details?.leasePrice
      return null
    },
    compareClass() {
      return this.compareIds?.includes(this.details.id) ? 'compare active' : 'compare'
    }
  },
  watch: {
    imageUrl() {
      this.loadingImages = true
    }
  },
  mounted() {
    this.resizeObserver.observe(this.$refs.image)
    this.imageWidth = this.$refs.image?.clientWidth ?? 0
    this.imageHeight = this.$refs.image?.clientHeight ?? 0
    // this.tag = this.details.customBadge ?? ''
  },
  beforeUnmount() {
    this.resizeObserver.disconnect()
  },
  methods: {
    select(e) {
      const action = typeof e === 'string' ? e : 'select'
      this.track('car_click', { listing_price: this.details.retailPrice?.value, listing_id: parseInt(this.details.id), event_label: { car_id: this.details.id }, vehicle: this.details })
      this.$emit('click', e, { id: this.details.id, item: this.details, action: action })
    },
    toggleFavorite() {
      const favourited = this.setFavorite(this.details.id)
      this.track(analyticsEvents.FAVOURITE_CAR, { listingId: this.details.id, favourited, listing_id: parseInt(this.details.id) })
      this.$emit('on-toggle-favorite', { listing: this.details, favourited })
    },
    prevImage() {
      if (this.details?.images?.length > 1) {
        this.currentImage = this.currentImage <= 0 ? this.details.images.length - 1 : this.currentImage - 1
      }
    },
    nextImage() {
      if (this.details?.images?.length > 1) {
        this.currentImage = this.currentImage >= this.details.images.length - 1 ? 0 : this.currentImage + 1
      }
    },
    compare() {
      this.track('compare', { listingId: this.details.id })
      this.$emit('compare', this.details.id)
    },
    doNothing() {
      //this is necessary to avoid a redirect when yo click on the modal text
    },
    contactUs() {
      this.modalInfo = null
      window.seezSdk.showModal('seez-sdk-lead-form', { listing: this.details.id, lg: this.locale })
    },
    calculate() {
      this.modalInfo = null
      // window.seezSdk.showModal('seez-sdk-calculator', { listing: this.details.id })
      this.$emit('click-payment', { listing: this.details, type: 'financing' }) //TODO: change this to something better
    },
    checkImageSize() {
      const newSize = this.$refs.image?.clientWidth ?? 0
      if (this.imageWidth < newSize) this.imageWidth = newSize
    }
  },
  requiredFields: '{ isNoviceDrivable fullColor image360 { exteriorClosedDoors interiorPanorama } licensePlate usage listPrice { value currency } customBadge id name model { id name family { brand { id name }}} variant description year currency images kilometrage kmUnit fuelType { name } transmission { name } color state externalId referenceNumber url locatedAt { plainAddress address { city }} retailPrice { value currency disclaimer } leasePrice { value currency lease { duration residualValue type audience downPayment yearlyKmtrage }} wholesalePrice { value currency } wholesaleLeasePrice { value currency lease { duration residualValue type audience downPayment }} emiPrice { value currency emi { disclaimer apr aopBeforeTax loanAmount downPayment paymentTerms calculatedOn interestRate }} videos interiorMaterial certified targetSite { name urlConfig { baseUrl listingUrl }} promotions { title description priceB2c disclaimer discount discounted_price start_date end_date}}'
}
</script>

<style lang="scss">
.listingCard {
  position: relative;
  color: var(--font-color);
  background: var(--background);
  text-decoration: none;
  border: 1px solid var(--border-color);

  .images {
    display: grid;
    grid-template-columns: auto 1fr auto;

    > img {
      grid-area: 1 / 1 / 1 / 4;
      aspect-ratio: 4 / 3;
      width: 100%;
      object-fit: cover;
    }

    > button {
      border: none;
      background: var(--highlight);
      cursor: pointer;
      transition: opacity 500ms;
      place-self: center;
      border-radius: 2rem;
      height: 1.75rem;
      width: 1.75rem;
      margin: 0.25rem;
      padding-block: 0.2rem 0;
      padding-inline: 0.1rem 0;
      text-align: center;
      opacity: 0.5;

      @media screen and (hover) {
        opacity: 0;
      }

      > svg {
        height: 1rem;
        width: 1rem;
        stroke: var(--background);
      }
    }

    &:hover > button {
      opacity: 1;
    }

    > .prev {
      grid-area: 1 / 1 / 1 / 1;
    }

    > .flipped {
      transform: scaleX(-1);
    }

    > .next {
      grid-area: 1 / 3 / 1 / 4;
    }

    > .erhvervleasing, .privateleasing {
      display: none;
    }

    > .videoIcon {
      width: 1em;
      grid-area: 1 / 1 / 1 / 4;
      margin: 1rem;
      place-self: end start;
    }

    > .loadingIcon {
      grid-area: 1 / 1 / 1 / 4;
      place-self: center;
      height: 5rem;
      fill: var(--highlight);
    }
  }

  .name {
    font-size: 1.16em;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .variant {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tags {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em 0.5em;

    > .isNoviceDrivable {
      display: none;
    }

    > li {
      white-space: nowrap;

      > svg {
        height: 1em;
        fill: var(--font-color);
      }
    }
  }

  .prices {
    > div > button {
      color: var(--highlight);
      background: none;
      border: none;
      cursor: pointer;
      padding-inline: 0.5em 0;

      > svg {
        height: 1em;
      }
    }
  }

  .status {
    position: absolute;
    inset: 0 auto auto 0;
    background-color: var(--highlight);
    color: var(--background);
  }

  .feature {
    position: absolute;
    inset: 0 0 auto auto;
    background-color: var(--highlight);
    color: var(--background);
    display: flex;

    > svg {
      display: inline-block;
      height: 1em;
      fill: var(--background);
    }
  }

  .image360,
  .favorite,
  .compare {
    border: none;
    background: none;
    padding: 0.5em;
    cursor: pointer;
    position: absolute;

    inset: auto 0 0 auto;

    > svg {
      height: 1.5em;

      &.on {
        fill: var(--highlight);
      }
    }
  }

  .certified {
    position: absolute;
    inset: auto 0 0 auto;

    > svg {
      height: 1.5em;
    }
  }

  .pricingModal {
    cursor: default;
    padding: 0;
    min-width: 100%;

    @media screen and (min-width: 36rem) {
      min-width: 33rem;
      padding: 0.875rem;
    }

    > p {
      font-style: italic;
      text-align: start;
      font-size: 0.75em;
    }

    > .emiDetailsList {
      .aopBeforeTax {
        display: none;
      }
      .apr {
        display: none;
      }
    }

    > p.disclaimer {
      max-width: 35rem;
      font-size: 0.75rem;
      font-weight: 500;
      display: none;
    }

    > div.cta {
      background-color: var(--accented-background);
      margin: 1.5rem -1.875rem -1.875rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        max-width: 30em;
        text-wrap: balance;
        margin: 0 0 1rem;
        font-size: 14px;
      }

      > button {
        background: none;
        border: none;
        display: inline-flex;
        gap: 0.75em;
        cursor: pointer;
        font-size: 1em;
        color: var(--highlight);

        > svg {
          height: 1em;
        }
      }
    }

    > h3 {
      margin-top: 0;
      text-align: start;
      font-size: 1.5rem;
      font-weight: 700;
    }

    > ul {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.625rem;
      list-style-type: none;

      > li {
        list-style-type: none;
        border: 1px solid #ccd0d1;
        border-radius: 0.625rem;
        padding: 1rem 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 0.125rem;

        > dd {
          font-weight: 700;
          font-size: 1.25rem;
        }

        > * {
          text-align: start;
          margin-inline-start: 0;
        }

        &.interestRate {
          display: none;
        }
      }

      @media screen and (max-width: 36rem) {
        grid-template-columns: 1fr;

        > li {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          > dd {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .seezModal .closer {
    inset-inline-end: 1rem;
    inset-block-start: 1.25rem;
  }
}

// start default class for ListingCard
.listingCardDefault {
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  place-items: start stretch;
  text-align: start;
  color: black;

  .images {
    overflow: hidden;
    grid-area: 1 / 1;

    > button {
      background-color: black;
    }
  }

  .name {
    font-size: 1.25em;
    font-weight: bold;
    margin: 0.75rem 1rem 0;
    grid-area: 2 / 1;
  }

  .variant {
    margin: 0.5rem 1rem;
    grid-area: 3 / 1;
  }

  .tags {
    margin: 1rem;
    grid-area: 4 / 1;
    font-size: 0.9em;

    > li {
      white-space: nowrap;
      padding: 0.25em 0.6em;
      border-radius: 0.25em;

      > svg {
        height: 0.75em;
        margin-inline-end: 0.5em;
      }

      &.transmission,
      &.color {
        display: none;
      }
    }
  }

  .tags + .prices {
    margin-block-start: 0;
  }

  .prices {
    margin: 1rem;
    grid-area: 5 / 1;
    font-size: 0.9rem;

    > div {
      display: grid;
      grid-template-columns: 1fr auto auto;

      > span:nth-child(2) {
        font-weight: bold;
      }
    }

    .retail {
      font-size: 1.25rem;
      margin-block-end: 0.5rem;
      > span:first-child {
        display: none;
      }
    }

    .lease {
      display: none;
    }
  }

  .status {
    padding: 0.5em 1em;
    margin: 1em;

    &.reserved {
      background-color: orange;
    }

    &.sold {
      background-color: red;
    }
  }

  .feature {
    padding: 0.5em 1em;
    border-radius: 0 1rem 0 0.75rem;
    background-color: #028f30;

    > svg {
      height: 1.5em;
    }

    > span {
      display: none;
    }
  }

  .certified {
    display: none;
  }

  .favorite {
    grid-area: 2 / 1;
    position: static;
    place-self: start end;
    padding: 1rem 1rem 0;
  }

  .image360 {
    display: none;
  }

  .compare {
    display: none;
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: stretch;
    text-align: center;

    > * {
      padding-block: 0.75rem;
      cursor: pointer;

      &:not(:last-child) {
      }

      > input[type='checkbox'] {
        cursor: pointer;
      }
    }
  }
}
</style>
