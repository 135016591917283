<template>
  <div class="discountedSelectedPrice">
    <div class="originalPrice">
      <p class="oldPrice">{{ oldPrice }}</p>
      <div class="discountTag">
        <TrendingDown />
        <span>Sconto di {{ discountedAmount }}</span>
      </div>
    </div>
    <p class="newPrice">{{ newPrice }}</p>
  </div>
</template>

<script>
import TrendingDown from '@/assets/trending-down.svg'
export default {
  name: 'DiscountedSection',
  components: { TrendingDown },
  props: {
    oldPrice: {
      type: String,
      required: true
    },
    discountedAmount: {
      type: String,
      required: true
    },
    newPrice: {
      type: String,
      required: true
    }
  },
}
</script>
