<!-- eslint-disable no-useless-escape -->
<template>
  <div id="chatModal" v-if="chatConfig" :class="{ chatModal: true, chatPosition: collapsed, [`${widgetPosition}Chat`]: slideIn, original: chatSizes.original.current && slideIn, small: chatSizes.small.current && slideIn, [`${widgetMargin}Chat`]: true }">
    <button @mouseenter="handleHoverWidget" v-if="showWidget" id="modalButton" :class="[widgetButtonClasses]" @click="slideIn ? onCloseChatClick(true) : onOpenChatClick()" aria-label="Toggle Chat">
      <img v-if=!slideIn id="seezarIcon" alt="Seezar icon" :src="widgetIcon" :class="[{ [widgetAnimation]: animationStart }]" />
      <svg v-else width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.19141 9.55859L1.44141 2.80859C0.984375 2.38672 0.984375 1.64844 1.44141 1.22656C1.86328 0.769531 2.60156 0.769531 3.02344 1.22656L9 7.16797L14.9414 1.22656C15.3633 0.769531 16.1016 0.769531 16.5234 1.22656C16.9805 1.64844 16.9805 2.38672 16.5234 2.80859L9.77344 9.55859C9.35156 10.0156 8.61328 10.0156 8.19141 9.55859Z" :fill="avatarIconThemes[dealershipSeezarBot?.widgetIconTheme] ?? 'black'"/>
      </svg>
      <PopupMessage :faqs="handleFaqQuestions" :show-popup-message="showPopupMessage" :is-desktop-screen="isDesktopScreen" :widget-position="widgetPosition" @select-faq="selectTooltipFAQ">
        <ChatMessage id="bot" ref="tooltipMessage" :message="welcomeMessage" :chat-avatar-styles="chatConfig?.theme?.chatAvatarStyles" :chat-message-styes="chatConfig?.theme?.chatMessageStyles" role="bot" :bot-config="dealershipSeezarBot" show-close-button @close="handleClosePopupMesage" />
      </PopupMessage>
    </button>

    <div :class="{ modalBackdrop: slideIn, collapsed: collapsed, [`${widgetMargin}Chat`]: true }">
      <div @mouseenter="handleHoverChat" :style="controlDisplayProperty" :class="{ content: true, slideIn: slideIn, [`${widgetMargin}Chat`]: isDesktopScreen && collapsed }">
          <ChatHeader :disable-clear-chat="disableClearChat" :header-icon="headerIcon" :chat-reference-id="chatReferenceId" :show-chat-size-options="showChatSizeOptions" :chat-sizes="chatSizes" @change-chat-size="changeChatSize" @close-chat="onCloseChatClick" @reset-messages="onClickResetMessages" :is-desktop-screen="isDesktopScreen" />

        <!-- Start of Chat Component -->
        <div ref="chat" v-if="chatConfig" :class="{ chat: true }">
          <div ref="messages" @scroll="handleScroll" :class="{ messages: true }">
            <ChatMessageList :messages="messages" :theme="chatConfig.theme" :bot-config="dealershipSeezarBot">
              <template #autoScrollObserver>
                <div ref="endOfMessages" id="endOfMessages">
                  <IntersectionObserver @on-intersection="handleEndOfMessagesVisibility" observed-ref-name="endOfMessages" />
            </div>
          </template>
              <template #automatedMessage>
                <ChatMessage id="bot" ref="message0" :message="firstMessage" :date="firstMessageDate" :chat-avatar-styles="chatConfig?.theme?.chatAvatarStyles" :chat-message-styes="chatConfig?.theme?.chatMessageStyles" role="bot" :last-child="!messages.length" :bot-config="dealershipSeezarBot" :show-faqs="!messages.length">
                  <template #faqs>
                    <FAQs :faqs="handleFaqQuestions" @question-selected="handleFAQSelected" :bot-config="dealershipSeezarBot" />
                  </template>
                </ChatMessage>
              </template>
            </ChatMessageList>
                  </div>
          <IntersectionObserver observed-ref-name="chat" @on-intersection="handleIntersection" />
          <div ref="inputWrapper" class="chatInputWrapper">
            <ChatInput id="chatInput" :placeholder="t?.textFieldPlaceholder || ''" @focus="onInputFocus" @new-message="handleNewMessage" :theme="chatConfig.theme" :disabled="disabled" :bot-config="dealershipSeezarBot" />
            <div v-if="!dealershipSeezarBot?.termsAndConditions">
              <p class="disclaimerText">{{ t.newDisclaimerPartOne }}</p>
              <p class="disclaimerText">
                <span :class="['tooltipContainer', textDirection]">
                  <QuestionMark />
                  <span :class="['tooltipText', textDirection]"> {{ t.disclaimerTooltipText }}</span>
                </span>
                <span @click="onPolicyClick" v-html="t.newDisclaimerPartTwo?.replace(/{seezarPrivacyPolicyUrl}/g, seezarPrivacyPolicyUrl)" />
              </p>
            </div>
            <div @click="onCustomDisclaimerClick" v-else class="customDisclaimerText" v-html="dealershipSeezarBot.termsAndConditions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hexToRgba, isColorWhite } from './helpers'
import { getSeezarConfig, getSeezarConfigByClientPath, getSeezarConfigByBotId, callCeaserApi, APIOperations, getUserChatHistory } from './api'
import { analyticsMixin } from '@/analytics.js'
import light from './themes/light.json'
import dark from './themes/dark.json'
import { langMixin } from '@/components/lang.js'
// chat component
import ChatMessageList from './ChatMessageList.ce.vue'
import IntersectionObserver from './IntersectionObserver.ce.vue'
import ChatInput from './ChatInput.ce.vue'
import FAQs from './FAQs.ce.vue'
import ChatMessage from './ChatMessage.ce.vue'
import QuestionMark from '@/assets/question-circle.svg'
import ChatHeader from './ChatHeader.ce.vue'
import { chatSizeKeys, customAttributes, booleanCustomAttributes, WidgetPositions, WidgetMargins, BorderRadius, WidgetAnimations, chatStates, chatRoles, iconThemes, radiusOptions, storageKeys, webSocketOperations, reservedWebSocketStrings, reservedWebSocketStringsKeys } from './constants.js'
import PopupMessage from './PopupMessage.ce.vue'


export default {
  name: 'ChatModal',
  components: { PopupMessage, ChatInput, ChatMessageList, IntersectionObserver, FAQs, ChatMessage, QuestionMark, ChatHeader },
  mixins: [analyticsMixin, langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    dealershipId: { type: String, default: '' },
    franchiseId: { type: String, default: '' },
    defaultTheme: { type: String, default: '' },
    passedBotId: { type: String, default: '' }
  },
  emits: ['close'],
  data() {
    // const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    return {
      slideIn: false,
      chatConfig: null,
      darkTheme: false,
      initialBodyOverflow: '',
      hideElement: true,
      dealershipSeezarBot: {},
      widgetIcon: '',
      headerIcon: '',
      widgetPosition: WidgetPositions.MIDDLE_RIGHT,
      botId: '',
      widgetMargin: WidgetMargins.MARGIN_NONE,
      customCss: '',
      // typingMessage: '',
      welcomeMessage: '',
      // typingSpeed: 90,
      widgetAnimation: WidgetAnimations.NONE,
      // animated: true,
      animationStart: true,
      animationInterval: 8000,
      animationRepeat: false,
      animationIntervalFunction: null,
      chatSizes: {
        [chatSizeKeys.full]: {
          current: false,
          icon: 'fullScreen'
        },
        [chatSizeKeys.small]: {
          current: true,
          icon: 'smallScreen'
        },
        [chatSizeKeys.original]: {
          current: false,
          icon: 'originalScreen'
        }
      },
      fallBackChatStartTime: 5,
      // isNotMobileScreen: true,
      isDesktopScreen: true,
      typingInterval: false,
      customAttributes: {},
      chatReferenceId: '',
      chatUuid: '',
      // chat component data
      clearDisabled: false,
      lastScrollTop: 0,
      scrollingUp: false,
      scrollingDown: false,
      messages: [],
      usage: null,
      loading: false,
      cachedFirstMessageDate: null,
      disabled: false,
      wsConnection: null,
      closeWsConnection: false,
      pingPongInterval: null,
      pingPongTimeGap: 5,
      wsClosedDueToError: false,
      wordsListLastIndex: 0,
      messageBuffer: '',
      errorBuffer: 2,
      errorsOccurred: 0,
      handShakeSent: false,
      firstMessageSent: false,
      originalMessage: '',
      isReceivingMessage: false,
      currentMessageMetrics: {},
      messageSentTime: null,            // Data variable to store the time when the message was sent
      botFirstResponseTime: null,       // Time when the first response from the bot is received (including "{}" messages)
      botContentResponseTime: null,     // Time when the first content message from the bot is received
      metaDataInterval: null,
      metaDataTimeGap: 3,
      metaDataFetchTries: 0,
      metaDataTryLimit: 2,
      maxWidthForMobileOrTablet: 768,
      lastSessionId: localStorage.getItem(storageKeys.LAST_SESSION_ID),
      currentSessionId: sessionStorage.getItem(storageKeys.CURRENT_SESSION_ID),
      closePopupMesage: false,
      borderRadiusOptions: BorderRadius
    }
  },
  computed: {
    showWidget() {
      if (!this.slideIn) {
        return this.botId && this.widgetIcon
      } else {
        return this.isDesktopScreen
      }
    },
    tooltipPosition() {
      const positionMap = {
        [WidgetPositions.TOP_LEFT]: 'bottomLeft',
        [WidgetPositions.MIDDLE_LEFT]: 'topLeft',
        [WidgetPositions.BOTTOM_LEFT]: 'topLeft',
        [WidgetPositions.TOP_RIGHT]: 'bottomRight',
        [WidgetPositions.MIDDLE_RIGHT]: 'topRight',
        [WidgetPositions.BOTTOM_RIGHT]: 'topRight'
      }

      return positionMap[this.widgetPosition] || ''
    },
    isDefaultIconSelected() {
      return this.isDefaultWidgetIcon(this.widgetIcon, this.dealershipSeezarBot?.widgetIcons || [])
    },
    widgetButtonClasses() {
      if (!this.slideIn)
        return { slideButton: true, customIcon: this.isDefaultIconSelected, [this.widgetPosition]: true, [this.widgetMargin]: true, squared: this.dealershipSeezarBot?.borderRadius === BorderRadius.SQUARE }

      return { slideButton: true, customIcon: true, bottomRight: this.chatOpensOnRight, bottomLeft: !this.chatOpensOnRight, [this.widgetMargin]: true, squared: this.dealershipSeezarBot?.borderRadius === BorderRadius.SQUARE }
    },
    controlDisplayProperty() {
      return this.hideElement ? 'display: none' : 'display: grid'
    },
    icon() {
      return this.dealershipSeezarBot.config?.customAvatarImages[0] || this.chatConfig?.theme?.modalHeadingIcon
    },
    chatModalStyles() {
      return {
        '--font-family': 'Manrope',
        '--primary-color': this.dealershipSeezarBot?.primaryColor,
        '--modal-bg-color': this.chatConfig?.theme.chatModalStyles.modalBgColor,
        '--chat-modal-header-bg-color': this.chatConfig?.theme.chatModalStyles.chatModalHeaderBgColor,
        '--chat-modal-header-text-color': this.chatConfig?.theme.chatModalStyles.chatModalHeaderTextColor,
        '--chat-modal-content-border-radius': this.chatConfig?.theme.chatModalStyles.chatModalContentBorderRadius[this.dealershipSeezarBot?.borderRadius],
        '--chat-modal-slider-button-bg-color': this.chatConfig?.theme.chatModalStyles.chatModalSliderButtonBgColor || '',
        '--shadow-from-primary-color': `0 4px 6px ${hexToRgba(this.dealershipSeezarBot?.primaryColor, '0.3')}`,
        '--chat-size-background': this.darkTheme ? 'black' : 'white',
        '--chat-size-border': this.darkTheme ? 'white' : 'black'
      }
    },
    bodyOverflow() {
      return this.slideIn && (!this.collapsed || !this.isDesktopScreen) ? 'hidden' : this.initialBodyOverflow
    },
    showChatSizeOptions() {
      return Object.keys(this.chatSizes).filter(size => !this.chatSizes[size].current)
    },
    collapsed() {
      return this.chatSizes.original.current || this.chatSizes.small.current
    },
    chatOpensOnRight() {
      return this.widgetPosition.endsWith('Right') ? true : false
    },
    showPopupMessage() {
      if ((this.lastSessionId == this.currentSessionId) && !this.onSeezarDashboard)
        return false
      
      if (this.closePopupMesage)
        return false

      return !!(this.welcomeMessage && !this.slideIn && this.dealershipSeezarBot)
    },
    onSeezarDashboard() {
      const url = new URL(window.location.href)
      return url.origin == import.meta.env.VITE_SEEZAR_DASHBOARD_URL
    },
    // chat component
    seezarPrivacyPolicyUrl() {
      return `${import.meta.env.VITE_SEEZAR_PRIVACY_URL}`
    },
    handleFaqQuestions() {
      return this.dealershipSeezarBot?.sampleQuestions?.length > 0 ? this.dealershipSeezarBot?.sampleQuestions : this.chatConfig.faqQuestions
    },
    hasPastMassages() {
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)
      return this.messages.some(message => new Date(message.date) < yesterday)
    },
    todaysText() {
      return this.defaultMessages.TODAY
    },
    resetChatText() {
      const text = this.chatConfig.resetChatText
      return text || this.defaultMessages.RESET_CHAT
    },
    noMessage() {
      return this.messages.length === 0
    },
    disableClearChat() {
      return this.clearDisabled || this.disabled || this.noMessage
    },
    chatComponentStyles() {
      const handleColor = color => {
        if (color === 'Transparent' || !color) return this.chatConfig?.theme?.chatInputStyles.buttonBgColor
        return color
      }

      return {
        '--chat-bg-color': 'white',
        '--chat-base-text-color': this.chatConfig?.theme?.chatBaseTextColor,
        '--chat-primary-button-bg-color': this.chatConfig?.theme?.chatPrimaryButtonBgColor,
        '--chat-comparison-table-bg': this.chatConfig?.theme?.comparisonTableBgColor,
        '--link-color': this.dealershipSeezarBot?.primaryColor == 'Transparent' || isColorWhite(this.dealershipSeezarBot?.primaryColor) ? this.chatConfig?.theme.linkColor : this.dealershipSeezarBot?.primaryColor,
        '--border-color': this.chatConfig?.theme?.chatInputStyles.borderColor,
        '--cta-bg-color': handleColor(this.dealershipSeezarBot?.primaryColor),
        '--cta-text-color': iconThemes[this.dealershipSeezarBot?.iconTheme || 'light'],
        '--cta-border-radius': radiusOptions[this.dealershipSeezarBot?.borderRadius || 'rounded'],
        '--text-direction': this.textDirection === 'rtl' ? 'rtl' : 'ltr',
        '--icon-rotate': this.textDirection === 'rtl' ? 'rotate(180deg)' : '',
        '--faq-arrow-fill': this.darkTheme ? 'white' : 'black'
      }
    },
    avatarIconThemes() {
      return iconThemes
    },
    defaultMessages() {
      return {
        TODAY: this.t.today,
        RESET_CHAT: 'Reset Chat',
        THINKING: this.t.thinking,
        DELETING_CHAT: 'Clearing chat history',
        CHAT_ID: this.t.chatId
      }
    },
    firstMessage() {
      if (this.customAttributes.longFirstMessage) return this.customAttributes.longFirstMessage
      return this.dealershipSeezarBot?.welcomeMessage ? this.dealershipSeezarBot?.welcomeMessage : `Hello, I'm ${this.dealershipSeezarBot?.botName || 'Seezar'} 👋 I'm your personal assistant. How can I help you?`
    },
    firstMessageDate() {
      if (this.cachedFirstMessageDate) {
        return this.cachedFirstMessageDate
      }
      return this.calculateFirstMessageDate()
    },
    messageListLastIndex() {
      return this.messages.length - 1
    },
    errorMessages() {
      return {
        ERROR_LOADING_MESSAGE: this.t?.errorLoadingMessage,
        WEB_SOCKET_ERROR: this.t?.wsConnectionError || 'Unable to connect, please try again later'
      }
    },
    toolMapping() {
      return {
        faq_search: {
          text: this.t.faq_search
        },
        inventory_search: {
          text: this.t.inventory_search
        },
        create_carousel_component_tool: {
          text: this.t.create_carousel_component_tool
        },
        create_comparison_component_tool: {
          text: this.t.create_comparison_component_tool
        },
        create_leads_form_component_tool: {
          text: this.t.create_leads_form_component_tool
        },
        listing_lookup_tool: {
          text: this.t.listing_lookup_tool
        },
        create_location_cards_component_tool: {
          text: this.t.create_location_cards_component_tool
        },
        create_listing_location_tool: {
          text: this.t.create_listing_location_tool
        }
      }
    }
  },
  trackData: ['botId', 'franchiseId', 'dealershipId', 'chatReferenceId', 'chatUuid'],
  watch: {
    animationInterval() {
      this.startAnimationRepeat()
    },
    animationRepeat(newValue) {
      if (!newValue && this.animationInterval) {
        clearInterval(this.animationInterval)
      } else {
        this.startAnimationRepeat()
      }
    },
    slideIn(v) {
      if (!v) {
        // if there is no response being streamed, close the connection
        if (!this.disabled) {
          this.closeWebSocketConnection()
        }
      } else {
        clearInterval(this.typingInterval)
        this.openWebSocketConnection()
        this.setLastSessionKey()
      }
    },
    bodyOverflow(newValue) {
      document.body.style.overflow = newValue
    },
    'dealershipSeezarBot.customAvatarImages': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.handleLoadIcons()
        }
      }
    },
    chatModalStyles() {
      this.injectThemeStyles()
    },
    // displayChatSizeOptions(newValue) {
    //   if (newValue) document.addEventListener('click', this.onClickOutsideChatSizeoptions)
    //   else document.removeEventListener('click', this.onClickOutsideChatSizeoptions)
    // },
    t() {},
    // chat component
    botId() {
      this.getChatHistory()
      this.openWebSocketConnection()
    },
    showWidget(v) {
      if (v) {
        this.track('widget_load', { url: window?.location?.href })
      }
    },
    messages() {
      this.scrollToBottom()
    },
    darkTheme(isDark) {
      this.switchTheme(isDark)
    },
    chatComponentStyles() {
      this.injectChatComponentThemeStyles()
    }
  },
  created() {
    this.initialBodyOverflow = window.getComputedStyle(document.body).overflow
  },
  mounted() {
    this.getDefaultConfig()
    this.getSeezarConfig()
    this.checkViewportAndExpand()
    this.injectThemeStyles()
    this.startAnimationRepeat()
    this.loadFontStylesheet()
    
    this.mediaQueryList = window.matchMedia(`(min-width: ${this.maxWidthForMobileOrTablet}px)`)
    this.mediaQueryList.addEventListener('change',this.checkScreenSize)
    this.checkScreenSize()

    // chat component
    // this.getSeezarConfig()
    this.getChatHistory()
    this.addClearListeners()
    // this.injectThemeStyles()
    this.injectChatComponentThemeStyles()
    window.addEventListener('externallyOpenChatModal', this.externallyOpenChatModal)
  },
  beforeUnmount() {
    document.body.style.overflow = this.initialBodyOverflow
    if (this.animationInterval) {
      clearInterval(this.animationInterval)
    }
    this.mediaQueryList.removeEventListener('change', this.checkScreenSize)

    // chat component
    const scrollContainer = this.$refs.messages
    scrollContainer.removeEventListener('scroll', this.handleUserScroll)
    this.closeWebSocketConnection()
    window.removeEventListener('externallyOpenChatModal', this.externallyOpenChatModal)
  },
  methods: {
    handleHoverWidget() {
      this.track('hover_widget')
    },
    handleHoverChat() {
      this.track('hover_chat')
    },
    onOpenChatClick() {
      this.track('open_bot')
      this.toggleSlide()
    },
    onCloseChatClick() {
      this.track('close_bot')
      this.toggleSlide()
    },
    startAnimationRepeat() {
      if (!this.animationRepeat) {
        if (this.animationIntervalFunction) {
          clearInterval(this.animationIntervalFunction)
        }
        return
      }
      if (this.animationIntervalFunction) {
        clearInterval(this.animationIntervalFunction)
      }

      this.animationIntervalFunction = setInterval(() => {
        this.animationStart = !this.animationStart
      }, this.animationInterval)
    },
    handleClosePopupMesage() {
      this.closePopupMesage = true
      this.setLastSessionKey()
    },
    setLastSessionKey() {
      if (this.lastSessionId == this.currentSessionId)
        return

      this.lastSessionId = this.currentSessionId
      localStorage.setItem(storageKeys.LAST_SESSION_ID, this.lastSessionId)
    },
    checkViewportAndExpand() {
      if (window.innerWidth <= this.maxWidthForMobileOrTablet) {
        this.changeChatSize(chatSizeKeys.full)
      }
    },
    isDefaultWidgetIcon(url, array) {
      // eslint-disable-next-line no-useless-escape
      const extractedPart = url.replace(/^https?:\/\/[^\/]+\/[^\/]+\/[^\/]+\//, '').replace(/\.[^\.]+$/, '')
      const index = array.findIndex(item => item === extractedPart)
      return index !== -1 ? true : false
    },
    loadFontStylesheet() {
      const fontName = 'Manrope'

      const existingFontLink = document.getElementById('seezar-font-stylesheet')
      if (existingFontLink) {
        existingFontLink.parentNode.removeChild(existingFontLink)
      }

      const fontLink = document.createElement('link')
      fontLink.id = 'seezar-font-stylesheet'
      fontLink.rel = 'stylesheet'
      fontLink.href = `https://fonts.googleapis.com/css2?family=${fontName}:wght@400;700&display=swap`
      fontLink.dataset.fontStylesheet = true
      
      document.head.appendChild(fontLink)
    },
    setDefaultHeaderIcon() {
      if (this.dealershipSeezarBot?.customAvatarImages?.[0]) return
      const iconUrl = this.darkTheme ? 'https://seez-platform-uploads.s3.eu-central-1.amazonaws.com/images/icons/icons/seezar_dark.png' : 'https://seez-platform-uploads.s3.eu-central-1.amazonaws.com/images/icons/icons/seezar_light.png'
      this.headerIcon = iconUrl
    },
    setDefaultWidgetIcon() {
      this.widgetIcon = 'https://seez-platform-uploads.s3.eu-central-1.amazonaws.com/images/seezar/SeezarIcon.png'
    },
    handleLoadIcons() {
      if (this.dealershipSeezarBot?.widgetIcon) {
        this.widgetIcon = `${import.meta.env.VITE_CHAT_IMAGE_URL}/image/0x0/${this.dealershipSeezarBot?.widgetIcon}.webp`
      } else {
        this.setDefaultWidgetIcon()
      }
      if (this.dealershipSeezarBot?.customAvatarImages?.length > 0) {
        this.headerIcon = `${import.meta.env.VITE_CHAT_IMAGE_URL}/image/0x0/${this.dealershipSeezarBot?.customAvatarImages[0]}.webp`
      } else {
        this.setDefaultHeaderIcon()
      }
    },
    async getSeezarConfigByPath(pathname, websiteUrl) {
      const result = await getSeezarConfigByClientPath(pathname, websiteUrl)

      if (!result.clientSeezarBot) {
        this.getDefaultConfig()
        this.handleLoadIcons()
        this.botId = null
        return
      }

      this.dealershipSeezarBot = result.clientSeezarBot.config
      this.setCustomAttributes()

      this.botId = result.clientSeezarBot.id

      if (this.dealershipSeezarBot.widgetPosition) {
        this.widgetPosition = this.dealershipSeezarBot.widgetPosition
      }
      if (this.dealershipSeezarBot.welcomeMessage) {
        this.welcomeMessage = this.dealershipSeezarBot.welcomeMessage
      }
      this.automaticChatOpen()

      if (this.dealershipSeezarBot.customCss) {
        const customCss = JSON.parse(this.dealershipSeezarBot.customCss)
        this.widgetMargin = customCss?.widgetMargin || 'none'
        this.widgetAnimation = customCss?.widgetAnimation || WidgetAnimations.NONE
        this.animationRepeat = customCss?.animationRepeat
      }

      this.handleLoadIcons()
    },
    async getSeezarConfig() {
      const url = new URL(window.location.href)
      const pathname = url.pathname
      const websiteUrl = url.href

      if (this.passedBotId) {
        this.botId = this.passedBotId
        const result = await getSeezarConfigByBotId(this.passedBotId)
        this.setupDealershipSeezarBot(result.seezarBot)
        return
      }

      if (this.dealershipId) {
        const result = await getSeezarConfig(this.dealershipId, this.franchiseId)
        this.setupDealershipSeezarBot(result.dealershipSeezarBot)
        return
      }

      this.getSeezarConfigByPath(pathname, websiteUrl)
    },
    setupDealershipSeezarBot(result) {
      this.dealershipSeezarBot = result.config
      this.setCustomAttributes()
      this.botId = result.id
      this.widgetPosition = this.dealershipSeezarBot.widgetPosition

      if (this.dealershipSeezarBot.customCss) {
        const customCss = JSON.parse(this.dealershipSeezarBot.customCss)
        this.widgetMargin = customCss?.widgetMargin || 'none'
        this.widgetAnimation = customCss?.widgetAnimation || WidgetAnimations.NONE
        this.animationRepeat = customCss?.animationRepeat
      }

      if (this.dealershipSeezarBot.welcomeMessage) {
        this.welcomeMessage = this.dealershipSeezarBot.welcomeMessage
      }
      this.automaticChatOpen()

      this.handleLoadIcons()
    },
    async getDefaultConfig() {
      if (this.defaultTheme === 'dark') {
        return (this.chatConfig = dark.chatConfig)
      }
      this.handl
      return (this.chatConfig = light.chatConfig)
    },
    toggleSlide() {
      if (this.slideIn) {
        this.slideIn = !this.slideIn
        this.hideElement = !this.hideElement
        this.$emit('close')
      } else {
        this.hideElement = !this.hideElement
        setTimeout(() => {
          this.slideIn = !this.slideIn
        }, [100])
      }
    },
    onClickChangeTheme() {
      this.darkTheme = !this.darkTheme
      this.track('chat_change_theme', { theme: this.darkTheme ? 'dark' : 'light' })
      this.setDefaultHeaderIcon()
      this.chatConfig = this.darkTheme ? dark.chatConfig : light.chatConfig
    },
    injectThemeStyles() {
      const styleSheet = document.createElement('style')
      let cssString = Object.entries(this.chatModalStyles)
        .filter(p => p[1] != null)
        .map(p => `${p[0]}: ${p[1]};`)
        .join(' ')
      if (this.dealershipSeezarBot?.customCss) cssString += ' ' + this.dealershipSeezarBot.customCss
      styleSheet.innerHTML = `.chatModal { ${cssString} }`
      styleSheet.setAttribute('data-custom', 'seez-modal')
      const rootNode = this.$el.getRootNode()
      rootNode.querySelectorAll('[data-custom="seez-modal"]').forEach(x => x.remove())
      const firstNoStyle = rootNode.querySelector(':not(style)')
      if (firstNoStyle) rootNode.insertBefore(styleSheet, firstNoStyle)
      else rootNode.prepend(styleSheet)
    },
    async automaticChatOpen() {
      if (!this.isDesktopScreen) return
      if ((this.lastSessionId == this.currentSessionId) && !this.onSeezarDashboard) return
      if (!this.customAttributes.automatedChatStart) return
      const chatStartTime = this.customAttributes.chatStartTime
      const seconds = !chatStartTime || isNaN(chatStartTime) || chatStartTime < 0 ? this.fallBackChatStartTime : chatStartTime
      setTimeout(() => {
        this.handleChatOpen()
        this.track('automatic_chat_start')
      }, seconds * 1000)
    },
    changeChatSize(newSize) {
      Object.keys(this.chatSizes).forEach(size => {
        if (size === newSize) this.chatSizes[size].current = true
        else this.chatSizes[size].current = false
      })

      this.displayChatSizeOptions = false
    },
    onClickExpand() {
      this.displayChatSizeOptions = !this.displayChatSizeOptions
    },
    onClickOutsideChatSizeoptions() {
      this.displayChatSizeOptions = false
    },
    setCustomAttributes() {
      if (!this.dealershipSeezarBot) return

      if (this.dealershipSeezarBot.customAttributes) {
        for (const attr of this.dealershipSeezarBot.customAttributes) {
          if (customAttributes[attr.key]) {
            if (booleanCustomAttributes[attr.key]) this.customAttributes[attr.key] = attr.value === 'true'
            else this.customAttributes[attr.key] = attr.value
          }
        }
      }
    },
    handleChatOpen() {
      if (!this.slideIn) this.toggleSlide()
    },
    checkScreenSize() {
      this.isDesktopScreen = this.mediaQueryList.matches
    },
    // chat component
    onPolicyClick() {
      this.track('seezar_policy_click')
    },
    onCustomDisclaimerClick() {
      this.track('custom_disclaimer_click')
    },
    onInputFocus() {
      this.track('chat_input_focus')
    },
    handleIntersection(isVisible) {
      if (isVisible) {
        this.scrollToBottom()
      }
    },
    parseChatMessages(chatData) {
      const parsedMessages = chatData
        .map((message, index) => ({
          id: `message${index + 1}`,
          message: this.bbCodeToHTML(message.content),
          author: { role: message.role === 'assistant' ? 'bot' : 'user' },
          date: message.sent_at
        }))
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date)
        })

      return parsedMessages
    },
    async getChatHistory() {
      this.loading = true

      if (!this.botId) return

      try {
        const chatHistoryData = await getUserChatHistory({ instanceId: +this.botId })
        const parsedMessages = this.parseChatMessages(chatHistoryData)
        this.messages = parsedMessages
        this.calculateFirstMessageDate()
        this.scrollToBottom()
      } catch (e) {
        this.error = true
        this.loading = false
        console.error(e)
        this.$root.alert = { type: 'Error', message: 'There was an error getting user chat' }
      } finally {
        this.loading = false
      }
    },
    // async getSeezarConfig() {
    //   if (this.botConfig) {
    //     this.dealershipSeezarBot = this.botConfig
    //     return
    //   }

    //   this.chatConfig = this.darkTheme ? dark.chatConfig : light.chatConfig
    //   if (!this.dealershipId && !this.botId) return

    //   let result
    //   if (this.botId) {
    //     result = await getSeezarConfigByBotId(this.botId)
    //     this.dealershipSeezarBot = result.seezarBot.config
    //   } else {
    //     result = await getSeezarConfig(this.dealershipId, this.franchiseId)
    //     this.dealershipSeezarBot = result.dealershipSeezarBot.config
    //   }
    // },
    bbCodeToHTML(str) {
      return str.replace(/\[url\](.*?)\[\/url\]/g, '<a href="$1" target="_blank">$1</a>')
    },
    getClientPath() {
      const url = new URL(window.location.href)
      return url.pathname
    },
    async sendMessageToAPI(newMessageObject) {
      this.setLoadingMessage()
      this.disabled = true
      const clientPath = this.getClientPath()

      try {
        const response = await callCeaserApi(APIOperations.SEND_MESSAGE, { message_in: newMessageObject.message, timestamp: newMessageObject.date.toISOString() }, this.botId, clientPath)
        const { message_out: message = '', chatReferenceId = '', usage = null, failsafeUrl = '' } = response
        const parsedMessage = this.bbCodeToHTML(message)
        this.chatReferenceId = chatReferenceId
        this.usage = usage

        if (failsafeUrl) {
          const errorMessage = `<a href="${failsafeUrl}" />${parsedMessage}</a>`
          this.replaceLoadingMessage(errorMessage, true)
          this.disabled = false
          return
        }
        if (!message) {
          this.replaceLoadingMessage(this.errorMessages.ERROR_LOADING_MESSAGE, true)
        }

        this.replaceLoadingMessage(parsedMessage)

        this.scrollToBottom()
      } catch (error) {
        console.error('Error sending message:', error)
        this.replaceLoadingMessage(this.errorMessages.ERROR_LOADING_MESSAGE, true)
      } finally {
        this.disabled = false
      }
    },
    handleResponseFromWebSocket(chunk) {
      if (this.loading) {
        const newMessage = {
          id: crypto.randomUUID(),
          author: { role: chatRoles.SYSTEM },
          words: [chunk],
          date: new Date()
        }

        this.messages[this.messageListLastIndex] = newMessage
        this.loading = false
        this.wordsListLastIndex = 0
        this.originalMessage = chunk
      } else {
        this.originalMessage += chunk
        this.messages[this.messageListLastIndex].words = this.originalMessage
          .split(/<seez-sdk-.*\/>|<seez-sdk-.*>(<\/seez-sdk-.*>)?/g)
          .filter(Boolean)
          .reduce((acc, curr, index, array) => {
            acc.push(curr)
            if (index < array.length - 1) {
              acc.push('<seez-sdk-loader limit-height></seez-sdk-loader>')
            }
            return acc
          }, [])
      }
    },
    setMetricsRecordingInitialValues() {
      this.messageSentTime = performance.now()
      this.botFirstResponseTime = null
      this.botContentResponseTime = null
      this.isReceivingMessage = false
      this.currentMessageMetrics = {
        chunkCount: 0,
        totalBytes: 0,
        startTime: null
      }
    },
    sendMessageToWebSocket(message) {
      this.setLoadingMessage()
      this.disabled = true

      if (this.wsClosedDueToError) {
        setTimeout(() => this.replaceLoadingMessage(this.errorMessages.WEB_SOCKET_ERROR, true), 2000)
        return false
      }

      if (this.wsConnection?.readyState != WebSocket.OPEN) {
        this.messageBuffer = message
        return false
      }

      this.setMetricsRecordingInitialValues()

      this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.SEND_MESSAGE, message_in: message }))
      this.requestMetaData()

      return true
    },
    sanitizeMessage(message) {
      const leadingWhitespace = message.match(/^\s*/)[0]

      const cleanMessage = message.replace(/<seez-sdk-[\w-]*\s*\/?>/g, '')

      const parser = new DOMParser()
      const parsed = parser.parseFromString(cleanMessage, 'text/html')

      return leadingWhitespace + (parsed.body.textContent || '')
    },
    handleNewMessage(newMessage) {
      const originalMessageText = newMessage.text
      const sanitizedMessageText = this.sanitizeMessage(originalMessageText)

      if (sanitizedMessageText === originalMessageText) {
        const messageObject = {
          id: crypto.randomUUID(),
          author: { role: chatRoles.USER },
          message: sanitizedMessageText,
          date: new Date()
        }

        const copy = [...this.messages, messageObject]
        this.messages = copy
        // this.sendMessageToAPI(messageObject)
        const messageSent = this.sendMessageToWebSocket(originalMessageText)
        if (messageSent) {
          const messageTypingStats = newMessage?.typingStats
          this.track('message_sent', { messageTypingStats })
        }
      }
    },
    handleFAQSelected(questionAsMessage) {
      this.handleNewMessage(questionAsMessage)
    },
    selectTooltipFAQ(faq) {
      this.toggleSlide()
      this.handleFAQSelected({ text: faq, sender: chatRoles.USER })
    },
    setLoadingMessage(message = this.defaultMessages.THINKING) {
      this.loading = true
      this.messages.push({
        id: chatStates.LOADING,
        author: { role: chatRoles.SYSTEM },
        message,
        date: new Date()
      })
    },
    changeLoadingMessageText(messageObject) {
      if (!this.loading) return

      try {
        this.messages[this.messageListLastIndex].message = this.toolMapping[messageObject?.preProcessing]?.text || this.defaultMessages.THINKING
      } catch (e) {
        console.log('Unable to parse pre-processing message:', e)
        this.messages[this.messageListLastIndex].message = this.defaultMessages.THINKING
      }
    },
    addClearListeners() {
      window.addEventListener('clearChat', this.onConfirmClearChat)
      window.addEventListener('cancelClearChat', this.cancelClearChat)
    },
    cancelClearChat() {
      const filteredMessages = this.messages.filter(item => item.id !== 'clear')
      this.messages = filteredMessages
      this.clearDisabled = false
    },
    setClearChatHistoryMessage() {
      this.messages.push({
        id: chatStates.CLEAR,
        author: { role: chatRoles.SYSTEM },
        message: `<seez-sdk-clear-chat id="${this.botId}" border-radius="${this.dealershipSeezarBot?.borderRadius}"></seez-sdk-clear-chat>`,
        date: new Date()
      })
      this.scrollToBottom()
    },
    removeLoadingMessage() {
      this.loading = false
      const loadingMessageIndex = this.messages.findIndex(message => message.id === chatStates.LOADING)

      if (loadingMessageIndex !== -1) {
        this.messages.splice(loadingMessageIndex, 1)
      }
    },
    replaceLoadingMessage(message, isError = false) {
      this.trackInteractiveComponentReceived(message)
      const loadingMessageIndex = this.messages.findIndex(message => message.id === chatStates.LOADING)

      if (loadingMessageIndex !== -1) {
        const newMessage = {
          id: isError ? chatStates.ERROR : crypto.randomUUID(),
          author: { role: chatRoles.SYSTEM },
          message: message,
          date: new Date()
        }

        this.messages[loadingMessageIndex] = newMessage
        this.loading = false
      }
    },
    onClickTodaysMessageButton() {
      const currentDate = new Date().toISOString().split('T')[0]

      const messagesToday = this.messages.filter(message => {
        const messageDate = new Date(message.date).toISOString().split('T')[0]
        return messageDate === currentDate
      })

      if (messagesToday.length > 0) {
        const firstTodayMessage = messagesToday[0]
        const messagesContainer = this.$refs.messages
        const firstMessageContainer = messagesContainer.firstElementChild
        const targetMessageElement = firstMessageContainer.querySelector(`#${firstTodayMessage.id}`)

        if (targetMessageElement) {
          targetMessageElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
    },
    onClickResetMessages() {
      this.clearDisabled = true
      this.setClearChatHistoryMessage()
      this.track('clear_chat_click')
    },
    onConfirmClearChat() {
      this.messages = []
      this.clearDisabled = false
    },
    scrollToBottom() {
      if (this.noMessage) return
      this.$nextTick(() => {
        const messagesElement = this.$refs.messages

        if (messagesElement) {
          const scrollOptions = {
            top: messagesElement.scrollHeight,
            left: 0,
            behavior: 'smooth'
          }

          messagesElement.scrollTo(scrollOptions)

          setTimeout(() => {
            messagesElement.scrollTo({
              ...scrollOptions,
              top: messagesElement.scrollHeight + 90
            })
          }, 100)
        }
      })
    },
    switchTheme(isDark) {
      const targetConfig = isDark ? dark.chatConfig : light.chatConfig

      if (this.avatarStyles) {
        targetConfig.theme.chatAvatarStyles = this.avatarStyles
      }

      this.chatConfig = targetConfig
    },
    // injectThemeStyles() {
    injectChatComponentThemeStyles() {
      const styleSheet = document.createElement('style')
      const cssString = Object.entries(this.chatComponentStyles)
        .filter(p => p[1] != null)
        .map(p => `${p[0]}: ${p[1]};`)
        .join(' ')
      styleSheet.innerHTML = `.chat { ${cssString} }`
      styleSheet.setAttribute('data-custom', 'seez-chat')
      const rootNode = this.$el.getRootNode()
      rootNode.querySelectorAll('[data-custom="seez-chat"]').forEach(x => x.remove())
      const firstNoStyle = rootNode.querySelector(':not(style)')
      if (firstNoStyle) rootNode.insertBefore(styleSheet, firstNoStyle)
      else rootNode.prepend(styleSheet)
    },
    async trackInteractiveComponentReceived(message) {
      const components = message.match(/<seez-sdk-\w+(?:-[a-zA-Z]+)*\s+[^>]*>/g)

      if (!components) return

      const componentNameRegex = /<seez-sdk-(\S+)/
      const parser = new DOMParser()

      components.forEach(component => {
        const document = parser.parseFromString(component, 'text/html')
        const element = document.body.firstChild

        const properties = {}
        for (const attr of element.attributes) {
          properties[attr.name] = attr.value
        }

        let [, componentName] = component.match(componentNameRegex)
        componentName = componentName.replace('-', '_')

        this.track(`chat_${componentName}_sent`, properties)
      })
    },
    calculateFirstMessageDate() {
      if (this.messages.length) {
        const date = new Date(this.messages[0].date)
        date.setMinutes(date.getMinutes() - 1)
        this.cachedFirstMessageDate = date
        return date
      } else {
        const currentDate = new Date()
        this.cachedFirstMessageDate = currentDate
        return currentDate
      }
    },
    closeWebSocketConnection() {
      this.closeWsConnection = true
      this.wsConnection?.close()
    },
    openWebSocketConnection() {
      // open the connection if the chat is open && there is no connection or the connection state is not CONNECTING or OPEN
      if (this.slideIn && (!this.wsConnection || ![0, 1].includes(this.wsConnection?.readyState))) {
        this.establishWebSocketConnection()
      }
    },
    establishWebSocketConnection() {
      if (!this.botId) return

      this.closeWsConnection = false
      this.wsClosedDueToError = false
      this.originalMessage = ''

      this.wsConnection = new WebSocket(import.meta.env.VITE_CHAT_STREAMING_ENDPOINT)
      this.addWebSocketEventListeners()
    },
    handleResponseObject(response) {
      try {
        const message = JSON.parse(response)

        // handle preprocessing object
        if (message[webSocketOperations.PREPROCESSING]) {
          this.changeLoadingMessageText(message)
          return
        }

        // handle uuid and chatRef
        if (webSocketOperations.UUID in message || webSocketOperations.CHAT_REF in message) {
          this.setIdVariables(message)
        }
      } catch (e) {
        console.log('Unable to parse response:', e)
      }
    },
    createHeaders() {
      return {
        'client-id': window.seezSdk.clientId,
        'client-seezar-bot-id': this.botId,
        'seez-anonymous-id': localStorage.getItem('Seez-Anonymous-Id'),
        'seez-session-id': sessionStorage.getItem('Seez-Session-Id')
      }
    },
    setIdVariables(messageObject) {
      try {
        if (!messageObject.chatRef && !messageObject.uuid) {
          if (!this.metaDataInterval) {        
            this.metaDataInterval = setInterval(() => {
              this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.METADATA }))
              this.metaDataFetchTries += 1
              if (this.metaDataFetchTries >= this.metaDataTryLimit) {
                clearInterval(this.metaDataInterval)
              }
            }, this.metaDataTimeGap * 1000)
          }
          return
        }

        clearInterval(this.metaDataInterval)
        this.chatReferenceId = messageObject.chatRef
        this.chatUuid = messageObject.uuid
      } catch (e) {
        console.log('Unable to set id variables', e)
      }
    },
    generateMetrics() {
      if (this.isReceivingMessage) {
        this.isReceivingMessage = false
        this.currentMessageMetrics.totalTime = performance.now() - this.currentMessageMetrics.startTime
        delete this.currentMessageMetrics.startTime
        const dataRate = (this.currentMessageMetrics.totalBytes / this.currentMessageMetrics.totalTime) * 1000
        this.currentMessageMetrics.dataRate = +dataRate.toFixed(2)
        const wordCount = this.originalMessage ? this.originalMessage.trim().split(/\s+/).length : 0
        const totalTimeMinutes = this.currentMessageMetrics.totalTime / 60000
        const wordsPerMinute = totalTimeMinutes > 0 ? wordCount / totalTimeMinutes : 0
        this.currentMessageMetrics.wordCount = wordCount
        this.currentMessageMetrics.wordsPerMinute = +wordsPerMinute.toFixed(2)
        if (this.botFirstResponseTime != null) {
          this.currentMessageMetrics.botFirstResponseTime = this.botFirstResponseTime
        }
        if (this.botContentResponseTime != null) {
          this.currentMessageMetrics.botContentResponseTime = this.botContentResponseTime
        }
        if (this.botFirstResponseTime != null) {
          this.currentMessageMetrics.botFirstResponseTime = this.botFirstResponseTime
        }
        if (this.botContentResponseTime != null) {
          this.currentMessageMetrics.botContentResponseTime = this.botContentResponseTime
        }
        this.currentMessageMetrics.botLoadingTime = this.botContentResponseTime - this.botFirstResponseTime
        return this.currentMessageMetrics
      } else {
        return null
      }
    },
    addWebSocketEventListeners() {
      const headers = this.createHeaders()

      this.wsConnection.addEventListener('message', event => {
        if (this.botFirstResponseTime === null && this.messageSentTime) {
          this.botFirstResponseTime = performance.now() - this.messageSentTime
        }
        if (!this.isReceivingMessage) {
          this.isReceivingMessage = true
          this.currentMessageMetrics.startTime = performance.now()
        }
        const safeMessageLinkRegex = /^<a\b[^>]*\bhref=(["']).*?\1[^>]*>[\s\S]*<\/a>$/i
        if (safeMessageLinkRegex.test(event.data.trim())) {
          this.track('fail_safe_message_sent')
        }

        if (this.handShakeSent) {
          this.checkForMessageBuffer()
          this.handShakeSent = false
        } else if (event.data.startsWith('{')) {
          this.handleResponseObject(event.data)
        } else if (!reservedWebSocketStringsKeys[event.data]) {
          if (this.botContentResponseTime === null && this.messageSentTime) {
            this.botContentResponseTime = performance.now() - this.messageSentTime
          }
          this.currentMessageMetrics.chunkCount += 1
          const chunkSize = event.data.length
          this.currentMessageMetrics.totalBytes += chunkSize

          this.handleResponseFromWebSocket(event.data)
        }

        if (event.data == reservedWebSocketStrings.END_OF_CHAT) {
          const metrics = this.generateMetrics()
          if (metrics) {
            this.track('message_received', metrics)
            this.botFirstResponseTime = null
            this.botContentResponseTime = null
          }
          if (this.loading) {
            this.replaceLoadingMessage(this.errorMessages.ERROR_LOADING_MESSAGE, true)
          } else {
            this.messages[this.messageListLastIndex].message = this.originalMessage
            this.messages[this.messageListLastIndex].words = null
            this.trackInteractiveComponentReceived(this.originalMessage)
            this.originalMessage = ''
            this.scrollToBottom()
          }

          this.disabled = false
          this.scrollToBottom()

          if (!this.slideIn) this.closeWebSocketConnection()
        }
      })

      this.wsConnection.addEventListener('open', () => {
        this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.HANDSHAKE, headers }))
        this.handShakeSent = true
        this.errorsOccurred = 0

        this.pingPongInterval = setInterval(() => this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.PING })), this.pingPongTimeGap * 60000)
      })

      this.wsConnection.addEventListener('error', () => {
        console.log('Web socket closed due to some error')

        this.errorsOccurred += 1
        if (this.errorsOccurred >= this.errorBuffer) {
          this.wsClosedDueToError = true
          this.closeWsConnection = true
          if (this.loading) this.replaceLoadingMessage(this.errorMessages.WEB_SOCKET_ERROR, true)
          return
        }

        if (this.loading) {
          this.messageBuffer = this.messages[this.messageListLastIndex - 1].message
        }
      })

      this.wsConnection.addEventListener('close', () => {
        clearInterval(this.pingPongInterval)

        if (!this.closeWsConnection) {
          console.log('Web socket closed, restarting websocket connection')
          this.establishWebSocketConnection()
        } else {
          console.debug('Web socket closed')
        }
      })
    },
    handleScroll() {
      const messagesElement = this.$refs.messages
      if (messagesElement.scrollTop < this.lastScrollTop) this.scrollingUp = true
      this.lastScrollTop = messagesElement.scrollTop
    },
    handleEndOfMessagesVisibility(isVisible) {
      if (isVisible) this.scrollingUp = false
      if (this.disabled && !this.scrollingUp && !isVisible) this.scrollToBottom()
    },
    externallyOpenChatModal(event) {
      // this.$emit('onOpenChatModal')
      this.handleChatOpen()
      const externalMessage = event.detail?.message
      if (externalMessage) {
        if (!this.disabled) {
          this.handleNewMessage({
            text: externalMessage,
            sender: chatRoles.USER,
            typingStats: null
          })
        } else {
          console.log('Bot is currently responding to a query')
        }
      }
    },
    checkForMessageBuffer() {
      if (this.messageBuffer) {
        this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.SEND_MESSAGE, message_in: this.messageBuffer }))
        this.messageBuffer = ''
        this.requestMetaData()
      }
    },
    requestMetaData() {
      if (!this.firstMessageSent) {
        this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.METADATA }))
        this.firstMessageSent = true
      }
    }
  }
}
</script>

<style>
.chatModal {
  transition: all 0.3s ease-out;
  font-family: var(--font-family), sans-serif;
  position: fixed;
  z-index: 10000;
  font-size: 16px;

  &.middleRightChat,
  &.topRightChat,
  &.bottomRightChat {
    .commonScreen {
      left: unset;
      right: 1px;
    }
  }

  &.middleLeftChat,
  &.topLeftChat,
  &.bottomLeftChat {
    .commonScreen {
      left: 1px;
      right: unset;
    }
    }
  }

.chatPosition {
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 768px) {
      &.middleRightChat,
      &.topRightChat,
      &.bottomRightChat {
        left: unset;
        right: 1em;
      }

      &.middleLeftChat,
      &.topLeftChat,
      &.bottomLeftChat {
        left: 1em;
        right: unset;
      }
    }
  }

.lowerWidgetButton {
  visibility: hidden;
  opacity: 0;
  transform: v-bind('chatOpensOnRight ? "translateX(110%)" : "translateX(-110%)"');
  position: fixed;
  background: var(--primary-color);
  border: none;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 10px;
  bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animateLowerWidgetButton {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);

  transition:
    transform 0.5s ease-in-out,
    visibility 0s linear,
    opacity 0.5s ease-in-out;
}

.original {
  top: 0em;
  bottom: 0em;
  left: 0em;
  right: 0em;
  @media screen and (min-width: 768px) {
    width: 34em;
    top: 1em;
    bottom: 5em;

    &.marginNoneChat {
      bottom: 4em;
    }
    &.marginSmallChat {
      bottom: 4.5em;
    }
    &.marginMediumChat {
      bottom: 5em;
    }
    &.marginLargeChat {
      bottom: 6em;
    }
  }
}

.small {
  bottom: 0em;
  top: 0em;
  left: 0em;
  right: 0em;
  @media screen and (min-width: 768px) {
    height: 34em;
    width: 26em;
    top: unset;

    &.marginNoneChat {
      bottom: 4em;
    }
    &.marginSmallChat {
      bottom: 4.5em;
    }
    &.marginMediumChat {
      bottom: 5em;
    }
    &.marginLargeChat {
      bottom: 6em;
    }
  }
}

@keyframes circleAnimation {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  opacity: 1;
  transition: all 0.2s ease-in-out;
  z-index: 3000;
  flex-direction: column;

  &.collapsed {
    position: initial;
    display: contents;
  }

  @media screen and (min-width: 768px) {
    padding: 1em;

    &.marginNoneChat {
      bottom: 3.5em;
      padding: 1em 0;
    }
    &.marginSmallChat {
      bottom: 4em;
      padding: 1em 0.45em;
    }
    &.marginMediumChat {
      bottom: 4.5em;
    }
    &.marginLargeChat {
      bottom: 5em;
      padding: 1em 1.75em;
    }
  }
}

.content {
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg-color);
  overflow: auto;
  position: relative;
  opacity: 0;
  visibility: hidden;
  box-shadow:
    1px 2px 27px 0px rgba(0, 0, 0, 0.5),
    -3px -2px 10px 0px rgba(0, 0, 0, 0.15);
  transform: v-bind('chatOpensOnRight ? "translateX(110%)" : "translateX(-110%)"');
  transition:
    transform 1s ease-in-out,
    opacity 1s ease-in-out,
    visibility 0s linear 1s;

  &.display {
    display: none;
  }

  @media screen and (min-width: 480px) {
    border-radius: var(--chat-modal-content-border-radius);
  }
}

.slideIn {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    visibility 0s linear;
  display: block;

  &.marginNoneChat {
    transform: v-bind('chatOpensOnRight ? "translateX(1em)" : "translateX(-1em)"');
  }

  &.marginSmallChat {
    transform: v-bind('chatOpensOnRight ? "translateX(0.5em)" : "translateX(-0.5em)"');
  }

  &.marginMediumChat {
    transform: v-bind('chatOpensOnRight ? "translateX(0.1em)" : "translateX(-0.1em)"');
  }

  &.marginLargeChat {
    transform: v-bind('chatOpensOnRight ? "translateX(-0.75em)" : "translateX(0.75em)"');
  }
}

.slideButton {
  direction: ltr;
  position: fixed;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  border: none;
  cursor: pointer;
  z-index: 999;
  background-color: var(--chat-modal-slider-button-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 101px;
  border-bottom-left-radius: 101px;
  overflow: visible;

  img {
    width: auto;
    height: auto;
    max-width: 60px;
    max-height: 150px;
    object-fit: cover;
  }

  svg {
    min-width: 17px;
    min-height: 11px;
    width: 17px;
    height: 11px;
  }
}

.topRight {
  top: 0;
  left: auto;
  bottom: auto;
  transform: translateY(0);
}
.bottomRight {
  bottom: 0;
  top: auto;
  left: auto;
  transform: translateY(0);
}
.bottomLeft {
  bottom: 0;
  left: 0;
  top: auto;
  right: auto;
  transform: translateY(0);
}
.topLeft {
  top: 0;
  transform: translateY(0);
  left: 0;
  bottom: auto;
  right: auto;
}

.middleLeft {
  top: 50%;
  left: 0;
  bottom: auto;
  right: auto;
}
.middleRight {
  top: 50%;
  left: auto;
  bottom: auto;
}

.marginSmall {
  margin: 0.5em;
}
.marginMedium {
  margin: 1em;
}
.marginLarge {
  margin: 2em;
}
.marginNone {
  margin: 0;
}

.customIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 48px;
  width: 60px;
  height: 60px;
  padding: 2em;

  > img {
    width: auto;
    height: auto;
    max-width: 32px;
    max-height: 50px;
    object-fit: cover;
    transform: translateY(0.1em);
  }
  }

.squared {
  border-radius: 6px;
}

.buttonTheme {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* // widget animations start */
.expand {
  animation: expandAndShrink 2s ease-in-out normal;
}
@keyframes expandAndShrink {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
}

.shakeHorizontal {
  animation: shake 2s ease-in-out normal;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.shakeVertical {
  animation: shakeAt45Degrees 2.5s ease-in-out normal;
}
@keyframes shakeAt45Degrees {
  0%,
  100% {
    transform: rotate(0deg) translate(0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: rotate(-45deg) translate(-5px, 5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: rotate(45deg) translate(5px, -5px);
  }
}

.bounce {
  animation: bounce 2s ease-in-out normal;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.pulse {
  animation: pulse 2s ease-in-out normal;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.swing {
  animation: swing 2s ease-in-out normal;
}
@keyframes swing {
  20% {
    transform: rotate(80deg);
  }
  40% {
    transform: rotate(-40deg);
  }
  60% {
    transform: rotate(80deg);
  }
  80% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.flip {
  animation: flip 2s ease-in-out normal;
}
@keyframes flip {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

/* // chat component */
.chat {
  --base-blue: '#FFFFFF';
  --chat-secondary-button-bg-color: #fafafa;

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.messages {
  place-self: stretch;
  overflow: auto;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.noMessage {
  grid-template-rows: 1fr auto;
}

.chatInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.2em;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, var(--chat-bg-color) 100%);
  direction: var(--text-direction);

  width: -webkit-fill-available;
  max-width: 60.625em;
  margin: 0 auto;
  padding: 0 18px;

  svg {
    width: 13px;
    height: 12px;
    cursor: pointer;
  }
}

.actionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.75em;
  font-size: 0.75em;
  text-align: center;

  > button {
    font-family: var(--font-family);
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
}

.actionButtons.ltr {
  direction: ltr;
}

.actionButtons.rtl {
  direction: rtl;
}

.todaysMessageButton {
  border: none;
  background: transparent;
  max-width: 4.563em;
  cursor: pointer;
  color: var(--chat-base-text-color);
}

.chatReferenceId {
  margin: 0;
  color: var(--chat-base-text-color);
}

.disclaimerText {
  color: var(--chat-base-text-color);
  text-align: center;
  font-size: 0.625em;
  background-color: var(--chat-bg-color);

  @media screen and (max-width: 390px) {
    font-size: 0.563em;
  }
}

.customDisclaimerText {
  color: var(--chat-base-text-color);
  text-align: center;
  font-size: 0.625em;
  background-color: var(--chat-bg-color);
  padding: 1em 0;

  @media screen and (max-width: 390px) {
    font-size: 0.563em;
  }
}

.disclaimerText:first-of-type {
  margin-bottom: 5px;
}
.disclaimerText:last-of-type {
  padding-bottom: 1em;
  text-align: center;
  margin: auto;
}

.link {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
}

.tooltipContainer {
  display: inline-block;
  position: relative;
  top: 2px;

  &.ltr {
    right: 2px;
  }
  &.rtl {
    left: 2px;
  }
}

.tooltipText {
  visibility: hidden;
  width: 250px;
  background-color: #fff;
  color: #262626;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: 125%;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow:
    0px -1px 13px 0px rgba(0, 0, 0, 0.15),
    0px 4px 12px 0px rgba(0, 0, 0, 0.13);

  &.ltr {
    left: 50%;
    margin-left: -12px;
  }
  &.rtl {
    right: 50%;
    margin-right: -12px;
  }
}

.tooltipText::after {
  content: '';
  position: absolute;
  top: 100%;
  margin-left: -9px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltipText.ltr::after {
  left: 6%;
}

.tooltipText.rtl::after {
  right: 3%;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
</style>
