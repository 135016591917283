import { handleThemeStringToObject } from './helpers'

export const APIOperations = {
  SEND_MESSAGE: 'sendMessage',
  CLEAR_CHAT: 'clearChat',
  CHAT_HISTORY: 'chatHistory'
}

const getApiHeaders = async (botId = '1') => {
  const token = await window.seezSdk.getAccessToken()

  const seezAnonymousId = localStorage.getItem('Seez-Anonymous-Id')
  const seezSessionId = sessionStorage.getItem('Seez-Session-Id')
  const clientId = window.seezSdk.clientId

  const headers = {
    'client-id': clientId || 'mp',
    'Content-Type': 'application/json'
  }

  if (botId) {
    headers['client-seezar-bot-id'] = botId
  }

  if (token) {
    headers.authorization = `Bearer ${token}`
  }

  if (seezAnonymousId) {
    headers['Seez-Anonymous-Id'] = seezAnonymousId
  }
  if (seezSessionId) {
    headers['Seez-Session-Id'] = seezSessionId
  }

  return headers
}

export const callCeaserApi = async (operation, bodyData = {}, botId = '', clientPath) => {
  try {
    const headers = await getApiHeaders(botId)

    const response = await fetch(import.meta.env.VITE_SEEZAR_API, {
      method: 'POST',
      headers,
      body: JSON.stringify({ operation, ...bodyData, clientPath })
    })

    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('Error:', error)
    return 'Error when getting this message'
  }
}

export const getUserChatHistory = async ({ instanceId, perPage = 20 }) => {
  const response = await callCeaserApi('getMyChatHistory', { limit: perPage }, instanceId)
  return response
}

const SEEZAR_CONFIG = `
botName
primaryColor
secondaryColor
description
sampleQuestions
font
welcomeMessage
borderRadius
botAvatarImage
userAvatarImage
customAvatarImages
defaultSeezarAvatarImages
defaultUserAvatarImages
widgetIcons
iconTheme
widgetIcon
chatConfig
iconTheme
widgetIcon
avatarBgColor
avatarIconTheme
widgetIconTheme
widgetPosition
activeTheme
customCss
customAttributes {
  key
  value
}
termsAndConditions
`

export const QUERY_GET_USER_CHAT_HISTORY = `
query getUserChatHistory($userId: ID!, $instanceId: Int!, $perPage: Int = 100, $lastEvaluatedKey: String) {
  getUserChatHistory(userId: $userId, instanceId: $instanceId, perPage: $perPage, lastEvaluatedKey: $lastEvaluatedKey) {
      chatHistory {
        content
        role
        sentAt
      }

      lastEvaluatedKey {
        chat_id
        sent_at
      }

      pageInfo {
        total
        page
        perPage
        pages
      }
  }
}
`

export const getSeezarConfigByClientPath = async (clientPath, websiteUrl) => {
  const QUERY_BOT_BY_CLIENT_ID = `
  query clientSeezarBot($clientPath: String!, $websiteUrl: String!) {
    clientSeezarBot(clientPath: $clientPath, websiteUrl: $websiteUrl) {
      id
      config {
        ${SEEZAR_CONFIG}
        }
    }
  }`

  const variables = { clientPath, websiteUrl }
  const response = await window.seezSdk.queryApi(QUERY_BOT_BY_CLIENT_ID, variables)

  return response
}

export const getSeezarConfig = async (dealershipId, franchiseId) => {
  const QUERY_BOT_CONFIG_BY_DEALER = `
query dealershipSeezarBot($dealershipId: ID!) {
  dealershipSeezarBot(dealershipId: $dealershipId) {
    id
    config {
        ${SEEZAR_CONFIG}
      
      }
  }
}`

  const QUERY_BOT_CONFIG_BY_FRANCHISE = `
query dealershipSeezarBot($dealershipId: ID!, $franchiseId: ID!) {
  dealershipSeezarBot(dealershipId: $dealershipId, franchiseId: $franchiseId) {
    id
    config {
        ${SEEZAR_CONFIG}
      
      }
  }
}`
  const variables = franchiseId ? { dealershipId, franchiseId } : { dealershipId }
  const query = franchiseId ? QUERY_BOT_CONFIG_BY_FRANCHISE : QUERY_BOT_CONFIG_BY_DEALER
  const response = await window.seezSdk.queryApi(query, variables)

  return response
}

export const getSeezarConfigByBotId = async botId => {
  const QUERY_BOT_CONFIG_BY_BOT_ID = `
query seezarBot($botId: ID!) {
  seezarBot(botId: $botId) {
    id
    config {
      ${SEEZAR_CONFIG}  
    }
  }
}` 
  const variables = { botId }
  const response = await window.seezSdk.queryApi(QUERY_BOT_CONFIG_BY_BOT_ID, variables)
  return response
}

export const fetchChatConfig = async () => {
  try {
    const query = `{
  targetSiteByClientId(id:"mp")
  {
    
    chatConfig{
      faqTitle
      faqSubtitle
      faqQuestions
      disclaimer
      theme
    }
  }
}`
    const response = await window.seezSdk.queryApi(query)

    const chatConfigWithStringTheme = response?.targetSiteByClientId?.chatConfig
    const chatConfigWithObjectTheme = handleThemeStringToObject(chatConfigWithStringTheme)
    return chatConfigWithObjectTheme
  } catch (e) {
    console.log(e)
    return 'Error getting chat configuration'
  }
}

export const getChatConfig = async (theme = 'light') => {
  try {
    const response = await fetch(`src/components/Chat/themes/${theme}.json`)

    if (response.ok) {
      const json = await response.json()
      return json.chatConfig
    } else {
      console.error('Failed to load chatConfig.json')
    }
  } catch (error) {
    console.error('Error loading chatConfig.json:', error)
  }
}
