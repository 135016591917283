<template>
  <div @click="handleWrapperClick" :class="{ inputWrapper: true, radius: isExpanded }" :style="chatInputStyles">
    <textarea @focus="$emit('focus')" ref="chatInput" :id="id" class="chatInput" v-model="inputText" @input="adjustTextareaHeight" @keydown="handleKeyDown" :placeholder="placeholder" :class="{ expanded: isExpanded, disabled }" :rows="textareaRows" :disabled="disabled" />
    <button id="chatInputSendButton" :class="['chatInputSendButton', { disabled: !inputText }, textDirection]" :disabled="!inputText || disabled" @click="sendMessage">
      <MessageIcon class="icon" />
    </button>
  </div>
</template>

<script>
import MessageIcon from '../../assets/arrow-right.svg'
import { langMixin } from '../lang'

export default {
  name: 'ChatInput',
  components: { MessageIcon },
  mixins: [langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    id: { type: String, required: true },
    placeholder: { type: String, required: true },
    theme: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    botConfig: { type: Object, default: () => {}, required: false }
  },
  emits: ['new-message', 'focus'],
  data() {
    return {
      inputText: '',
      enterClicked: false,
      shiftEnterPressed: false,
      textareaRows: 1,
      typingStartTime: null, // Timestamp when typing starts
      typingEndTime: null, // Timestamp when typing ends (message sent)
      keystrokeIntervals: [], // Array to store intervals between keystrokes
      lastKeystrokeTime: null, // Timestamp of the last keystroke
      totalTypingTime: 0, // Total time taken to type the message (ms)
      averageKeystrokeInterval: 0, // Average time between keystrokes (ms)
      keystrokesPerSecond: 0, // Keystrokes per second
      wordsPerMinute: 0, // Words per minute
      wordCount: 0
    }
  },
  computed: {
    isExpanded() {
      return this.textareaRows > 1 || this.enterClicked || this.shiftEnterPressed
    },
    buttonIconTheme() {
      if (!this.botConfig?.iconTheme) return 'black'
      return this.botConfig?.iconTheme === 'light' ? 'white' : 'black'
    },
    chatInputStyles() {
      let inputRadius = this.theme.chatInputStyles.inputRadius
      let buttonRadius = this.theme.chatInputStyles.buttonRadius
      if (this.botConfig?.borderRadius && this.botConfig.borderRadius === 'square') {
        inputRadius = '6px'
        buttonRadius = '6px'
      }

      const handleColor = color => {
        if (color === 'Transparent' || !color) return this.theme.chatInputStyles.chatBgColor
        return color
      }

      return {
        '--button-bg-color': handleColor(this.botConfig.primaryColor),
        '--chat-bg-color': this.theme.chatInputStyles.chatBgColor,
        '--input-radius': inputRadius,
        '--button-radius': buttonRadius,
        '--chat-base-text-color': this.theme.chatBaseTextColor,
        '--input-border-color': this.theme.chatInputStyles.inputBorderColor,
        '--chat-placeholer-color': this.theme.chatInputStyles.chatPlaceholderColor,
        '--icon-color': this.buttonIconTheme
      }
    }
  },
  watch: {
    inputText() {
      if (this.inputText === '') {
        this.textareaRows = 1
      }
    },
    disabled(isDisabled) {
      if (!isDisabled) {
        this.$nextTick(() => {
          this.$refs.chatInput.focus()
        })
      }
    }
  },
  methods: {
    sendMessage() {
      if (this.inputText.trim() !== '') {
        this.typingEndTime = performance.now()
        this.calculateTypingStats()

        const message = {
          text: this.inputText,
          sender: 'user',
          typingStats: {
            totalTypingTime: this.totalTypingTime,
            averageKeystrokeInterval: parseFloat(this.averageKeystrokeInterval),
            keystrokesPerSecond: parseFloat(this.keystrokesPerSecond),
            wordsPerMinute: +parseFloat(this.wordsPerMinute),
            wordCount: this.wordCount
          }
        }

        this.$emit('new-message', message)
        this.inputText = ''
        this.resetTypingTracking()
      }
    },
    calculateNumRows(textarea) {
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10)
      const rows = Math.floor(textarea.scrollHeight / lineHeight)
      this.textareaRows = rows < 1 ? 1 : rows
    },
    adjustTextareaHeight(event) {
      const textarea = event.target
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
      this.enterClicked = false
      this.calculateNumRows(textarea)

      if (this.inputText.trim() === '') {
        this.shiftEnterPressed = false
      }
    },
    handleWrapperClick() {
      this.$nextTick(() => {
        this.$refs.chatInput.focus()
      })
    },
    handleKeyDown(event) {
      this.trackKeystroke(event)

      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.sendMessage()
        this.shiftEnterPressed = false
      } else if (event.key === 'Enter' && event.shiftKey) {
        this.shiftEnterPressed = true
      }
    },
    trackKeystroke(event) {
      // Filter out non-character keys (e.g., Shift, Ctrl, Alt)
      if (!this.isCharacterKey(event)) {
        return
      }

      const currentTime = performance.now()
      if (!this.typingStartTime) {
        this.typingStartTime = currentTime
      }

      if (this.lastKeystrokeTime) {
        const interval = currentTime - this.lastKeystrokeTime
        this.keystrokeIntervals.push(interval)
      }

      this.lastKeystrokeTime = currentTime
    },
    isCharacterKey(event) {
      const key = event.key
      return key.length === 1
    },
    calculateTypingStats() {
      if (this.typingStartTime && this.typingEndTime) {
        this.totalTypingTime = this.typingEndTime - this.typingStartTime
        if (this.keystrokeIntervals.length > 0) {
          const sumIntervals = this.keystrokeIntervals.reduce((acc, curr) => acc + curr, 0)
          this.averageKeystrokeInterval = sumIntervals / this.keystrokeIntervals.length
        } else {
          this.averageKeystrokeInterval = 0
        }

        const totalSeconds = this.totalTypingTime / 1000
        this.keystrokesPerSecond = totalSeconds > 0 ? (this.keystrokeIntervals.length / totalSeconds).toFixed(2) : 0

        const wordCount = this.inputText.trim().split(/\s+/).length
        this.wordCount = wordCount
        const minutes = totalSeconds / 60
        this.wordsPerMinute = minutes > 0 ? (wordCount / minutes).toFixed(2) : 0
      }
    },
    resetTypingTracking() {
      this.typingStartTime = null
      this.typingEndTime = null
      this.keystrokeIntervals = []
      this.lastKeystrokeTime = null
      this.totalTypingTime = 0
      this.averageKeystrokeInterval = 0
      this.keystrokesPerSecond = 0
      this.wordsPerMinute = 0
    }
  }
}
</script>

<style>

.inputWrapper {
  position: relative;
  border-radius: var(--input-radius);
  border: 1px solid var(--input-border-color);
  background: var(--chat-bg-color);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7em 0.4em;
  width: -webkit-fill-available;
}

.chatInput {
  width: 70%;
  margin-inline-end: 1em;
  height: 1.25em;
  line-height: 1.25em;
  min-height: 1.25em;
  max-height: 1.25em;
  resize: none;
  border: none;
  background: var(--chat-bg-color);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  color: var(--chat-base-text-color);
  font-size: 1em;
  font-family: var(--font-family);
  padding: 0 0.5em;

  @media screen and (min-width: 768px) {
    font-size: 0.875em;
    width: 85%;
  }
}

textarea::placeholder {
  color: var(--chat-placeholer-color);
  font-family: var(--font-family);
}

.icon {
  transform: rotate(-90deg);
  stroke: var(--icon-color);
  min-width: 13px;
  min-height: 12px;
}
.chatInput.expanded {
  max-height: 12.5em;
  transition: max-height 0.6s ease;
  border-radius: 2px;
}

.radius {
  border-radius: 0.5em;
}

.chatInputSendButton {
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  background-color: var(--button-bg-color);
  border-radius: var(--button-radius);
  border: none;
  cursor: pointer;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.chatInputSendButton.ltr {
  right: 0.375em;
}

.chatInputSendButton.rtl {
  left: 0.375em;
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
</style>
