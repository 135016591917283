<template>
  <div :class="['insuranceOption', selected ? 'selected' : '']">
    <div class="insuranceHeader" @click="updateInsurance(option)">
      <div class="name">
        <div v-if="multiple" :class="['checkbox', selected ? 'selected' : '']">
          <input type="checkbox" :checked="selected" />
        </div>
        <div v-else :class="['radio', selected ? 'selected' : '']">
          <input type="radio" :checked="selected" />
        </div>
        <span>{{ t[option.name?.replaceAll(' ', '')] ?? option.name?.replaceAll(' ', '') }}</span>
      </div>
      <Loader v-if="selected && loading" />
      <div v-if="isRoot && !loading" class="price">
        <h3>{{ insurancePrice }}</h3>
      </div>
    </div>
    <div class="insuranceBody" v-if="selected">
      <div v-if="isRoot && option.description" class="description" v-html="renderDisclaimer(option)" />
      <div class="children" v-if="getChildren.length > 0">
        <InsuranceOption :multiple="multiple" v-for="child in getChildren" :key="child?.id" :selected="selectedOptionIds.includes(child.id) || selectedChild?.id === child.id" :option="child" :selected-option-ids="selectedOptionIds" :options="getChildren" @update-insurance="updateInsurance(child)" />
      </div>
      <div v-if="isRoot && option.link" class="link" @click="openLink(option)">
        <p>{{ t.linkToSCF ?? 't.linkToSCF' }}</p>
        <div class="icon"><ShareIcon /></div>
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../sdk.js'
import { langMixin } from '../../lang'
import Loader from '../../Loader.ce.vue'
import ShareIcon from '@/assets/external-link.svg'

export default {
  name: 'InsuranceOption',
  components: { ShareIcon, Loader },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    option: { type: Object, required: true },
    // selectedOptionId: { type: String, default: null },
    selectedOptionIds: { type: Array, default: null },
    options: { type: Array, default: () => [] },
    selectedId: { type: Object, default: null },
    price: { type: Number, default: null },
    isRoot: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false }
  },
  emits: ['selectOption', 'updateInsurance'],
  data() {
    return {
      showChildren: this.selected,
      selectedChild: this.subOption
    }
  },
  computed: {
    getChildren() {
      return this.options.filter(io => this.option?.id !== null && io.parentId === this.option?.id)
    },
    insurancePrice() {
      const selectedInsurance = this.options.find(o => this.selectedOptionIds.includes(o.id) && (o.parentId === this.option.id || o.id === this.option.id) && o.price?.amount && o.price?.currency)
      if (this.selected && selectedInsurance) {
        return selectedInsurance?.price?.text || this.formatNumber(selectedInsurance.price.amount, 0, selectedInsurance.price.currency)
      }
      return null
    }
  },
  methods: {
    updateInsurance(option) {
      this.$emit('updateInsurance', option)
    },
    renderDisclaimer(option) {
      const key = option?.description.replace(/ /g, '')
      return this.t[key] ?? option.description
    },
    openLink(option) {
      const link = option.link
      if (link) {
        return window.open(link)
      }
    }
  }
}
</script>
