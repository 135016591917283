<template>
  <div class="noOrdersWrapper">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1038_2320)">
        <path d="M24.5 8.75C26.43 8.75 28 10.32 28 12.25V24.5C28 26.43 26.43 28 24.5 28H3.5C1.57 28 0 26.43 0 24.5V12.25C0 10.32 1.57 8.75 3.5 8.75H24.5Z" fill="var(--highlight)" />
        <path opacity="0.4" d="M25.38 5.69C25.38 4.96 24.79 4.38 24.06 4.38H3.94C3.21 4.38 2.63 4.96 2.63 5.69 2.63 6.41 3.21 7 3.94 7H24.06C24.79 7 25.38 6.41 25.38 5.69ZM22.75 1.31C22.75.59 22.16 0 21.44 0H6.56C5.84 0 5.25.59 5.25 1.31 5.25 2.04 5.84 2.63 6.56 2.63H21.44C22.16 2.63 22.75 2.04 22.75 1.31Z" fill="var(--highlight)" />
      </g>
    </svg>
    <h3>Du er klar!</h3>
    <p>Der er ingen åbne ordrer på din konto på nuværende tidspunkt.</p>
  </div>
</template>

<script>
export default {
  name: 'SeezActiveOrderCancellation'
}
</script>
