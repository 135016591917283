<template>
  <div class="tooltip-container" @mouseover="showTooltip" @mouseleave="hideTooltip">
    <slot />
    <div v-if="isTooltipVisible" :class="['tooltip-modal', placement]">
      <slot name="tooltip-content">
        <div v-html="message" />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipComponent',
  props: {
    message: { type: String, default: '' },
    placement: { type: String, default: 'bottomRight' }
  },
  data() {
    return {
      isTooltipVisible: false
    }
  },
  methods: {
    showTooltip() {
      this.isTooltipVisible = true
    },
    hideTooltip() {
      this.isTooltipVisible = false
    }
  }
}
</script>

<style lang="scss">
.tooltip-container {
  position: relative;

  .tooltip-modal {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
    top: calc(100% + 0.25rem);
    border-radius: 1rem;
    padding: 0.625rem;
    width: max-content;
    max-width: 16rem;
    right: 0;

    @media screen and (min-width: 50rem) {
      right: auto;
      left: -1rem;
    }

    > div {
      max-height: 250px;
      overflow-y: auto;
    }

    > * {
      color: black;
    }

    &::before {
      @media screen and (min-width: 50rem) {
        position: absolute;
        top: -0.5rem;
        left: 0.5rem;
        content: '';
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid white;
      }
    }

    &.bottomLeft {
      left: auto;
      @media screen and (min-width: 50rem) {
        right: -3.5rem;
      }

      &::before {
        left: auto;

        @media screen and (min-width: 50rem) {
          right: 3rem;
        }
      }
    }

    &.bottomRight {
      @media screen and (max-width: 50rem) {
        right: auto;
        left: -3rem;
      }
    }
  }
}
</style>
