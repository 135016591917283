<template>
  <div :ref="id" class="chatMessage" :style="messageStyles">
    <div class="avatarWrapper" v-if="!isUserMessage">
      <div class="avatarContainer">
        <UserAvatar :id="role" :avatar-theme="chatAvatarStyles[activeUserChat]" :bot-config="botConfig" :url="url" />
        <span>{{ botConfig?.botName ? botConfig?.botName : 'Seezar'}}</span>
      </div>
      <button v-if="showCloseButton" @click="$emit('close')">
        <XIcon />
      </button>
    </div>
    <ChatLoading v-if="loading" :message="message" :chat-message-styes="chatMessageStyes" />
    <div v-else :class="{ userMessage: isUserMessage, fullWidth: !isUserMessage }">
      <div v-if="words" :class="{ message: true, error }">
      <span  v-for="(word, index) in words" :key="index"  v-html="word" />
    </div>
    <div v-else :class="{ message: true, error }">
      <div v-html="message" />
      <slot v-if="showFaqs" name="faqs" />
    </div>
    </div>
    <time v-if="date" class="date">{{ dateStampFormatted }}</time>
  </div>
</template>
<script>
import UserAvatar from './UserAvatar.ce.vue'
import ChatLoading from './ChatLoading.ce.vue'
import { langMixin } from '../lang'
import { hexToRgba } from './helpers'
import XIcon from '@/assets/close.svg'

export default {
  name: 'ChatMessage',
  components: { UserAvatar, ChatLoading, XIcon },
  mixins: [langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    message: { type: String , default: ''},
    role: { type: String, required: true },
    id: { type: String, required: true },
    date: { type: [String, Date], default: '' },
    chatAvatarStyles: { type: Object, required: true },
    chatMessageStyes: { type: Object, required: true },
    botConfig: { type: Object, default: () => {} },
    words: { type: Array , default: null },
    showFaqs: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: false }
  },
  emits: ['close'],
  data() {
    return {
      htmlTypedMessage: '',
      typingSpeed: 8,
      typedMessage: '',
      typingIndex: 0,
      activeUserChat: 'userAvatarStyles'
    }
  },
  computed: {
    loading() {
      return this.id === 'loading'
    },
    error() {
      return this.id === 'error'
    },
    url() {
      return this.activeUserChat === 'userAvatarStyles' ? this.botConfig.userAvatarImage : this.botConfig.botAvatarImage || ''
    },
    messageStyles() {
      const userColor = !this.botConfig.primaryColor || this.botConfig.primaryColor == 'Transparent' ? hexToRgba(this.chatMessageStyes.userMessageBgColor, 0.1)  : hexToRgba(this.botConfig.primaryColor || '', 0.1)
      return {
        '--date-color': this.chatMessageStyes.dateColor,
        '--message-color': this.chatMessageStyes.messageColor,
        '--button-text-color': this.chatMessageStyes.buttonTextColor,
        '--align-chat-message': this.isUserMessage ? 'flex-end' : 'flex-start',
        '--user-message-bg': userColor
      }
    },
    dateStampFormatted() {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }

      const language = this.language

      const formatDate = date => {
        return new Intl.DateTimeFormat(language, options).format(date)
      }
      const parsedDate = new Date(this.date)
      const formattedDate = formatDate(parsedDate)
      return formattedDate
    },
    isUserMessage() {
      return this.role === 'user'
    }
  },
  mounted() {
    this.defineTheme()
  },
  methods: {
    defineTheme() {
      this.activeUserChat = this.role === 'user' ? 'userAvatarStyles' : 'ceaserAvatarStyles'
    },
  }
}
</script>
<style>
.avatarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: var(--align-chat-message);

  button {
    outline: none;
    background: #F5F5F5;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.avatarContainer {
  display: flex;
  gap: 0.5em;
  align-items: center;
  align-self: var(--align-chat-message);

  > span { 
    font-size: 14px;
    color: black;
    font-weight: 600;
    font-family: var(--font-family);
  }
}

.date,
.message {
  padding: 0;
  margin: 0;
  overflow: auto;

  a {
    color: var(--message-color);
    font-weight: 600;
  }
}

.chatMessage {
  display: flex;
  flex-direction: column;
  align-items: var(--align-chat-message);
  gap: 0.5em;
}

.userMessage {
  background-color: var(--user-message-bg);
  padding: 8px;
  border-radius: 10px;
}

.fullWidth {
  width: 100%;
}

.date {
  color: var(--date-color);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.message {
  color: var(--message-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  white-space: pre-wrap;
  font-family: var(--font-family);
}

.message.error {
  color: red;
}
</style>
