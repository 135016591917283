<template>
  <Modal @close="close">
    <div class="cancelOrderDialog">
      <p class="title">{{ t['active_order_change'] }}</p>
      <p class="subtitle">{{ t['you_have_active_order'] }} {{ details.name }}</p>
      <div v-if="details" class="carDetails">
        <img :src="imageUrl" :alt="details.name" @error="imageError = true" loading="lazy" />
        <b>{{ details.name }}</b>
        <span class="dark">{{ details.variant }}</span>
        <span>{{ details.year }} &middot; {{ details.kilometrage.toLocaleString(language) }} Km</span>
        <span class="showInDesktop">{{ t.price }}</span>
        <b class="price" v-if="details.retailPrice?.value">{{ formatPrice(details.retailPrice) }}</b>
      </div>
      <Loader v-else />

      <div class="notice-text">
        <div>{{ t['notice_text_1'] ?? 'notice_text_1' }}</div>
        <div>{{ t['notice_text_2'] ?? 'notice_text_2' }}</div>
        <div>{{ t['notice_text_3'] ?? 'notice_text_3' }}</div>
      </div>

      <div class="cta">
        <button v-if="pause === 'true'" @click="onPause">{{ t['pause_active_order'] ?? 'pause_active_order' }}</button>
        <button v-else @click="cancel">{{ t['cancel_start_new_order'] }}</button>
        <button class="preferred" @click="resume">{{ t['resume_order'] }}</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import Loader from '../Loader.ce.vue'
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'

export default {
  name: 'SeezActiveOrderCancellation',
  components: { Modal, Loader },
  mixins: [langMixin('MODAL_ACTIVE_ORDER_COMPONENT'), SeezSdk.vueQueryMixin],
  inheritAttrs: false,
  props: {
    listing: { type: Number, required: true },
    placeholder: { type: String, default: null },
    pause: { type: Boolean, default: false }
  },
  emits: ['close'],
  data() {
    return {
      details: null,
      imageError: false,
      loading: false
    }
  },
  computed: {
    imageUrl() {
      if (this.imageError) return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      const d = this.details
      if (d.images && d.images.length > 0) return `${import.meta.env.VITE_IMAGES_URL}/image/0x0/${d.images[0]}.webp`
      return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
    }
  },
  mounted() {
    this.loadListing()
  },
  methods: {
    async loadListing() {
      this.loading = true
      const result = await this.queryApi('query l($id: ID!) { listing(id: $id) { id name variant year kilometrage retailPrice { value currency } images }}', { id: this.listing })
      this.details = result.listing
      this.loading = false
    },
    close() {
      this.$emit('close', 'Nothing')
    },
    cancel() {
      this.$emit('close', 'CancelAndStartNew')
    },
    resume() {
      this.$emit('close', 'ResumeCurrent')
    },
    onPause() {
      this.$emit('close', 'PauseActiveOrder')
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.cancelOrderDialog {
  @include theme;
  text-align: start;
  direction: auto;
  padding: 10px;
  > p {
    margin: 0.25em 0;

    &.title {
      font-size: 24px;
      font-weight: bold;
    }

    &.subtitle {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .loader {
    height: 140px;
    margin: 2em 0;
  }

  .carDetails {
    display: grid;
    gap: 0.25em 1.5em;
    border: 1px solid #eee;
    margin: 0.8em auto;
    border-radius: 11px;
    padding: 10px;
    box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;

    .showInDesktop {
      display: none;
    }

    > img {
      width: 100%;
      border-radius: 0.5em;
      margin-block-end: 1em;
    }

    > .dark {
      color: #444;
    }

    > .price {
      inset-inline-end: 1em;
      inset-block-end: 1em;
    }

    @media (min-width: 40rem) {
      grid-auto-flow: column;
      grid-template-rows: auto auto 1fr;
      grid-template-columns: auto 1fr 1fr;
      justify-items: start;
      height: calc(120px - 2em);
      padding: 25px;

      .showInDesktop {
        display: block;
      }

      > img {
        grid-row: 1 / -1;
        margin: -1em 0 -1em -1em;
        width: 200px;
        height: 119px;
        border-radius: 11px;
        object-fit: cover;
      }

      > .price {
        position: static;
      }
    }

    > span {
      color: #999;
      font-size: 0.8em;
    }
  }

  .notice-text {
    margin: 16px 0;
    > div {
      color: #353B3C;
      line-height: 28.78px;
      margin: 5px 0
    }
  }

  .cta {
    display: grid;
    gap: 0.75em;
    justify-items: stretch;

    > button {
      border-radius: 4em;
      border: 2px solid var(--highlight);
      background-color: var(--background);
      color: var(--highlight);
      padding: 0.6em 1.5em;
      font-size: clamp(14px, 2vw, 18px);
      font-weight: bold;
      font-family: inherit;
      white-space: nowrap;
      cursor: pointer;

      &.preferred {
        background-color: var(--highlight);
        color: var(--background);
      }
    }

    @media (min-width: 40rem) {
      width: 800px;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: 40rem) {
    padding: 30px;

    &.title {
      font-size: 34px;
      font-weight: bold;
    }

    &.subtitle {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
