<template>
  <div class="userProfile">
    <h1>{{ t.account_settings }}</h1>
    <form @submit.prevent="submitProfile">
      <fieldset>
        <legend>{{ t.personal_information }}</legend>
        <label>
          <span>{{ t.first_name }}</span>
          <input type="text" name="firstName" autocomplete="given-name" :value="user?.firstName" />
        </label>
        <label>
          <span>{{ t.middle_name }}</span>
          <input type="text" name="middleName" :value="user?.middleName" />
        </label>
        <label>
          <span>{{ t.last_name }}</span>
          <input type="text" name="lastName" autocomplete="family-name" :value="user?.lastName" />
        </label>
        <label>
          <span>{{ t.email }}</span>
          <input type="email" name="email" autocomplete="email" :value="user?.email" disabled />
        </label>
        <label>
          <slot name="phonePrefix" />
          <span>{{ t.phone }}</span>
          <input type="tel" name="phone" autocomplete="tel" :value="user?.phone" />
        </label>
      </fieldset>
      <fieldset>
        <legend>{{ t.address_information }}</legend>
        <label>
          <span>{{ t.address }}</span>
          <input type="text" name="address.street" autocomplete="street-address" :value="user?.address?.street" />
        </label>
        <label>
          <span>{{ t.city }}</span>
          <input type="text" name="address.city" :value="user?.address?.city" />
        </label>
        <label>
          <span>{{ t.state }}</span>
          <input type="text" name="address.state" autocomplete="province" :value="user?.address?.state" />
        </label>
        <label>
          <span>{{ t.country }}</span>
          <input type="email" name="address.country" autocomplete="country" :value="getCountry" disabled />
        </label>
      </fieldset>
      <input type="submit" :value="t.save" />
    </form>
    <section class="marketing">
      <label>
        <span>{{ t.marketing_consent }}</span>
        <Toggle :on="user?.acceptedMarketingTerms != null" @change="toggleMarketing" />
      </label>
      <p>
        <slot>
          {{ l.marketing_terms }}
        </slot>
      </p>
    </section>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import { langMixin } from '../lang.js'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import Toggle from '../Toggle.ce.vue'

export default {
  name: 'UserProfile',
  components: { Loader, Toggle },
  mixins: [langMixin('PROFILE_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  inheritAttrs: false,
  props: {
    country: {
      type: String,
      default: 'denmark'
      // validator(value) { return value == null || Object.keys(this.t).includes(value) }
    },
    phoneCode: {
      type: String,
      default: '45' //Danmark phone code
    }
  },
  emits: ['change'],
  data() {
    return {
      loading: false,
      user: null,
      error: null
    }
  },
  computed: {
    l() {
      return this.languageResources?.LOGIN_COMPONENT_TRANSLATIONS ?? {}
    },
    getCountry() {
      return this.user?.address?.country || this.t[this.country]
    }
  },
  mounted() {
    this.loadProfile()
  },
  methods: {
    async loadProfile() {
      this.loading = true
      try {
        const { me } = await this.queryApi('{me { id firstName middleName lastName email phone acceptedMarketingTerms address { street postalCode city state country }}}')
        this.user = me
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    async submitProfile(e) {
      this.error = null
      const formData = Object.fromEntries(new FormData(e.target))
      for (const key in formData) {
        formData[key] = formData[key]?.trim()
        const parts = key.split('.')
        if (parts.length === 2) {
          if (!(parts[0] in formData)) formData[parts[0]] = {}
          formData[parts[0]][parts[1]] = formData[key]
          delete formData[key]
        }
      }
      this.loading = true
      try {
        const query = 'mutation updateme($details:UserInputType) { updateMe(details: $details) { id firstName middleName lastName email phone acceptedMarketingTerms address { street postalCode city state country }}}'
        const payload = { details: {...formData, address: {...formData.address, country: this.getCountry }, ...(this.phoneCode && { phoneCode: this.phoneCode})} }
        const { updateMe } = await this.queryApi(query, payload)
        this.user = updateMe
        await window.seezSdk.getAccessToken(true)
        this.$emit('change')
      } catch (error) {
        console.error(error)
        this.error = 'Error saving profile'
      }
      this.loading = false
    },
    async toggleMarketing(e) {
      this.loading = true
      try {
        this.user.acceptedMarketingTerms = await this.queryApi(`mutation { changeMarketingPreferences(accepted:${e}) }`)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '../../base.scss';

.userProfile {
  @include theme;

  position: relative;
  background-color: var(--background);
  padding: 0em;

  > h1 {
    text-align: start;
    font-size: 1.5em;
    margin-block-start: 1em;
    margin-inline-start: 0.5em;
    color: black;
  }

  > form {
    input {
      border-radius: 3em;
    }

    > fieldset {
      border: none;
      display: flex;
      gap: 1em;
      flex-wrap: wrap;
      margin: 1em 0 1em 0;
      padding: 0;

      > legend {
        padding: 0;
        margin-block-end: 1em;
      }

      > label {
        flex-grow: 1;
        min-width: 30%;
        > * {
          display: block;
          width: 100%;
          box-sizing: border-box;
        }

        > span {
          color: #999;
          font-size: 0.75em;
          margin: 0 0 0.25em 2em;
        }

        > input {
          padding: 1em 1.5em;
          border: 1px solid var(--border-color);
          font-family: var(--base-font);
        }
      }
    }

    > [type='submit'] {
      background: var(--highlight);
      color: var(--background);
      border: none;
      font-size: 1em;
      padding: 1em 3em;
      margin-inline-start: auto;
      display: block;
      min-width: 30%;
      font-family: var(--base-font);
    }
  }

  > section.marketing {
    margin-block-start: 3em;

    > label {
      display: flex;

      > span {
        flex-grow: 1;
      }
    }

    > p {
      font-size: 0.8em;
      max-height: 16em;
      overflow: auto;
    }
  }

  > .loader {
    position: absolute;
    inset: 0;
  }
}
</style>
