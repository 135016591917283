<template>
  <Modal @close="modalClosed">
    <div class="tradeinOffer">
      <template v-if="offer?.state === 'offerSent'">
        <h2>{{ t.tradein_offer_title }}</h2>
        <span>{{ t.tradein_value_offered }}</span>
        <b>{{ formatPrice({ value: offer?.offerAmount }, offer?.currency) }}</b>
        <span>{{ t.outstanding_amount }}</span>
        <b>{{ formatPrice({ value: offer?.outstandingFinanceAmount }, offer?.currency) }}</b>
        <hr />
        <span>{{ t.receivable_tradein_amount }}</span>
        <b>{{ formatPrice({ value: offer?.totalAmount }, offer?.currency) }}</b>
        <p>{{ t.you_can_accpet_or_reject }}</p>
        <button class="reject" @click="rejectTradeIn">{{ t.reject_cta }}</button>
        <button class="accept" @click="acceptTradeIn">{{ t.accept_cta }}</button>
      </template>
      <template v-if="offer?.state === 'accepted'">
        <CheckIcon />
        <h2>{{ t.offer_accepted }}</h2>
        <hr />
        <span>{{ t.receivable_tradein_amount }}</span>
        <b>{{ formatPrice({ value: offer?.totalAmount }, offer?.currency) }}</b>
        <p>{{ t.you_can_start_an_order }}</p>
        <button class="accept double" @click="buyNow">{{ t.buy_now }}</button>
      </template>
      <template v-if="offer?.state === 'buyerRejected'">
        <i class="red">&times;</i>
        <h2>{{ t.offer_rejected }}</h2>
        <p v-if="supportEmail || supportPhone">
          {{ t.you_can_contact_us }}
          <a v-if="supportPhone" :href="'tel:' + supportPhone"> {{ supportPhone }}</a>
          <span v-if="supportEmail && supportPhone">&nbsp;{{ t.or_separator }}&nbsp;</span>
          <a v-if="supportEmail" :href="'mailto:' + supportEmail"> {{ supportEmail }}</a>
        </p>
      </template>
      <Loader v-if="loading" />
    </div>
  </Modal>
</template>
//{"data":{"rejectTradeInOffer":{"offerAmount":49500,"outstandingFinanceAmount":0,"totalAmount":49500,"state":"buyerRejected","converted":null}}}
<script>
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import Modal from '../Modal.ce.vue'
import { langMixin } from '../lang'
import CheckIcon from '../../assets/check.svg'

const tradeinFields = '{ currency offerAmount outstandingFinanceAmount totalAmount state converted listing { id targetSite { customerSupport { email phoneNumber }}} customer { id }}'

export default {
  name: 'TradeInOffer',
  components: { Loader, Modal, CheckIcon },
  mixins: [langMixin('TRADEIN_OFFER'), SeezSdk.vueQueryMixin],
  props: { tradein: { type: Number, required: true } },
  emits: ['close', 'buy', 'loaded'],
  data: () => ({
    offer: null,
    loading: false
  }),

  computed: {
    supportEmail() {
      return this.offer?.listing?.targetSite?.customerSupport?.email
    },
    supportPhone() {
      return this.offer?.listing?.targetSite?.customerSupport?.phoneNumber
    }
  },

  mounted() {
    this.queryTradeIn(`{tradeIn(id: ${this.tradein}) ${tradeinFields}}`, 'tradeIn')
  },
  methods: {
    async rejectTradeIn() {
      const user = await window.seezSdk.getCurrentUser()
      if (this.offer?.customer?.id == null || parseInt(this.offer.customer.id) !== user?.id) {
        console.error('You are not allowed to reject this tradein')
        this.modalClosed()
        return
      }
      return this.queryTradeIn(`mutation{rejectTradeInOffer(id:${this.tradein}) ${tradeinFields}}`, 'rejectTradeInOffer')
    },
    async acceptTradeIn() {
      const user = await window.seezSdk.getCurrentUser()
      if (this.offer?.customer?.id == null || parseInt(this.offer.customer.id) !== user?.id) {
        console.error('You are not allowed to accept this tradein')
        this.modalClosed()
        return
      }
      return this.queryTradeIn(`mutation{acceptTradeInOffer(id:${this.tradein}) ${tradeinFields}}`, 'acceptTradeInOffer')
    },
    async queryTradeIn(query, key) {
      this.loading = true
      try {
        const [data, user] = await Promise.all([this.queryApi(query), window.seezSdk.getCurrentUser()])
        this.offer = data[key]
        if (!user?.roles?.some(r => r.rolecode === 'supusr') && (this.offer?.customer?.id == null || parseInt(this.offer.customer.id) !== user?.id)) {
          console.warn('Invalid tradein')
          this.modalClosed()
          return
        }
        this.$nextTick(() => {
          //focus on the last button
          const buttons = this.$el.querySelectorAll('div > button')
          if (buttons.length > 0) buttons[buttons.length - 1].focus()
        })
        this.$emit('loaded', this.offer)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    modalClosed() {
      this.$emit('close')
    },
    buyNow() {
      this.$emit('buy')
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.tradeinOffer {
  @include theme;
  position: relative;
  display: grid;
  padding: 1em;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  min-height: 10rem;
  min-width: 10rem;

  > i,
  > h2,
  > hr,
  > p {
    grid-column-end: span 2;
    text-align: center;
    margin: 0;
  }

  > svg {
    grid-column-end: span 2;
    place-self: center;
    height: 4.5rem;
    margin-block-end: 1rem;
  }

  i {
    font-size: 3rem;
    font-style: normal;
    font-weight: bold;
    text-align: center;

    border-radius: 10em;
    background-color: #33ae5c;
    border: 0.3em solid #e1f3e7;
    color: white;
    place-self: center;
    display: block;
    width: 4.5rem;
    height: 4.5rem;
    line-height: 4.5rem;

    &.red {
      background-color: #ff0009;
      border-color: #ffe1e1;
    }
  }

  > h2 {
    margin-block-end: 1em;
  }

  i + h2 {
    margin-block-start: 0.75em;
  }

  > span {
    text-align: left;
  }

  > p {
    margin: 1em 5ch 0.5em;
  }

  h2 + p {
    margin-block-start: -2em;
  }

  > b {
    text-align: right;
  }

  > hr {
    height: 0;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  > button {
    background: none;
    border: none;
    padding: 0.75em;
    border-radius: 3em;
    cursor: pointer;
    font-size: 1.1em;
    font-family: var(--base-font);

    &.reject {
      color: #ff0009;
      border: 1px solid #ff0009;
    }

    &.accept {
      color: white;
      background-color: var(--highlight);
    }

    &.double {
      grid-column-end: span 2;
    }
  }

  > .loader {
    position: absolute;
    inset: 0;
  }
}

.seezModal > .closer {
  outline: none;
}
</style>
