<template>
  <Modal>
    <div class="tradeInUploadHelp">
      <img :src="imageUrl" alt="" />
      <h2>{{ t.tradeInPicturesGuideTitle }}</h2>
      <p>{{ slideTitle }}</p>
      <p>{{ slideText }}</p>
      <div class="navigation">
        <button @click="jumpTo(currentSlide - 1)"><LeftIcon /></button>
        <button :class="{ dot: true, active: currentSlide === 0 }" @click="jumpTo(0)"><Dot /></button>
        <button :class="{ dot: true, active: currentSlide === 1 }" @click="jumpTo(1)"><Dot /></button>
        <button :class="{ dot: true, active: currentSlide === 2 }" @click="jumpTo(2)"><Dot /></button>
        <button :class="{ dot: true, active: currentSlide === 3 }" @click="jumpTo(3)"><Dot /></button>
        <button @click="jumpTo(currentSlide + 1)"><LeftIcon /></button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { langMixin } from '../lang'
import Modal from '../Modal.ce.vue'
import LeftIcon from '../../assets/left.svg'
import Dot from '../../assets/circle.svg'

export default {
  name: 'TradeInUploadHelp',
  components: { Modal, LeftIcon, Dot },
  mixins: [langMixin('TRADEIN_GUIDE')],
  data() {
    return {
      currentSlide: 0
    }
  },
  computed: {
    imageUrl() {
      const names = ['front', 'back', 'side', 'interior']
      return `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/${names[this.currentSlide]}.webp`
    },
    slideTitle() {
      const names = ['tradeInPicturesGuideFront', 'tradeInPicturesGuideBack', 'tradeInPicturesGuideSide', 'tradeInPicturesGuideInterior']
      return this.t[names[this.currentSlide]]
    },
    slideText() {
      const names = ['tradeInPicturesGuideFrontGuide', 'tradeInPicturesGuideBackGuide', 'tradeInPicturesGuideSideGuide', 'tradeInPicturesGuideInteriorGuide']
      return this.t[names[this.currentSlide]]
    }
  },
  methods: {
    jumpTo(slide) {
      if (slide < 0) this.currentSlide = 3
      else if (slide > 3) this.currentSlide = 0
      else this.currentSlide = slide
    }
  }
}
</script>
<style lang="scss">
@import '../../base';

.tradeInUploadHelp {
  @include theme;

  text-align: left;
  max-width: 30rem;

  > img {
    border-radius: 0.5rem;
    aspect-ratio: 8 / 5;
    width: 100%;
    object-fit: cover;
  }

  > .navigation {
    display: grid;
    grid-template-columns: 1fr repeat(4, auto) 1fr;
    align-items: center;

    > button {
      border: none;
      background: none;
      color: #999;
      cursor: pointer;
      border-radius: 5em;
      margin: 0;
      padding: 0;

      &.dot > svg {
        height: 1rem;
        width: 1rem;
        margin: 0.75rem;
      }

      &.active > svg > path {
        fill: var(--highlight);
      }

      &:first-child,
      &:last-child {
        justify-self: center;
        background-color: var(--highlight);
        color: white;
        height: 3.5rem;
        width: 3.5rem;
        text-align: center;
        display: grid;
        place-items: center;

        > svg {
          height: 1.75rem;
        }
      }

      &:last-child > svg {
        transform: scaleX(-1);
      }
    }
  }
}
</style>
