<template>
  <Modal :closable="false" @close="showCancelModal = false">
    <div class="cancelModal">
      <div>
        <h3>{{ t.areYouSureYouWantToCancel ?? 't.areYouSureYouWantToCancel' }}</h3>
        <span>{{ t.youWillBeCanceling ?? 't.youWillBeCanceling' }}</span>
        <div class="cancelButtons">
          <button class="yes" @click="cancelOrder">{{ t.cancelOrder ?? 't.cancelOrder' }}</button>
          <button class="no" @click="keepOffer">{{ t.keepOrder ?? 't.keepOrder' }}</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import { langMixin } from '../lang'

export default {
  name: 'CancelOrderModal',
  components: { Modal },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS')],
  emits: ['close'],
  methods: {
    cancelOrder() {
      this.$emit('close', 'cancelOrder')
    },
    keepOffer() {
      this.$emit('close', 'keepOffer')
    }
  }
}
</script>