<template>
  <div v-if="externalPaymentResult && !tryAgain" class="depositPayment">
    <div v-if="externalPaymentResult === '#ok'" class="success">
      <div class="icon" />
      <h3>{{ t.externalPaymentSuccessTitle ?? 't.externalPaymentSuccessTitle' }}</h3>
      <span>{{ t.externalPaymentSuccessSubtitle ?? 't.externalPaymentSuccessSubtitle' }}</span>
      <button @click="submitOrder">{{ t.proceedWithBuyingFlow ?? 't.proceedWithBuyingFlow' }}</button>
    </div>
    <div v-if="externalPaymentResult === '#error'" class="error">
      <div class="icon" />
      <h3>{{ t.externalPaymentErrorTitle ?? 't.externalPaymentErrorTitle' }}</h3>
      <span>{{ t.externalPaymentErrorSubtitle ?? 't.externalPaymentErrorSubtitle' }}</span>
      <button @click="tryAgain = true">{{ t.tryAgain ?? 't.tryAgain' }}</button>
    </div>
    <div v-if="externalPaymentResult === '#rejected'" class="rejected">
      <div class="icon" />
      <h3>{{ t.externalPaymentRejectedTitle ?? 't.externalPaymentRejectedTitle' }}</h3>
      <span>{{ t.externalPaymentRejectedSubtitle ?? 't.externalPaymentRejectedSubtitle' }}</span>
      <button @click="tryAgain = true">{{ t.tryAgain ?? 't.tryAgain' }}</button>
    </div>
    <div v-if="externalPaymentResult === '#extensionrejected'" class="rejected">
      <div class="icon" />
      <h3>{{ t.externalPaymentRejectedTitle ?? 't.externalPaymentRejectedTitle' }}</h3>
      <span>{{ t.externalPaymentRejectedSubtitle ?? 't.externalPaymentRejectedSubtitle' }}</span>
      <button @click="clickExtendPayment">{{ t.tryAgain ?? 't.tryAgain' }}</button>
    </div>
  </div>
  <div v-else-if="!externalPaymentResult || tryAgain" class="depositPayment">
    <div class="depositHeader">
      <h3>{{ t.paymentGatewayTitle ?? 't.paymentGatewayTitle' }}</h3>
      <span>{{ t.paymentGatewaySubtitle ? t.paymentGatewaySubtitle.replace('{value}', depositAmount) : 't.paymentGatewaySubtitle' }}</span>
    </div>
    <div class="totalPayable">
      <h3>{{ t.totalPayable ?? 't.totalPayable' }}</h3>
      <h3>{{ formatNumber(depositConfig?.orderDepositAmount ?? 0, 0, depositConfig?.orderDepositCurrency) }}</h3>
    </div>
    <div class="paymentInfo">
      <span>{{ t.payNowBuyLater ?? 't.payNowBuyLater' }}</span>
    </div>
    <div class="payButtons">
      <button class="payPal" @click="initiatePayment('paypal')" :disabled="loading">
        <div class="payPalIcon" />
        {{ t.paypal ?? 'PayPal' }}
      </button>
      <button class="card" @click="initiatePayment('card')" :disabled="loading">
        <div class="cardIcon" />
        {{ t.card ?? 'card' }}
      </button>
    </div>
    <div>
      <p v-if="error" class="error">{{ t.errorInitiatingPayment ?? 't.errorInitiatingPayment' }}</p>
    </div>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import Loader from '../Loader.ce.vue'

export default {
  name: 'DepositPayment',
  components: { Loader },
  mixins: [langMixin('EXTERNAL_PAYMENTS_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    sourceId: { type: String, required: true },
    depositConfig: { type: Object, default: null },
    sourceType: { type: String, default: 'orderDeposit' },
    redirectUrl: { type: String, default: null },
    externalPaymentResult: { type: String, default: null }
  },
  emits: ['redirect', 'submitOrder', 'clickExtendPaymentAgain'],
  data() {
    return {
      error: false,
      loading: false,
      tryAgain: false,
      paymentTypes: {
        card: 'depositCard',
        paypal: 'depositPaypal'
      }
    }
  },
  computed: {
    depositAmount() {
      if (this.depositConfig) {
        return `${this.depositConfig.orderDepositAmount ?? 't.unavailableAmount'} ${this.depositConfig.orderDepositCurrency ?? 't.unavailableCurrency'}`
      }
      return 't.unavailableOrderDepositAmount'
    }
  },
  methods: {
    clickExtendPayment() {
      this.$emit('clickExtendPaymentAgain')
    },
    submitOrder() {
      this.$emit('submitOrder')
    },
    async initiatePayment(paymentMethod) {
      this.error = false
      const paymentProductType = this.paymentTypes[paymentMethod]

      this.loading = true

      try {
        const encodedUrlOk = `${encodeURIComponent(this.redirectUrl)}#ok`
        const encodedUrlError = `${encodeURIComponent(this.redirectUrl)}#error`
        const encodedUrlPending = `${encodeURIComponent(this.redirectUrl)}#pending`
        const encodedUrlRejected = `${encodeURIComponent(this.redirectUrl)}#rejected`

        const mutation = `
        mutation createOrderDepositExternalPayment {
          createOrderDepositExternalPayment(
            orderId: ${this.sourceId}
            input: {
              scf: {
                paymentProductType: ${paymentProductType}
                paymentMethod: ${paymentMethod}
                paymentType: downpayment
                transactionType: purchase
                redirectionURL: {
                  urlPaymentOk: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlOk}"
                  urlPaymentError: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlError}"
                  urlPaymentPending: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlPending}"
                  urlPaymentRejected: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlRejected}"
                }
              }
            }
          ) {
            id
            paymentInfo {
              scf {
                providerUrl
              }
            }
          }
        }`

        const { createOrderDepositExternalPayment } = await this.queryApi(mutation)

        if (!createOrderDepositExternalPayment) {
          this.loading = false
          return (this.error = true)
        }

        const providerUrl = createOrderDepositExternalPayment.paymentInfo.scf.providerUrl
        if (providerUrl) {
          this.$emit('redirect', providerUrl)
        }
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss"></style>
