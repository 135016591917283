<template>
  <div id="search">
    <div class="side">
      <FilterPanel :save-search-clicked="saveSearchClicked" v-model="currentFilters" :options="options" :tags="tags" :preload="preload" :class="{ showFilter: showFilter }" :lg="lg" :total-result="total" @remove="removeFilter" @close="showFilter = false" @change="updateCurrentFilters" />
      <slot name="underfilter" />
    </div>
    <div :class="{ resultsSection: true, showFilter: showFilter }">
      <div class="SEOText">
        <h1>{{ headingValue }}</h1>
      </div>
      <div v-if="options" class="filterTags">
        <span v-for="tag in tags.filter(t => t.label)" :key="tag.id + '_' + tag.value">
          {{ tag.label }}
          <i @click="removeFilter(tag)">&times;</i>
          <span class="closeBtn" @click="removeFilter(tag)" />
        </span>
        <button v-if="tags.length > 0" class="saveSearch" @click="onClickSaveSearch">
          <PlusCircledIcon />
          {{ t['save_this_search'] }}
        </button>
      </div>
      <div class="sorting">
        <button @click="showFilter = true">
          <svg width="16" height="16" viewBox="0 1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7.75H2.35C1.17.75.57 2.17 1.42 2.99L5.85 7.42V12.13C5.85 12.56 6.04 12.95 6.37 13.22L8.12 14.53C8.97 15.11 10.22 14.56 10.22 13.46V7.42L14.63 2.99C15.47 2.17 14.87.75 13.7.75ZM8.91 6.88V13.44L7.16 12.13V6.88L2.35 2.06H13.72L8.91 6.88Z" fill="#000000" />
          </svg>
          {{ `${t['filter']} ${selectedFilterCount}` }}
        </button>
        <multi-select v-model="currentFilters.sort" :multi="false" :options="sortingOrders" :required="true" :placeholder="t['sort_by']" />
      </div>
      <ResultsPanel :current-filters="currentFilters" @click-payment="e => $emit('click-payment', e)" @track="e => $emit('track', e)" v-model="currentFilters" :options="options" :lg="lg" :to="to" :target="target" :focus-id="focusId" @click="clicked" @results="updateTotal" :placeholder="placeholder" :page-link-url="pageLinkUrl" @compare="e => $emit('compare', e)" :compare-ids="compareIds" @on-toggle-favorite="e => $emit('on-toggle-favorite', e)">
        <template #empty>
          <slot name="empty" />
        </template>
        <template #financingTerms>
          <slot name="financingTerms" />
        </template>
        <template #listingPlaceholder>
          <slot name="listingPlaceholder" />
        </template>
      </ResultsPanel>
    </div>
  </div>
</template>

<script>
import { analyticsMixin } from '../../analytics.js'
import FilterPanel from './FilterPanel.ce.vue'
import ResultsPanel from './ResultsPanel.ce.vue'
import MultiSelect from '../MultiSelect.ce.vue'
import { parseUrlFilters, formatFilterForApi, slugify } from '../../logic.js'
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang.js'
import { prismicCms } from '../cms'
import PlusCircledIcon from '@/assets/plus-circled.svg'

const filtersMap = {
  '?body-types=1,5,9': 'family_life',
  '?price-max=250000&price-type=retail': 'budget',
  '?body-types=8,9': 'outdoor_adventure',
  '?body-types=7,11': 'single',
  '?body-types=1,8,9': 'family_suv',
  '?price-max=200000&price-type=retail&fuel-types=3': 'economical_cars',
  '?fuel-types=3': 'environmentally_cars'
}
const allColors = [
  { id: 'grey', color: '#757575', da: 'grå', it: 'Grigio' },
  { id: 'silver', color: '#E2E2E2', da: 'sølv', it: 'Argento' },
  { id: 'white', color: '#FFFFFF', da: 'hvid', it: 'Bianco' },
  { id: 'black', color: '#000000', da: 'sort', it: 'Nero' },
  { id: 'blue', color: '#0068FF', da: 'blå', it: 'Blu' },
  { id: 'red', color: '#FF0009', da: 'rød', it: 'Rosso' },
  { id: 'orange', color: '#FFA500', da: 'orange', it: 'Arancione' },
  { id: 'brown', color: '#664E00', da: 'brun', it: 'Marrone' },
  { id: 'green', color: '#009A33', da: 'grøn', it: 'Verde' },
  { id: 'yellow', color: '#FFD500', da: 'gul' },
  { id: 'bronze', color: '#CD7F32', it: 'Bronzo' }
]

export default {
  name: 'SeezSearch',
  components: { FilterPanel, ResultsPanel, MultiSelect, PlusCircledIcon },
  mixins: [langMixin('RESULT_PANEL_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, prismicCms, analyticsMixin],
  inheritAttrs: false,
  props: {
    filters: { type: String, default: null }, // URL querystring format
    to: { type: String, default: null },
    target: { type: String, default: null },
    focusId: { type: String, default: null },
    placeholder: { type: String, default: null },
    headingSEO: { type: String, default: '' },
    pageLinkUrl: { type: String, default: null },
    withSlider: { type: Boolean, default: true },
    compareIds: { type: String, default: '' }
  },
  emits: ['created', 'filtersUpdated', 'select', 'click-payment', 'total-results', 'body-style-change', 'track', 'compare', 'on-toggle-favorite'],
  data() {
    const parsedFilters = parseUrlFilters(this.filters)
    return {
      saveSearchClicked: false,
      currentFilters: parsedFilters,
      oldFilterString: JSON.stringify(parsedFilters),
      oldBodyOverflow: document.body.style.overflow,
      options: {
        brands: [],
        families: [],
        fuelTypes: [],
        bodyTypes: [],
        transmissions: [],
        years: [],
        doors: [],
        drives: [],
        engines: [],
        colors: [...allColors]
      },
      showFilter: false,
      title: '',
      totalResults: null,
      isWholesale: ['wholesale', 'wholesaleLease'].includes(parsedFilters.priceType),
      titleByCategory: true,
      currency: null
    }
  },
  computed: {
    headingValue() {
      if (this.headingSEO) return this.headingSEO
      if (this.brandData) return `${this.brandData.title} ${this.total}`
      const title = this.titleByCategory ? this.handleTitleByCategory() : this.handleTitleByFilters()
      return `${this.brandData === undefined ? 'Brugte ' : ''} ${title ?? this.t.search_results} ${this.total}`
    },
    brandData() {
      if (this.currentFilters.brands == null || this.languageResources?.SEO_DATA == null) return null
      const brandOption = this.options.brands.find(o => o.id === this.currentFilters.brands)
      if (brandOption == null) return null
      const brandSeo = this.languageResources.SEO_DATA[slugify(brandOption.name)] ?? { title: this.languageResources?.SEO_DATA.default.title.replace('{brand}', brandOption.name) }
      return brandSeo
    },
    sortingOrders() {
      if (this.t == null) return []
      return [
        { id: '-attractiveness', name: this.t.most_attractive },
        { id: '-created_date', name: this.t.recently_added },
        { id: 'price', name: this.t.price_ascending },
        { id: '-price', name: this.t.price_descending },
        { id: 'mileage', name: this.t.kilometrage_ascending },
        { id: '-mileage', name: this.t.kilometrage_descending },
        { id: '-year', name: this.t.age_descending },
        { id: 'year', name: this.t.age_ascending },
        { id: 'emi_downpayment', name: this.t.downpayment },
        { id: 'emi_price', name: this.t.monthy_payment },
      ]
    },
    tags() {
      const result = []

      if (this.currentFilters.brands) result.push({ id: 'brands', keys: ['brands'], label: this.options?.brands?.find(b => b.id === this.currentFilters.brands)?.name })

      if (this.currentFilters.families && this.currentFilters.families.length > 0) {
        if (Array.isArray(this.currentFilters.families)) result.push(...this.currentFilters.families.map(f => ({ id: `families${f}`, keys: ['families'], value: f, label: this.options?.families?.find(of => of.id === f)?.name })))
        else
          result.push({
            id: `families${this.currentFilters.families}`,
            keys: ['families'],
            value: this.currentFilters.families,
            label: this.options?.families?.find(of => of.id === this.currentFilters.families)?.name
          })
      }

      if (this.currentFilters.models && this.currentFilters.models.length > 0) {
        if (Array.isArray(this.currentFilters.models)) result.push(...this.currentFilters.models.map(m => ({ id: `models${m}`, keys: ['models'], value: m, label: this.options?.families?.flatMap(f => f.models)?.find(om => om.id === m)?.name })))
        else
          result.push({
            id: `models${this.currentFilters.models}`,
            keys: ['models'],
            value: this.currentFilters.models,
            label: this.options?.models?.find(b => b.id === this.currentFilters.models)?.name
          })
      }

      if (this.currentFilters.yearMin && this.currentFilters.yearMax) result.push({ id: 'year', keys: ['yearMin', 'yearMax'], label: this.t.betweenMinAndMax?.replace('{min}', this.currentFilters.yearMin).replace('{max}', this.currentFilters.yearMax) })
      else if (this.currentFilters.yearMin) result.push({ id: 'yearMin', keys: ['yearMin'], label: this.t.minOrNewer?.replace('{min}', this.currentFilters.yearMin) })
      else if (this.currentFilters.yearMax) result.push({ id: 'yearMax', keys: ['yearMax'], label: this.t.maxOrOlder?.replace('{max}', this.currentFilters.yearMax) })

      if (this.currentFilters.kilometrageMin && this.currentFilters.kilometrageMax)
        result.push({
          id: 'kilometrage',
          keys: ['kilometrageMin', 'kilometrageMax'],
          label: this.t.betweenMinAndMax?.replace('{min}', this.formatNumber(this.currentFilters.kilometrageMin) + ' km').replace('{max}', this.formatNumber(this.currentFilters.kilometrageMax) + ' km')
        })
      else if (this.currentFilters.kilometrageMin) result.push({ id: 'kilometrageMin', keys: ['kilometrageMin'], label: this.t.minOrMore?.replace('{min}', this.formatNumber(this.currentFilters.kilometrageMin) + ' km') })
      else if (this.currentFilters.kilometrageMax) result.push({ id: 'kilometrageMax', keys: ['kilometrageMax'], label: this.t.maxOrLess?.replace('{max}', this.formatNumber(this.currentFilters.kilometrageMax) + ' km') })

      if (this.currentFilters.priceMin && this.currentFilters.priceMax)
        result.push({
          id: 'price',
          keys: ['priceMin', 'priceMax'],
          label: this.t.betweenMinAndMax?.replace('{min}', this.formatPrice({ value: this.currentFilters.priceMin }, this.currency)).replace('{max}', this.formatPrice({ value: this.currentFilters.priceMax }, this.currency))
        })
      else if (this.currentFilters.priceMin) result.push({ id: 'priceMin', keys: ['priceMin'], label: this.t.minOrMore?.replace('{min}', this.formatPrice({ value: this.currentFilters.priceMin }, this.currency)) })
      else if (this.currentFilters.priceMax) result.push({ id: 'priceMax', keys: ['priceMax'], label: this.t.maxOrLess?.replace('{max}', this.formatPrice({ value: this.currentFilters.priceMax }, this.currency)) })

      // if (this.currentFilters.priceType) result.push({ id: 'priceType', keys: ['priceType', 'priceMin', 'priceMax'], label: this.currentFilters.priceType })

      if (this.currentFilters.bodyTypes && this.currentFilters.bodyTypes.length > 0) {
        result.push(
          ...this.currentFilters.bodyTypes.map(bt => {
            const bodyType = this.options?.bodyTypes?.find(b => b.id === bt)?.name
            const translatedBodyType = this.languageResources?.Common?.BodyTypes?.[bodyType] || bodyType
            return { id: `bodyType${bt}`, keys: ['bodyTypes'], value: bt, label: translatedBodyType }
          })
        )
      }

      if (this.currentFilters.transmissions) result.push({ id: 'transmissions', keys: ['transmissions'], label: this.options?.transmissions?.find(b => b.id === this.currentFilters.transmissions)?.name })
      if (this.currentFilters.fuelTypes && this.currentFilters.fuelTypes.length > 0) {
        result.push(...this.currentFilters.fuelTypes.map(f => ({ id: `fuelTypes${f}`, keys: ['fuelTypes'], value: f, label: this.options?.fuelTypes?.find(ft => ft.id === f)?.name })))
      }

      if (this.currentFilters.colors && this.currentFilters.colors.length > 0) {
        result.push(...this.currentFilters.colors.map(c => ({ id: `color${c}`, keys: ['colors'], value: c, label: this.languageResources?.FILTER_PANEL_COMPONENT_TRANSLATIONS?.[c] })))
      }

      if (this.currentFilters.engineSizeMax) result.push({ id: 'engineSizeMax', keys: ['engineSizeMax'], label: this.options?.engines?.find(b => b.id === this.currentFilters.engineSizeMax)?.name })

      if (this.currentFilters.driveTypes && this.currentFilters.driveTypes.length > 0) {
        result.push(...this.currentFilters.driveTypes.map(d => ({ id: `driveTypes${d}`, keys: ['driveTypes'], value: d, label: this.t[`drive_type_${d}`] })))
      }

      if (this.currentFilters.doors) result.push({ id: 'doors', keys: ['doors'], label: this.t.doors?.replace('{n}', this.currentFilters.doors) })

      if (Number(this.currentFilters.numberSeat)) result.push({ id: 'seats', keys: ['numberSeat'], label: this.t.seats?.replace('{n}', this.currentFilters.numberSeat) })

      if (this.currentFilters.hasPromotion) result.push({ id: 'hasPromotion', keys: ['hasPromotion'], label: this.t.promozione })

      if (this.currentFilters.isNoviceDrivable && this.currentFilters.isNoviceDrivable !== 'false') result.push({ id: 'isNoviceDrivable', keys: ['isNoviceDrivable'], label: this.t.neopatentati })

      if (this.currentFilters.freeText && this.currentFilters.freeText.length > 0) {
        result.push(...this.currentFilters.freeText.split(' ').map(w => ({ id: `freeText${w}`, keys: ['freeText'], value: w, label: w })))
      }

      return result
    },
    preload() {
      return this.currentFilters?.ss ? parseInt(this.currentFilters?.ss) : null
    },
    selectedFilterCount() {
      return this.tags.length > 0 ? `(${this.tags.length})` : ''
    },
    total() {
      return this.totalResults > 0 ? ` (${this.totalResults})` : ''
    }
  },
  watch: {
    filters(v) {
      const parsedFilters = parseUrlFilters(v)
      this.isWholesale = ['wholesale', 'wholesaleLease'].includes(parsedFilters.priceType)
      if (JSON.stringify(parsedFilters) !== this.oldFilterString) this.currentFilters = parseUrlFilters(v)
    },
    currentFilters: {
      deep: true,
      handler(v) {
        if (JSON.stringify(v) !== this.oldFilterString) {
          const cleanFilter = Object.fromEntries(Object.entries(v).filter(p => !p[0].startsWith('_')))
          this.$emit('filtersUpdated', cleanFilter)
          this.track('search_result', { category: 'user_engagement', action: 'search_result', label: 'Filters', iteraction: { cleanFilter } })
        }
        this.isWholesale = ['wholesale', 'wholesaleLease'].includes(this.currentFilters.priceType)
        this.oldFilterString = JSON.stringify(v)
        this.titleByCategory = false
        this.loadAvailableOptions()
        this.totalResults = null
      }
    },
    showFilter(value) {
      if (value) {
        document.body.style.overflow = 'hidden'
        this.$emit('body-style-change', value)
      } else {
        this.$emit('body-style-change', value)
        document.body.style.overflow = this.oldBodyOverflow
      }
    }
  },
  created() {
    this.$emit('created')
  },
  mounted() {
    // this.title = this.handleTitleByCategory()
    this.loadAvailableOptions()
  },
  methods: {
    onClickSaveSearch() {
      this.saveSearchClicked = true
      this.showFilter = true
      setTimeout(() => {
        this.saveSearchClicked = false
      }, 2000)
    },
    async loadAvailableOptions() {
      const query = `query filterOptionsWithCounters($filter: ListingFiltersInput) {
        filterOptionsWithCounters(filter: $filter) {
          brands { id name count}
          families {id name brandid count}
          models {id name familyid count}
          fuels { id name count }
          bodies { id name count }
          transmissions { id name count }
          years { year count }
          numbersOfDoors { number_of_doors count }
          driveTypes { drive_type count }
          engineSizes { engine_size count }
          colors { color count }
          currencies { name count }
        }
      }`
      const { filterOptionsWithCounters: filterOptions } = await this.queryApi(query, { filter: formatFilterForApi(this.currentFilters) })
      const newFilters = {
        brands: filterOptions.brands.map(b => ({ id: parseInt(b.id), name: b.name, count: b.count })),
        families: filterOptions.families.map(f => ({
          id: parseInt(f.id),
          name: f.name,
          models: filterOptions.models.filter(m => m.familyid === f.id).map(m => ({ id: parseInt(m.id), name: m.name, count: m.count })),
          brandid: parseInt(f.brandid),
          count: f.count
        })),
        bodyTypes: filterOptions.bodies.filter(b => b.name !== 'Unknown').map(b => ({ id: parseInt(b.id), name: b.name, count: b.count })),
        transmissions: filterOptions.transmissions.map(b => ({ id: parseInt(b.id), name: this.languageResources?.Common?.Transmissions?.[b.name] ?? b.name, count: b.count })),
        fuelTypes: filterOptions.fuels.map(b => ({ id: parseInt(b.id), name: this.languageResources?.Common?.FuelTypes?.[b.name] ?? b.name, count: b.count })),
        years: filterOptions.years.map(x => ({ id: x.year, name: x.year.toString(), count: x.count })),
        doors: filterOptions.numbersOfDoors.map(x => ({ id: x.number_of_doors, name: x.number_of_doors.toString(), count: x.count })),
        drives: filterOptions.driveTypes.map(d => ({ id: d.drive_type, count: d.count })),
        engines: filterOptions.engineSizes.map(x => ({
          id: parseFloat(x.engine_size),
          name: this.languageResources?.FILTER_PANEL_COMPONENT_TRANSLATIONS?.liters?.replace('{l}', x.engine_size.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })),
          count: x.count
        })),
        colors: allColors.filter(c => filterOptions.colors.some(fc => fc.color.toLowerCase() === c.id.toLowerCase() || fc.color.toLowerCase() === c.it?.toLowerCase()))
      }
      Object.assign(this.options, newFilters)
      this.removeUnavailableOptions()
      if (filterOptions.currencies == null || filterOptions.currencies.length === 0) console.warn('No currency available')
      else {
        this.currency = filterOptions.currencies[0].name
        if (filterOptions.currencies.length > 1) console.warn('Multiple currencies available. Using ' + filterOptions.currencies[0].name)
      }
    },
    removeUnavailableOptions() {
      if (this.currentFilters.brands && !this.options.brands.some(b => b.id === this.currentFilters.brands)) this.currentFilters.brands = null
      if (this.currentFilters.families) {
        const unavailableFamilies = this.currentFilters.families.filter(b => !this.options.families.map(x => x.id).includes(b))
        if (unavailableFamilies.length > 0) this.currentFilters.families = this.currentFilters.families.filter(b => !unavailableFamilies.includes(b))
      }
      if (this.currentFilters.models) {
        const unavailableModels = this.currentFilters.models.filter(
          b =>
            !this.options.families
              .flatMap(x => x.models)
              .map(x => x.id)
              .includes(b)
        )
        if (unavailableModels.length > 0) this.currentFilters.models = this.currentFilters.models.filter(b => !unavailableModels.includes(b))
      }
      if (this.currentFilters.bodyTypes) {
        const unavailableBodyTypes = this.currentFilters.bodyTypes.filter(b => !this.options.bodyTypes.map(x => x.id).includes(b))
        if (unavailableBodyTypes.length > 0) this.currentFilters.bodyTypes = this.currentFilters.bodyTypes.filter(b => !unavailableBodyTypes.includes(b))
      }
      if (this.currentFilters.transmissions && !this.options.transmissions.some(b => b.id === this.currentFilters.transmissions)) this.currentFilters.transmissions = null
      if (this.currentFilters.fuelTypes) {
        const unavailableFuelTypes = this.currentFilters.fuelTypes.filter(b => !this.options.fuelTypes.map(x => x.id).includes(b))
        if (unavailableFuelTypes.length > 0) this.currentFilters.fuelTypes = this.currentFilters.fuelTypes.filter(b => !unavailableFuelTypes.includes(b))
      }
      if (this.currentFilters.driveTypes) {
        const unavailableDriveTypes = this.currentFilters.driveTypes.filter(b => !this.options.drives.map(x => x.id).includes(b))
        if (unavailableDriveTypes.length > 0) this.currentFilters.driveTypes = this.currentFilters.driveTypes.filter(b => !unavailableDriveTypes.includes(b))
      }
      if (this.currentFilters.doors && !this.options.doors.some(b => b.id === this.currentFilters.doors)) this.currentFilters.doors = null
    },
    removeFilter(e) {
      const newFilter = JSON.parse(JSON.stringify(this.currentFilters))
      for (const k of e.keys) {
        if (k === 'freeText') {
          newFilter[k] = newFilter[k]
            .replace(e.value, '')
            .trim()
            .replace(/\s{2,}/, ' ')
        } else if (e.value) {
          newFilter[k] = newFilter[k].filter(v => v != e.value)
          if (newFilter[k].length === 0) delete newFilter[k]
        } else {
          delete newFilter[k]
        }
      }

      this.currentFilters = newFilter
    },
    updateCurrentFilters(data) {
      this.currentFilters = { ...this.currentFilters, ...data }
    },
    handleTitleByCategory() {
      return this.t[filtersMap[this.filters] || 'search_results']
    },
    handleTitleByFilters() {
      if (Object.keys(this.currentFilters).filter(f => f !== 'sort').length === 0) return this.t.all_cars
      if (this.currentFilters.brands && this.options?.brands) return this.options?.brands?.find(b => b.id === this.currentFilters.brands)?.name
      return this.t.search_results
    },
    clicked(e, details) {
      this.$emit('select', e, details)
    },
    updateTotal(r) {
      this.$emit('total-results', { totalResults: r.pageInfo.total })
      this.totalResults = r.pageInfo.total
    },
    genFilterData({ bodytype, brand, colors, fueltype, kilometrageMax, kilometrageMin, model, priceType, priceMin, priceMax, transmission, yearMax, yearMin, doors, seats }) {
      return {
        search: {
          filter: {
            make: brand,
            model,
            year: yearMin || (yearMax && { yearMin, yearMax }),
            mileage: kilometrageMin || (kilometrageMax && { kilometrageMin, kilometrageMax }),
            priceType,
            priceMin,
            priceMax,
            bodytype,
            transmission,
            fueltype,
            colors,
            doors,
            seats
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

#search {
  @include theme;

  background-color: var(--background);
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 28ch 5fr;

  .side {
    overflow: hidden auto;
    @include scrollBars;
  }

  .SEOText {
    grid-area: title;
    h1 {
      margin: 1rem 0 0 1rem;
      padding: 0;
      color: black;

      @media screen and (max-width: 47.938rem) {
        font-size: 1.3rem;
        margin: 0.4rem 0rem 0.4rem 1rem;
      }
    }
  }

  .resultsSection {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-areas: 'title sorting' 'tags tags' 'result result';
    overflow: hidden;
    padding: 0.5em;

    > *:not(:last-child) {
      margin-block-end: 0.5em;
    }

    > h1:not(:last-child) {
      grid-area: title;
      margin: 0 0 1rem 1rem;
      color: black;
      font-size: 1.5em;
    }

    > .filterTags {
      grid-area: tags;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      gap: 1em;
      position: relative;
      margin-inline-start: 1em;
      padding: 0.5rem 0 0.5rem 0;
      @media screen and (max-width: 47.938rem) {
        overflow-x: auto;
        flex-wrap: nowrap;
      }

      > .saveSearch {
        display: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background-color: var(--highlight);
        font-family: var(--base-font);
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
        border: none;
        border-radius: 1em;
        color: var(--background);
        transition:
          opacity 0.3s ease,
          transform 0.3s ease;

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 0.5;
        }
      }

      > span {
        border: 1px solid var(--highlight);
        border-radius: 1em;
        padding: 0.25em 0.75em;
        color: var(--highlight);
        height: fit-content;
        font-size: 0.9em;
        white-space: nowrap;
        position: relative;

        > i {
          display: inline-block;
          margin-inline-start: 0.5em;
          cursor: pointer;
          font-style: normal;
        }

        > .closeBtn {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          inset-inline-start: 0;
          border-radius: 1em;
          cursor: pointer;
        }
      }
    }

    > .sorting {
      grid-area: sorting;
      padding-block-start: 1rem;

      @media screen and (max-width) {
        padding-block-start: 0rem;
      }

      > button {
        border: none;
        outline: none;
        padding: 0.5em;
        background-color: transparent;
        display: none;
        white-space: nowrap;
        background-color: var(--background);

        > * {
          vertical-align: text-bottom;
        }
      }

      > .multiselect {
        margin-inline-end: 1.3rem;

        @media screen and (max-width: 47.938rem) {
          margin-inline-end: 0;
        }

        > button {
          border: none;
          outline: none;
          font-size: 0.9em;
        }

        > ul {
          inset-inline: auto 0;
        }
      }
      > .multiselect {
        border: 1px solid #cbcbcb;
        border-radius: 2em;
      }

      @media screen and (max-width: 47.938rem) {
        display: flex;
        margin: 0 1em 1em 1em;
        height: 3em;
        gap: 1em;
        padding-block-start: 0rem;

        > button,
        > .multiselect {
          flex-grow: 1;
          padding: 0.25em 0.75em;
          width: 100%;
          color: black;

          &:first-child {
            border-radius: 2em;
            border: 1px solid black;
            font-family: var(--base-font);
            font-size: 1em;
            color: black;
          }

          &:last-child {
            border-radius: 2em;
            font-family: var(--base-font);
            font-size: 1em;
            text-align: center;
            padding: 0.25em 0;
            margin-inline-start: -1px;
            color: black;
          }
        }
        > button {
          display: inline-block;

          > svg {
            display: inline-block;
            vertical-align: text-bottom;
          }
        }
      }
    }

    .resultsList {
      grid-area: result;
    }
  }

  @media screen and (max-width: 47.938rem) {
    .resultsSection {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: repeat(3, auto) 1fr;
      grid-template-areas: 'title sorting' 'tags tags' 'result result';
      overflow: hidden;
      padding: 0.3em 0;
    }
  }

  @media screen and (max-width: 47.938rem) {
    grid-template-columns: auto;

    .side > .filtersPanel:not(.showFilter) {
      display: none;
    }

    .resultsSection {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto) 1fr;
      grid-template-areas: 'title' 'sorting' 'tags' 'result';

      &.showFilter {
        display: none;
      }
    }
  }
}
</style>
