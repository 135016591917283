<template>
  <section :class="{ imagesCarousel: true, fullScreen: fullScreenMode }">
    <img @click="fullScreenMode = true" :class="[!fullScreenMode ? 'clickable' : '', 'mainImage']" key="main" ref="mainImage" :src="mainImageUrl" :alt="images?.[0]" @load="mainImageLoading = false" />
    <div class="thumbnails" ref="thumbs">
      <img v-for="i in images" :key="i" :src="thumbUrl(i)" :alt="i" :class="{ selected: i === mainImage }" @click="mainImage = i" loading="lazy" />
    </div>
    <button @click="movePrev" class="prev"><RightIcon /></button>
    <button @click="moveNext" class="next"><RightIcon /></button>
    <button @click="fullScreenMode = !fullScreenMode" class="toggle">
      <CrossIcon v-if="fullScreenMode" />
      <SearchIcon v-else />
    </button>
    <Loader v-if="mainImageLoading" />
  </section>
</template>
<script>
import RightIcon from '../../assets/right.svg'
import SearchIcon from '../../assets/search.svg'
import CrossIcon from '../../assets/cross.svg'
import Loader from '../Loader.ce.vue'

export default {
  name: 'ImagesCarousel',
  components: {
    RightIcon,
    SearchIcon,
    CrossIcon,
    Loader
  },
  props: {
    images: { type: Array, required: true }
  },
  data() {
    return {
      mainImage: this.images?.[0],
      mainImageLoading: false,
      fullScreenMode: false,
      mainImageWidth: 0,
      thumbnailWidth: 0
    }
  },
  computed: {
    mainImageUrl() {
      if (this.mainImageWidth === 0) return null
      const pixelRatio = window.devicePixelRatio ?? 1
      const fixedWidth = Math.min(Math.ceil((this.mainImageWidth * pixelRatio) / 100) * 100, 1600)
      return this.imageUrl(this.mainImage, fixedWidth)
    }
  },
  watch: {
    images(v) {
      this.mainImage = v?.[0]
    },
    mainImageUrl() {
      this.mainImageLoading = true
    },
    fullScreenMode() {
      if (this.fullScreenMode) {
        window.addEventListener('keyup', this.handleKeyPress)
      } else {
        window.removeEventListener('keyup', this.handleKeyPress)
      }
      this.$nextTick(() => {
        this.mainImageWidth = this.$refs.mainImage?.clientWidth ?? 0
        this.thumbnailWidth = this.fullScreenMode ? (this.$refs.thumbs.clientHeight * 16) / 10 : this.$refs.thumbs.clientWidth ?? 0
      })
    }
  },
  mounted() {
    this.mainImageWidth = this.$refs.mainImage?.clientWidth ?? 0
    this.thumbnailWidth = this.fullScreenMode ? (this.$refs.thumbs.clientHeight * 16) / 10 : this.$refs.thumbs.clientWidth ?? 0
  },
  methods: {
    handleKeyPress(e) {
      switch (e.key) {
        case 'ArrowLeft':
          return this.movePrev()
        case 'ArrowRight':
          return this.moveNext()
        case 'Escape':
          return (this.fullScreenMode = false)
        default:
          break
      }
    },
    imageUrl(path, size) {
      if(!path) return `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      return `${import.meta.env.VITE_IMAGES_URL}/image/${size}x0/${path}.webp`
    },
    thumbUrl(path) {
      const pixelRatio = window.devicePixelRatio ?? 1
      const fixedWidth = Math.min(Math.ceil((this.thumbnailWidth * pixelRatio) / 100) * 100, 1600)
      return this.imageUrl(path, fixedWidth)
    },
    movePrev() {
      if (this.images?.length > 1) {
        const i = this.images.indexOf(this.mainImage)
        this.mainImage = i <= 0 ? this.images[this.images.length - 1] : this.images[i - 1]
      }
    },
    moveNext() {
      if (this.images?.length > 1) {
        const i = this.images.indexOf(this.mainImage)
        this.mainImage = i >= this.images.length - 1 ? this.images[0] : this.images[i + 1]
      }
    }
  }
}
</script>

<style lang="scss">
.imagesCarousel {
  display: grid;
  place-items: stretch;

  > .mainImage {
    grid-area: 1 / 1;
    width: 100%;
    object-fit: cover;
  }

  > .clickable {
    cursor: pointer;
  }

  > .thumbnails {
    overflow: auto;
    display: none;
    align-items: stretch;

    > img {
      display: none;
      cursor: pointer;
      width: 100%;
      object-fit: cover;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: block;
      }
    }
  }

  > button {
    grid-area: 1 / 1;
    border: none;
    background: white;
    margin: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 2rem;
    cursor: pointer;
    display: grid;
    place-items: center;
    z-index: 2;
    transition: opacity 250ms;

    @media screen and (hover) {
      opacity: 0;
    }

    > svg {
      height: 1.25rem;
    }

    &.prev {
      place-self: center start;

      > svg {
        transform: rotate(180deg);
      }
    }

    &.next {
      place-self: center end;
    }

    &.toggle {
      place-self: start end;
    }
  }

  .mainImage:hover ~ button,
  > button:hover {
    opacity: 1;
  }

  .loader {
    grid-area: 1 / 1;
  }

  &.fullScreen {
    position: fixed;
    inset: 0;
    padding: 1rem;
    backdrop-filter: blur(3px) brightness(0.1);
    z-index: 2;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr auto;

    > .mainImage {
      grid-area: 1 / 1 / 1 / 4;
      place-self: stretch;
      height: auto;
      object-fit: contain;
      overflow: hidden;
    }

    .loader {
      grid-area: 1 / 1 / 1 / 4;
    }

    > .thumbnails {
      grid-area: 2 / 1 / 2 / 4;
      grid-auto-flow: column;

      > img {
        height: 5rem;
        width: auto;
        display: block;
      }
    }

    > .prev {
      grid-area: 1 / 1;
    }

    > .next {
      grid-area: 1 / 3;
    }

    > .toggle {
      grid-area: 1 / 3;
    }
  }

  @media screen and (min-width: 60rem) {
    grid-template-columns: 3fr 1fr;
    gap: 1rem;

    > .thumbnails {
      display: grid;
      gap: 1rem;
    }
  }
}
</style>
