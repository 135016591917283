<template>
  <div class="emptySearch">
    <div>
      <svg v-if="isButtonAction" width="48" height="42" viewBox="0 0 48 42" fill="var(--highlight)">
        <path d="M37.5 0H31.5C30.7 0 30 .7 30 1.5V6H39L39 1.5C39 .7 38.3 0 37.5 0ZM21 24H27V9H21V24ZM16.5 0H10.5C9.7 0 9 .7 9 1.5L9 6H18V1.5C18 .7 17.3 0 16.5 0Z" opacity="0.4" />
        <path d="M6 12C5.8 20.8.3 22.7 0 34.9V39C0 40.6 1.4 42 3 42H15C16.6 42 18 40.6 18 39V24H21V9H9C7.3 9 6 10.4 6 12ZM42 12C42 10.4 40.7 9 39 9H27V24H30V39C30 40.6 31.4 42 33 42H45C46.6 42 48 40.6 48 39V34.9C47.7 22.7 42.3 20.8 42 12Z" />
      </svg>
      <svg v-else width="42" height="43" viewBox="0 0 42 43" fill="var(--highlight)">
        <path d="M40.5 36.7 31 27.1C33.3 23.8 34.1 19.9 33.7 15.5 33.1 11.7 31.5 8.6 28.8 6 26.2 3.4 23.1 1.8 19.3 1.3 15.6.9 12.3 1.4 9.3 3 6.4 4.7 4.1 7 2.4 9.9.8 12.9.2 16.3.7 19.9 1.2 23.7 2.8 26.9 5.4 29.5 7.9 32.1 11.1 33.7 14.8 34.3 19.3 34.8 23.1 33.9 26.4 31.6L36.1 41.2C36.7 41.8 37.4 42.1 38.3 42.1 39.2 42.1 39.9 41.8 40.5 41.2 41.2 40.5 41.5 39.8 41.5 38.9 41.5 38.1 41.2 37.3 40.5 36.7ZM6.8 17.8C6.9 14.9 7.9 12.5 9.9 10.6 11.8 8.6 14.2 7.6 17.1 7.5 20 7.6 22.4 8.6 24.4 10.6 26.3 12.5 27.3 14.9 27.3 17.8 27.3 20.7 26.3 23.1 24.4 25 22.4 27 20 28 17.1 28 14.2 28 11.8 27 9.9 25 7.9 23.1 6.9 20.7 6.8 17.8Z" />
      </svg>
      <h1>{{ titleWithDefault }}</h1>
      <p v-if="description">{{ description }}</p>
      <a v-if="to" :class="isButtonAction && 'button'" :href="to">{{ actionLabelWithDefault }}</a>
    </div>
  </div>
</template>

<script>
import { langMixin } from './lang'

export default {
  name: 'SeezEmptySearch',
  mixins: [langMixin('EMPTY_SEARCH_COMPONENT_TRANSLATIONS')],
  props: {
    title: { type: String, default: null },
    actionLabel: { type: String, default: null },
    isButtonAction: { type: Boolean, default: false },
    description: { type: String, default: null },
    to: { type: String, default: null }
  },
  computed: {
    titleWithDefault() {
      return this.title || this.t.nothing_to_see
    },
    actionLabelWithDefault() {
      return this.actionLabel || this.t.search_now
    }
  }
}
</script>

<style lang="scss">
.emptySearch {
  margin: 3em auto;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: var(--background);
  text-align: center;

  > div {
    > h1 {
      font-size: 1rem;
      font-weight: 700;
      color: #333333;
      margin-block-end: 0;
    }

    > p {
      font-weight: 400;
      color: #afafaf;
      margin-block-start: 0.5rem;
    }

    > a {
      color: var(--highlight);
      font-weight: 700;
      cursor: pointer;
      text-decoration: none;
    }

    > .button {
      border-style: solid;
      border-color: var(--highlight);
      border-width: 0.1rem;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
    }
  }
}
</style>
