<template>
  <div class="reservationExtension" v-if="!showExtendModal">
    <h3>{{ alternateTitle ?? t.reservationExtensionTitle ?? 't.reservationExtensionTitle' }}</h3>
    <div class="timeLeft">
      <h3>{{ countdown }}</h3>
    </div>
    <div v-if="extendable && showExtendButton">
      <span>{{ t.needMoreTime ?? 't.needMoreTime' }}</span>
    </div>
    <span class="missingInfo" v-if="missingInfo">{{ t.fillOutPersonalInfo ?? 't.fillOutPersonalInfo' }}</span>
    <div class="extendHour" v-if="extendable && showExtendButton">
      <button :class="`extend ${!missingInfo ? '' : 'disabled'}`" @click="extendHourModal()" :disabled="loading || missingInfo">
        {{ t.extendHour ?? 't.extendHour' }}
      </button>
      <button :class="`continue ${!missingInfo ? '' : 'disabled'}`" @click="close()" :disabled="loading || missingInfo">
        {{ t.continue ?? 't.continue' }}
      </button>
    </div>
    <div v-else-if="!reservationPrice?.value">
      <button class="continue" @click="close()" :disabled="loading">
        {{ t.continue ?? 't.continue' }}
      </button>
    </div>
  </div>
  <div class="reservationExtension" v-if="showExtendModal">
    <h3>{{ t.extendReservation ?? 't.extendReservation' }}</h3>
    <span>{{ t.extendReservationSubTitle ?? 't.extendReservationSubTitle' }}</span>
    <div v-if="extendable">
      <span>{{ t.extendTo48 ?? 't.extendTo48' }}</span>
    </div>
    <span class="missingInfo" v-if="missingInfo">{{ t.fillOutPersonalInfo ?? 't.fillOutPersonalInfo' }}</span>
    <div class="payButtons" v-if="extendable">
      <button :class="`payPal ${!missingInfo ? '' : 'disabled'}`" @click="initiatePayment('paypal')" :disabled="loading || missingInfo">
        <div class="payPalIcon" />
        {{ t.paypal ?? 'PayPal' }}
      </button>
      <button :class="`card ${!missingInfo ? '' : 'disabled'}`" @click="initiatePayment('card')" :disabled="loading || missingInfo">
        <div class="cardIcon" />
        {{ t.card ?? 'card' }}
      </button>
    </div>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import Loader from '../Loader.ce.vue'
import { calculateCountdown } from '../../logic'

export default {
  name: 'ReservationExtension',
  components: { Loader },
  mixins: [langMixin('EXTERNAL_PAYMENTS_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    sourceId: { type: [String, Number], required: true },
    sourceType: { type: String, default: 'orderVehicleReservationExtension' },
    redirectUrl: { type: String, default: null },
    reservationPrice: { type: Object, default: null },
    endDate: { type: String, default: null },
    extendable: { type: Boolean, default: true },
    missingInfo: { type: Boolean, default: false }
  },
  emits: ['redirect', 'close'],
  data() {
    let timeDifference = null
    if (this.endDate) {
      timeDifference = new Date(this.endDate) - new Date()
    }

    return {
      loading: false,
      timeDifference: timeDifference,
      alternateTitle: null,
      alternateSubtitle: null,
      paymentTypes: {
        paypal: 'paypalAuthCaptureRefund',
        card: 'cardAuthCaptureRefund'
      },
      showExtendModal: false,
      showExtendButton: true
    }
  },
  computed: {
    countdown() {
      if (this.endDate) {
        if (this.timeDifference <= 0) {
          this.hiddenExtendButton()
          return this.t.timeIsOver
        }

        let { remainingDays, remainingHours, remainingMinutes } = calculateCountdown(this.timeDifference)
        const days = remainingDays > 1 ? this.t.days : this.t.day
        const hours = remainingHours > 1 ? this.t.hours : this.t.hour
        const minutes = remainingMinutes > 1 ? this.t.minutes : this.t.minute

        const remainingDaysText = remainingDays > 0 ? `${remainingDays} ${days} ` : ''
        const remainingHoursText = remainingHours > 0 ? `${remainingHours < 10 ? `0${remainingHours}` : remainingHours} ${hours} ` : ''
        const remainingMinutesText = remainingMinutes < 10 ? `0${remainingMinutes} ${minutes}` : `${remainingMinutes} ${minutes}`

        const remaining = `${remainingDaysText}${remainingHoursText}${remainingMinutesText}`

        return remaining
      }

      return null
    }
  },
  watch: {
    timeDifference: {
      immediate: true,
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timeDifference = new Date(this.endDate) - new Date()
            if (this.timeDifference / 1000 < 300 && !this.alternateTitle && !this.alternateSubtitle) {
              this.alternateTitle = this.t.aboutToExpireTitle ?? 'this.t.aboutToExpireTitle'
              this.alternateSubtitle = this.t.aboutToExpireSubtitle ?? 'this.t.aboutToExpireSubtitle'
            }
          }, 1000)
        }
      }
    }
  },
  methods: {
    extendHourModal() {
      this.showExtendModal = true
    },
    hiddenExtendButton() {
      this.alternateTitle = this.t.aboutToExpireTitle ?? 'this.t.aboutToExpireTitle'
      this.showExtendButton = false
    },
    close() {
      this.$emit('close')
    },
    async initiatePayment(paymentMethod) {
      const paymentProductType = this.paymentTypes[paymentMethod]

      this.loading = true

      try {
        const encodedUrlOk = encodeURIComponent(`${this.redirectUrl}`)
        const encodedUrlError = encodeURIComponent(`${this.redirectUrl}`)
        const encodedUrlPending = encodeURIComponent(`${this.redirectUrl}`)
        const encodedUrlRejected = encodeURIComponent(`${this.redirectUrl}`)

        const mutation = `
        mutation createOrderReservationExensionSCFPayment {
          createOrderReservationExtensionSCFPayment(
            orderId: ${this.sourceId}
            paymentProductType: ${paymentProductType}
            paymentMethod: ${paymentMethod}
            paymentType: reservation
            transactionType: authorization
            redirectionURL: {
              urlPaymentOk: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlOk}"
              urlPaymentError: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlError}"
              urlPaymentPending: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlPending}"
              urlPaymentRejected: "${import.meta.env.VITE_API_BASE_URL}/scf/redirect/?sourceId=${this.sourceId}&sourceType=${this.sourceType}&redirectTo=${encodedUrlRejected}"
            }
          ) {
            id
            paymentInfo {
              scf {
                providerUrl
              }
            }
          }
        }`

        const { createOrderReservationExtensionSCFPayment } = await this.queryApi(mutation)
        const providerUrl = createOrderReservationExtensionSCFPayment.paymentInfo.scf.providerUrl

        if (providerUrl) {
          this.$emit('redirect', providerUrl)
        }
      } catch (e) {
        console.error(e)
      } finally {
        // this.loading = false
      }
    }
  }
}
</script>

<style lang="scss"></style>
