<template>
  <div class="formContainer" :class="{ defaultStyles: loadDefaultStyles }">
    <SkeletonLoader v-if="loadingTemplate" />
    <form v-if="!onSuccess && !loadingTemplate" @submit.prevent="formSubmitted" class="dynamicInquiryForm fade-in" :class="{ defaultStyles: loadDefaultStyles }">
      <h2 v-if="t">{{ t[leadType]?.heading }}</h2>
      <ListingSelector v-if="selectedListingDetails" :listings-details="listingsDetails" :selected-listing-details="selectedListingDetails" @listing-selected="handleListingSelected" />
      <fieldset v-for="section in dynamicInquiryForm[0]?.fields" :key="section.name" :class="`${section.name}Form`">
        <legend>
          {{ t[leadType]?.[section.name]?.legend }}
        </legend>
        <p v-if="section.errorMessage" class="leadTypeError">
          {{ t[section.errorMessage] || t.errorCreatingInquiry }}
        </p>
        <FormTemplate :translation-node="t" :preloaded-data="inquiryData" :fields="section.fields" @updated="updateInquiry" />
      </fieldset>
      <p class="error" v-if="errorMessage">{{ t[errorMessage] }}</p>
      <input data-test-id="inputSubmit" v-if="!onSuccess" type="submit" :value="t[leadType]?.submit" />
      <slot v-if="selectedListingDetails" name="contactInfo" class="contactInfo">
        <p class="contactInfo" v-html="handleContactInfo(t)" />
      </slot>
      <div v-if="loading || submitting" class="loaderWrapper">
        <Loader />
      </div>
    </form>
    <Success v-if="onSuccess" :translation-node="t" />
  </div>
</template>

<script>
import SeezSdk from '../../sdk'
import SkeletonLoader from './SkeletonLoader.ce.vue'
import { langMixin } from '../lang'
import FormTemplate from '../FormTemplate/FormTemplate.ce.vue'
import ListingSelector from './ListingSelector.ce.vue'
import Loader from '../Loader.ce.vue'
import Success from './Success.ce.vue'

const errorTranslationKeys = {
  INQUIRY: 'errorCreatingInquiry',
  LISTING: 'errorListing',
  DEALERSHIP: 'errorDealershipLoad',
  HOLIDAYS: 'errorTestDriveHoliday',
  NO_LISTING: 'errorNoListing',
  SHOWROOM_CLOSED: 'errorShowroomClosed',
  SELECT_SHOWROOM: 'errorSelectLocation',
  NO_TIME_SLOTS: 'noTimeSlots'
}

export default {
  name: 'DynamicInquiryForm',
  components: { FormTemplate, ListingSelector, Loader, Success, SkeletonLoader },
  mixins: [SeezSdk.vueQueryMixin, langMixin('DYNAMIC_INQUIRY_FORM_TRANSLATIONS')],
  props: {
    id: { type: String, default: '' },
    dealershipId: { type: String, default: '' },
    listing: { type: String, default: '' },
    leadType: { type: String, default: '' }
  },
  emits: ['update'],
  data() {
    return {
      targetSite: null,
      loadDefaultStyles: false,
      submitting: false,
      errorMessage: '',
      onSuccess: false,
      listingsDetails: [],
      selectedListingDetails: null,
      inquiryData: {},
      formFields: null,
      legalTerms: {},
      payload: null,
      locationOptions: [],
      timeSlotsOptions: [],
      leadTypeErrorMessage: '',
      openingHours: {},
      dynamicInquiryForm: [],
      loadingTemplate: true, // Indicates initial data loading
      loading: false // Indicates form submission loading
    }
  },
  watch: {
    'inquiryData.date': 'generateTimeSlots',
    'inquiryData.location': 'handleSlotsByLocation',
    t() {}
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loadingTemplate = true
      let listingId = null

      // Handle external id case.
      if (this.id) {
        const listingIdByExternalIdResult = await this.queryListingByExternalId()
        listingId = listingIdByExternalIdResult
      }

      try {
        await Promise.all([this.loadListings(listingId), this.loadTargetSite()])
      } catch (e) {
        // Errors are handled within loadListings and loadTargetSite
        console.log(e)
      } finally {
        this.loadingTemplate = false
      }
    },

    handleContactInfo(t) {
      const phone = this.selectedListingDetails?.dealership?.phone || this.targetSite?.customerSupport?.phoneNumber || ''
      const referenceNumber = this.selectedListingDetails?.referenceNumber || ''
      return t.disclaimer.replace('{phone_number}', phone).replace('{phone_number_label}', phone).replace('{reference_number}', referenceNumber)
    },

    handleLegalText(section) {
      return section.name === 'legal' ? this.t.legalText?.replace(/{dealershipName}/g, this.targetSite?.name) : ''
    },
    async queryListingByExternalId() {
      if (!this.id) return
      try {
        const { listingsByExternalId } = await this.queryApi(`{listingsByExternalId(externalId: "${this.id}")}`)
        if (listingsByExternalId.length === 0) {
          this.errorMessage = errorTranslationKeys.NO_LISTING
          this.loadingTemplate = false
          return
        }

        return listingsByExternalId[0]
      } catch (e) {
        console.error('Error queryListingByExternalId', e)
        this.errorMessage = errorTranslationKeys.LISTING
      }
    },
    //Arg listingId is passed to loadListings when the externalId is passed to the component
    async loadListings(listingId) {
      let ids = []
      if (listingId) {
        ids.push(listingId)
      } else {
        ids = this.listing.split(',')
      }

      try {
        const { listingsByIds } = await this.queryApi(
          `query listings($ids: [ID!]!) {
            listingsByIds(ids: $ids) {
              id
              name
              variant
              year
              images
              kilometrage
              kmUnit
              externalId
              referenceNumber
              vehicle {
                locatedAt {
                  id
                  plainAddress
                  openingHours {
                    testDriveTimeSlotSize
                    holidays
                    week {
                      from
                      to
                    }
                  }
                }
              }
              dealership {
                id
                plainAddress
                phone
                workingHours {
                  dayOfWeek
                  startTime
                  endTime
                }
              }
              locatedAt {
                openingHours {
                  testDriveTimeSlotSize
                  holidays
                  week {
                    from
                    to
                  }
                }
              }
            }
          }`,
          { ids }
        )
        this.listingsDetails = listingsByIds
        this.selectedListingDetails = this.listingsDetails[0] ?? null
        this.inquiryData.location = this.listingsDetails[0]?.vehicle?.locatedAt?.id || ''

        this.openingHours = this.listingsDetails[0]?.vehicle?.locatedAt?.openingHours || {}

        if (ids.length) {
          this.handleListingLocationOptions()
        }
      } catch (e) {
        console.error('Error loadListing', e)
        this.errorMessage = errorTranslationKeys.LISTING
      }
    },
    async loadTargetSite() {
      const query = `{
        currentTargetSite  {
          id
          name
          dealerships {
            id
            name
            plainAddress
            openingHours {
              testDriveTimeSlotSize
              holidays
              week {
                from
                to
              }
            }
            children {
              id
              plainAddress
              openingHours {
                testDriveTimeSlotSize
                holidays
                week {
                  from
                  to
                }
              }
            }
          }
          customerSupport {
            email
            phoneNumber
          }
          urlConfig {
            privacyPolicyUrl
            termsAndConditionsUrl
          }
          layoutTemplates(name: "${this.leadType}") {
            name
            fields {
              name
              fields {
                name
                type
                values
                area
                regex
                question
                infoLabel
                placeholder
                query
                required
                visible
                enabled
                startDateDelay
                default
                translationNode
                acceptedFiles
                lookupGeneratorFunction
              }

            }
          }
        }
      }`

      try {
        const { currentTargetSite } = await this.queryApi(query)
        if (!currentTargetSite) {
          this.errorMessage = errorTranslationKeys.DEALERSHIP
          return
        }
        this.targetSite = currentTargetSite

        if (!this.selectedListingDetails) {
          this.locationOptions = this.handleLocation(currentTargetSite.dealerships)
        }
        if (currentTargetSite.layoutTemplates.length > 1) {
          this.dynamicInquiryForm = currentTargetSite.layoutTemplates.filter(template => template.name === 'generalInquiry')
          throw new Error('Type passed not valid')
        }

        this.dynamicInquiryForm = currentTargetSite.layoutTemplates
        this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
      } catch (e) {
        console.error('Error loadTargetSite', e)
        this.errorMessage = errorTranslationKeys.DEALERSHIP
      }
    },
    setDropdownOptions(fields) {
      fields.forEach(field => {
        if (field.name === 'location') {
          field.values = this.locationOptions.map(i => i.name)
        }

        if (field.name === 'time') {
          field.values = this.timeSlotsOptions
        }

        if (field.fields && Array.isArray(field.fields)) {
          this.setDropdownOptions(field.fields)
        }
      })
    },
    async formSubmitted(e) {
      this.loading = true
      this.errorMessage = ''
      this.onSuccess = false

      if (this.inquiryData.date) {
        this.inquiryData.date = this.inquiryData.date.split('T')[0]
      }

      const formattedFields = this.formatPayload(this.inquiryData)

      const payload = {
        inquiryType: this.leadType,
        dealershipId: +this.dealershipId || +this.targetSite?.dealerships[0]?.id,
        inquiryData: formattedFields
      }

      if (this.selectedListingDetails) {
        payload.listingId = this.selectedListingDetails.id
      }

      const query = `
        mutation createInquiry($inquiryType: String!, $dealershipId: ID, $listingId: ID, $inquiryData: [CustomAttributeInput!]!) {
          createInquiry(
            inquiryType: $inquiryType
            dealershipId: $dealershipId
            listingId: $listingId
            inquiryData: $inquiryData
          ) {
            id
            inquiryData {
              key
              value
            }
            inquiryType
          }
        }`

      try {
        const { createInquiry } = await this.queryApi(query, payload)

        if (!createInquiry) {
          this.errorMessage = errorTranslationKeys.INQUIRY
        } else {
          this.onSuccess = true
          this.inquiryData = {}
        }
      } catch (e) {
        console.log(e)
        this.errorMessage = errorTranslationKeys.INQUIRY
      } finally {
        this.loading = false
      }
    },
    updateInquiry(newData) {
      this.leadTypeErrorMessage = ''
      this.inquiryData = { ...this.inquiryData, ...newData }
    },
    handleListingSelected(listing) {
      this.selectedListingDetails = listing
      this.setBookingTimeToInitialValues()
      this.inquiryData.date = ''
      this.handleListingLocationOptions()
      this.inquiryData = { ...this.inquiryData, location: listing?.vehicle?.locatedAt?.id || '' }
      this.openingHours = listing?.vehicle?.locatedAt?.openingHours || {}
    },
    handleListingLocationOptions() {
      this.locationOptions = [
        {
          id: this.selectedListingDetails?.vehicle?.locatedAt?.id,
          name: this.selectedListingDetails?.vehicle?.locatedAt?.plainAddress
        }
      ]
    },
    handleLocation(dealershipArr = []) {
      let results = []

      dealershipArr.forEach(d => {
        if (d.plainAddress && d.plainAddress.trim() !== '') {
          results.push({
            id: d.id,
            name: d.plainAddress.trim()
          })
        }

        results = results.concat(
          d.children
            .filter(l => l.plainAddress && l.plainAddress.trim() !== '')
            .map(l => ({
              id: l.id,
              name: l.plainAddress.trim()
            }))
        )
      })

      const uniqueResults = new Map(results.map(result => [result.name, result]))

      return Array.from(uniqueResults.values()).sort((a, b) => a.name.localeCompare(b.name))
    },
    handleSlotsByLocation(locationId) {
      if (!this.targetSite) return

      this.setBookingTimeToInitialValues()
      this.inquiryData.date = ''

      const findLocationById = (data, id) => {
        if (data.id === id) {
          return data
        }
        if (data.children && data.children.length > 0) {
          for (const child of data.children) {
            const found = findLocationById(child, id)
            if (found) {
              return found
            }
          }
        }

        return null
      }

      const selectedLocation = findLocationById(this.targetSite.dealerships[0], locationId)
      if (!selectedLocation) return
      this.openingHours = selectedLocation.openingHours || {}
    },
    setBookingTimeToInitialValues() {
      this.timeSlotsOptions = []
      this.leadTypeErrorMessage = ''
    },
    getTimeSlots(from, to, duration) {
      const timeFrom = this.getMinutes(from)
      const timeTo = this.getMinutes(to)
      const result = []
      if (timeFrom != null && timeTo != null && duration > 0) {
        for (let current = timeFrom; current < timeTo; current += duration) {
          let h = Math.floor(current / 60).toString()
          if (h.length === 1) h = '0' + h
          let m = (current % 60).toString()
          if (m.length === 1) m = '0' + m
          result.push(`${h}:${m}`)
        }
      }
      return result
    },
    getMinutes(time) {
      const parts = /^(\d{1,2})(?::(\d{1,2}))?$/g.exec(time)
      if (parts == null) return null
      const hours = parseInt(parts?.[1])
      const minutes = parseInt(parts?.[2] ?? '0')
      return hours * 60 + (minutes ?? 0)
    },
    generateTimeSlots(newDateValue) {
      if (!newDateValue) {
        this.timeSlotsOptions = []
        return
      }

      this.setBookingTimeToInitialValues()
      const date = new Date(this.inquiryData.date)
      const dayIndex = date.getUTCDay()
      const currentDate = date.toISOString().split('T')[0]

      // Check if there are specific opening hours available
      if (!this.openingHours || !this.openingHours.week) {
        // If no opening hours are available, generate time slots from 9am to 5pm

        const normalizedFrom = '09:30' // Start from 9am
        const normalizedTo = '18:30' // End at 5pm

        const slots = this.getTimeSlots(normalizedFrom, normalizedTo, 60)

        this.timeSlotsOptions = slots
        this.setDropdownOptions(this.dynamicInquiryForm[0].fields)

        // Display a message indicating no specific opening hours
        this.leadTypeErrorMessage = errorTranslationKeys.NO_TIME_SLOTS
        return
      }

      // Check for holidays
      if (this.openingHours.holidays && this.openingHours.holidays.includes(`${currentDate}T00:00:00.000Z`)) {
        this.leadTypeErrorMessage = errorTranslationKeys.HOLIDAYS
        return
      }

      const daySchedule = this.openingHours.week[dayIndex]

      if (!daySchedule || !daySchedule.from || !daySchedule.to) {
        this.leadTypeErrorMessage = errorTranslationKeys.SHOWROOM_CLOSED
        return
      }

      const normalizedFrom = daySchedule.from.includes(':') ? daySchedule.from : `${daySchedule.from}:00`
      const normalizedTo = daySchedule.to.includes(':') ? daySchedule.to : `${daySchedule.to}:00`

      const slots = this.getTimeSlots(normalizedFrom, normalizedTo, this.openingHours.testDriveTimeSlotSize ?? 15)
      this.timeSlotsOptions = slots
      this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
    },
    formatPayload(obj) {
      const keyValueArray = []
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          const nestedArray = this.formatPayload(value)
          nestedArray.forEach(item => keyValueArray.push({ key: `${key}.${item.key}`, value: item.value }))
        } else {
          keyValueArray.push({ key, value })
        }
      }
      return keyValueArray
    }
  }
}
</script>

<style>
.formContainer {
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.dynamicInquiryForm {
  height: auto;
  background-color: var(--chat-bg-color, white);
  padding: 0.5rem;
  --highlight: #0068ff;
  --text-color: black;
  --background: white;
  --accented-background: color-mix(in oklab, var(--highlight) 5%, var(--background));
  --base-font: Biennale, Verdana, Geneva, Tahoma, sans-serif;
  --base-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
  --border-color: #ccc;
}

.leadTypeError {
  color: red;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 0.7rem;
}

.defaultPrivacy {
  margin-bottom: 1rem;
}

.defaultPrivacy p {
  font-size: 0.875rem;
}

.success {
  border-radius: 52px;
  color: black;
  padding: 12px 0;

  width: 100%;
  margin: auto;
  text-align: center;
  border: none;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.success p {
  margin: 0;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: start;

  h2 {
    margin: 0 !important;
  }
}

.loaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: red;
  font-size: 0.8rem;
  text-align: center;
}

.dynamicInquiryForm,
.SkeletonLoader {
  position: relative;
}

.SkeletonLoader {
  z-index: 5;
}

.loaderWrapper {
  z-index: 10;
}

.defaultStyles {
  .formContainer {
    gap: 0.75rem;
  }

  .dynamicInquiryForm {
    padding: 1.25rem;
    border-radius: 0.563rem;
    border: 1px solid #ccc;

    fieldset {
      border: none;
      margin: 1.125rem 0 0 0;
      padding: 0;

      legend {
        margin-bottom: 0.75rem;
      }
    }

    .dynamicInputField span {
      display: none;
    }

    .formTemplate {
      input,
      textarea {
        padding: 0.75rem 1rem;
        border: 1px solid #ccc;
        border-radius: 0.563rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        width: -webkit-fill-available;
      }

      textarea {
        width: -webkit-fill-available !important;
      }

      input[type='checkbox'] {
        width: auto;
        margin-inline-end: 0.5rem;
      }

      input[name='privacyTerms'] {
        width: -webkit-fill-available;
        display: none;
      }

      .checkboxWrapper input[name='privacyTerms'] + div {
        font-weight: 700;
      }

      button {
        padding: 0.743rem 1rem;
        border: 1px solid #ccc;
        border-radius: 0.563rem;
        display: flex;
        align-items: center;
        font-size: 1rem;

        span {
          display: contents;
        }
      }
    }

    input[type='submit'] {
      background: var(--button-bg-color, --highlight);
      border-radius: 0.563rem;
      width: -webkit-fill-available;
      margin-top: 0.563rem;
      padding: 0.75rem 1rem;
    }
  }
}
</style>
