<template>
  <div class="insuranceDetails">
    <h3>{{ t.insuranceBenefitsTitle }}</h3>
    <ul>
      <li>{{ t.insuranceBenefits1 }}</li>
      <li>{{ t.insuranceBenefits2 }}</li>
      <li>{{ t.insuranceBenefits3 }}</li>
    </ul>
    <p>
      <i class="small">{{ t.insuranceBenefitsDisclaimer }}</i>
    </p>
    <div class="apply">
      <button @click="requestInsurance">{{ t.insuranceApplyNow }}</button>
      <p>
        Insurance partner
        <TrygLogo />
      </p>
    </div>
  </div>
</template>
<script>
import { langMixin } from '../lang'
import TrygLogo from '../../assets/tryg.svg'

export default {
  name: 'InsuranceDetails',
  components: { TrygLogo },
  mixins: [langMixin('CHECKOUT_COMPONENT_TRANSLATIONS')],
  props: { order: { type: Object, required: true } }
}
</script>
<style lang="scss">
.insuranceDetails {
  > h3 {
    margin: 2rem 2rem 1rem;
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0 2rem 1rem;

    > li {
      margin-block-end: 0.5em;
    }
  }

  > p {
    margin: 0 2rem 2rem;
    font-size: small;
  }

  > .apply {
    background-color: var(--accented-background);
    padding: 2rem;
    text-align: center;

    > button {
      color: var(--background);
      background-color: var(--highlight);
      border: none;
      border-radius: 3em;
      font-size: 1em;
      padding: 0.5em 1em;
    }

    > p {
      width: max-content;
      margin: 1em auto 0;
      display: flex;
      align-items: center;
      gap: 1em;

      > svg {
        height: 1.5em;
      }
    }
  }
}
</style>
