<template>
  <div class="insuranceOptions" v-if="insuranceOptions?.length">
    <div class="insuranceGroups" v-if="Object.keys(insuranceGroups)?.length">
      <span>{{ t.availableInsuranceTypes ?? 't.availableInsuranceTypes' }}</span>
      <div class="selector">
        <div v-for="group in Object.keys(insuranceGroups)" :key="group">
          <input type="radio" :id="group" :value="group" v-model="selectedGroup" />
          <label :for="group"> {{ t[group] ?? group }} </label>
        </div>
      </div>
    </div>
    <span v-if="rootInsuranceOptions?.length">{{ t.insuranceOptions ?? 't.insuranceOptions' }}</span>
    <InsuranceOption
      :multiple="allowMultipleInsurances"
      v-for="rootOption in rootInsuranceOptions"
      :key="rootOption.id"
      :option="rootOption"
      :selected-option-ids="selectedOptions.map(o => o.id)"
      :options="insuranceOptions"
      :price="getOptionPrice(rootOption)"
      :selected="
        selectedOptions
          ? checkSelected(
              rootOption,
              selectedOptions.find(o => o.parentId === rootOption.id || o.id === rootOption.id),
              insuranceOptions
            )
          : !rootOption.id
      "
      :is-root="true"
      :loading="loading"
      @update-insurance="updateInsurance"
    />
  </div>
  <div v-else>
    <span>{{ t.noInsuranceOptionsAvailable ?? 't.noInsuranceOptionsAvailable' }}</span>
  </div>
</template>

<script>
import SeezSdk from '../../../sdk.js'
import InsuranceOption from './InsuranceOption.ce.vue'
import { langMixin } from '../../lang'

export default {
  name: 'InsuranceOptions',
  components: { InsuranceOption },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    orderId: { type: String, required: true },
    insuranceData: { type: Object, required: true }
  },
  emits: ['insuranceUpdated'],
  data() {
    let selectedOptions = this.insuranceData?.options?.filter(o => this.insuranceData?.selectedOptionIds.includes(o.id))
    const noneDuplicates = this.insuranceData?.options?.filter((value, index, self) => index === self.findIndex(t => t.id === value.id)) ?? []
    const rootPackages = noneDuplicates?.filter(ip => !ip.parentId) ?? []

    let groups = {}
    for (const root of rootPackages) {
      if (root.group) {
        if (groups[root.group]) {
          groups[root.group].push(root)
        } else {
          groups[root.group] = [root]
        }
      }
    }

    let selectedGroup = null
    for (const option of selectedOptions) {
      if (Object.keys(groups).includes(option?.group)) {
        selectedGroup = option.group
      } else {
        selectedGroup = rootPackages?.find(r => this.checkSelected(r, option, noneDuplicates))?.group
      }
    }

    if (!selectedGroup && Object.keys(groups).length) {
      selectedGroup = Object.keys(groups)?.[0]
    }

    return {
      loading: false,
      allowMultipleInsurances: this.insuranceData?.multiple ?? false,
      insuranceGroups: groups,
      insuranceOptions: noneDuplicates ?? [],
      rootInsuranceOptions: groups[selectedGroup] ?? rootPackages,
      selectedGroup: selectedGroup,
      selectedOptions: selectedOptions
    }
  },
  computed: {
    paymentMethodQuery() {
      return 'key name breakdowns {key name products {key name price description} total}'
    }
  },
  watch: {
    selectedGroup: {
      handler: function (v) {
        this.rootInsuranceOptions = this.insuranceGroups[v]
      }
    }
  },
  methods: {
    checkSelected(possibleParent, selectedNode, allOptions) {
      const checkParent = node => {
        if (node?.id === possibleParent?.id && node?.name === possibleParent?.name) {
          return true
        }

        const parent = allOptions.find(o => o.id && o?.id === node?.parentId)
        if (parent) {
          return checkParent(parent)
        } else {
          return false
        }
      }

      if (selectedNode) {
        return checkParent(selectedNode)
      }

      return false
    },
    async updateInsurance(option) {
      const optionHasChildren = this.insuranceOptions.find(o => o.parentId === option.id)
      let shouldUpdate = false

      const existingOption = this.selectedOptions.find(o => o.id === option.id)
      if (this.allowMultipleInsurances) {
        shouldUpdate = true
        if (existingOption) {
          if (existingOption.parentId) {
            this.selectedOptions = [...this.selectedOptions.filter(so => so.id !== existingOption.id), this.insuranceOptions.find(io => io.id === existingOption.parentId)]
          } else {
            this.selectedOptions = this.selectedOptions.filter(so => so.id !== existingOption.id)
          }
        } else {
          this.selectedOptions = [...this.selectedOptions.filter(so => this.insuranceData.selectedOptionIds.includes(so.id)), option]
          const siblingOption = this.selectedOptions.find(so => so.parentId !== null && so.parentId === option.parentId && so.id !== option.id)
          if (siblingOption) {
            if (this.selectedOptions.indexOf(siblingOption) > -1) this.selectedOptions.splice(this.selectedOptions.indexOf(siblingOption), 1)
          }
        }
      } else {
        this.selectedOptions = [option]
        shouldUpdate = !existingOption
      }

      if (!optionHasChildren && shouldUpdate) {
        this.loading = true
        try {
          const mutation = `
          mutation updateInsurance($insurance: InsuranceInput) {
            updateOrder(orderId: ${this.orderId} insurance: $insurance) {
              appState { current steps { name state errors }}
              insuranceData {
                multiple
                selectedProvider
                selectedOptionIds
                provider
                options { id parentId name description amount currency price { amount currency min max text } group link }
              }
              pricing { financing {${this.paymentMethodQuery}} cashPayment {${this.paymentMethodQuery}} externalPaymentsTotal adminFeesTotal adminFees { name amount currency requiredFor } currency discount vehiclePrice addonsPrice valueAddedServicesPrice deliveryPrice licensePlatePrice tradeInAmount tradeInOffer tradeInAdminFee tradeInReserveAmount tradeInBalance tradeInOfferGross tradeInFinancing tradeInReturnToCustomer subtotal total final }
            }
          }
          `

          const optionIds = this.selectedOptions.filter(so => !this.insuranceOptions.filter(io => io.parentId === so.id)?.length).map(so => so.id)
          const { updateOrder } = await this.queryApi(mutation, { insurance: { optionIds: optionIds } })
          this.selectedOptions = updateOrder?.insuranceData?.options.filter(o => updateOrder.insuranceData?.selectedOptionIds?.includes(o?.id))
          this.$emit('insuranceUpdated', updateOrder)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    },
    getOptionPrice(option) {
      if (option?.amount && option?.currency) {
        this.formatNumber(option.amount, 0, option.currency)
      }
      return null
    }
  }
}
</script>
