class ToastInstance {
  instance
  set(instance) {
    this.instance = instance
  }

  get() {
    return this.instance
  }
}
const toastInstance = new ToastInstance()
export default toastInstance
