<template>
  <div class="financingDetails" v-if="order">
    <h4>{{ t.insuranceDetail ?? 't.insuranceDetail' }}</h4>
    <dl>
      <dt>{{ t.insuranceType ?? 't.insuranceType' }}</dt>
      <dd>{{ insuranceType }}</dd>
      <dt v-if="groupInsurance">{{ t.groupInsurance ?? 't.groupInsurance' }}</dt>
      <dd v-if="groupInsurance">{{ groupInsurance }}</dd>
      <dt>{{ t.insurancePrice ?? 't.insurancePrice' }}</dt>
      <dd>{{ monthlyPaymentInsurance }}</dd>
      <dt v-if="duration">{{ t.duration ?? 't.duration' }}</dt>
      <dd v-if="duration">{{ duration }}</dd>
    </dl>
    <hr class="line" />
    <p class="des" v-html="description" />
  </div>
</template>

<script>
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'
import { analyticsMixin } from '../../analytics.js'

export default {
  name: 'InsuranceInfo',
  mixins: [langMixin('FINANCING_PROVIDER_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    order: { type: Object, required: true }
  },
  data() {
    return {
      loading: false,
      financing: this.order?.payment?.financing,
      selectedOptionId: this.order?.insuranceData?.selectedOptionIds ? this.order?.insuranceData?.selectedOptionIds?.[0] : null,
      owner: this.order?.listing?.owner
    }
  },
  computed: {
    groupInsurance() {
      const hasGroup = this.order?.insuranceData?.options?.[0]?.group
      if (!hasGroup) return null
      if (this.selectedOptionId) {
        const insuranceSelected = this.order?.insuranceData?.options?.find(e => e.id === this.selectedOptionId)
        if (insuranceSelected) {
          if (!insuranceSelected.parentId) {
            const key = insuranceSelected.group?.split(' ').join('')
            return this.t[key] ?? `${key}`
          } else {
            const parent = this.order?.insuranceData?.options?.find(e => e.id === insuranceSelected.parentId)
            if (parent) {
              const key = parent.group?.split(' ').join('')
              return this.t[key] ?? `${key}`
            }
          }
        }
      }
      return null
    },
    monthlyPaymentInsurance() {
      if (this.selectedOptionId) {
        const insuranceSelected = this.order?.insuranceData?.options?.find(e => e.id === this.selectedOptionId)
        if (insuranceSelected) {
          return this.formatNumber(insuranceSelected.amount, 0, insuranceSelected.currency)
        }
      }
      return null
    },
    insuranceType() {
      if (this.selectedOptionId) {
        const insuranceSelected = this.order?.insuranceData?.options?.find(e => e.id === this.selectedOptionId)
        if (insuranceSelected) {
          if (!insuranceSelected.parentId) {
            const key = insuranceSelected.name?.split(' ').join('')
            return this.t[key] ?? `${key}`
          } else {
            const parent = this.order?.insuranceData?.options?.find(e => e.id === insuranceSelected.parentId)
            if (parent) {
              const key = parent.name?.split(' ').join('')
              return this.t[key] ?? `${key}`
            }
          }
        }
      }
      return null
    },
    duration() {
      if (this.selectedOptionId) {
        const insuranceSelected = this.order?.insuranceData?.options?.find(e => e.id === this.selectedOptionId)
        if (insuranceSelected && insuranceSelected?.parentId) return insuranceSelected.name
      }
      return null
    },
    description() {
      if (this.selectedOptionId) {
        const insuranceSelected = this.order?.insuranceData?.options?.find(e => e.id === this.selectedOptionId)
        if (insuranceSelected) {
          const key = insuranceSelected.description
          return insuranceSelected.description ?? this.t[key] ?? `t.${key}`
        }
      }
      return 't.insuranceSelectedNotFound'
    }
  }
}
</script>
<style lang="scss">
.financingDetails {
  > h4 {
    margin: 0rem 0rem 1rem 0rem;
  }
  > dl {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;
    > dd {
      text-align: right;
      font-weight: 700;
    }

    @media screen and (min-width: 46rem) {
      grid-template-columns: 1fr auto 1fr auto;
    }
  }
}
</style>
