<template>
  <div class="orderReview">
    <h2>{{ t.reviewYourOrder ?? 't.reviewYourOrder' }}</h2>
    <span>{{ dateFromValue(new Date()) }} • #{{ order.id }}</span>
    <div class="reviewComponents">
      <!-- CUSTOMER INFO -->
      <div class="reviewSection">
        <div class="titleCard">
          {{ t.customerInfoTitle ?? 't.customerInfoTitle' }}
          <button @click="edit('customerInfo')">
            {{ t.edit ?? 't.edit' }}
            <div />
          </button>
        </div>
        <div>
          <div v-if="order.coOwnerInfo" class="subtitleCard">{{ t.primaryOwner ?? 't.primaryOwner' }}</div>
          <!-- customer info -->
          <div class="details">
            <div v-for="(field, i) in customerInfoFields.filter(f => f.visible !== false)" :key="field.name">
              <div v-if="i % 2 === 0" class="column">
                <span class="fieldName">{{ t[replaceLocationAddress(field.name)] ?? replaceLocationAddress(field.name) }}:</span>
                <span>{{ getField(order.customerInfo, field) }}</span>
              </div>
              <div v-else-if="i % 2 === 1" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.customerInfo, field) }}</span>
              </div>
            </div>
          </div>
          <!-- customer address-->
          <div class="details">
            <div v-for="(field, i) in customerAddressFields.filter(f => f.visible !== false)" :key="field.name">
              <div v-if="i % 2 === 0" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.customerInfo.address, field) }}</span>
              </div>
              <div v-else-if="i % 2 === 1" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.customerInfo.address, field) }}</span>
              </div>
            </div>
          </div>
          <!-- co-owner info -->
          <div v-if="order.coOwnerInfo" class="horizontalLine">
            <hr />
          </div>
          <div v-if="order.coOwnerInfo" class="subtitleCard">{{ t.secondaryOwner ?? 't.secondaryOwner' }}</div>
          <div v-if="order.coOwnerInfo" class="details">
            <div v-for="(field, i) in coOwnerInfoFields.filter(f => f.visible !== false)" :key="field.name">
              <div v-if="i % 2 === 0" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.coOwnerInfo, field) }}</span>
              </div>
              <div v-else-if="i % 2 === 1" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.coOwnerInfo, field) }}</span>
              </div>
            </div>
          </div>
          <!-- co-owner address-->
          <div v-if="order.coOwnerInfo" class="details">
            <div v-for="(field, i) in coOwnerAddressFields.filter(f => f.visible !== false)" :key="field.name">
              <div v-if="i % 2 === 0" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.coOwnerInfo.address, field) }}</span>
              </div>
              <div v-else-if="i % 2 === 1" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.coOwnerInfo.address, field) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DELIVERY INFO -->
      <div class="reviewSection">
        <div class="titleCard">
          {{ t.deliveryInfoTitle ?? 't.deliveryInfoTitle' }}
          <button @click="edit('delivery')">
            {{ t.edit ?? 't.edit' }}
            <div />
          </button>
        </div>
        <!-- delivery info -->
        <div>
          <div class="details">
            <div v-for="(field, i) in deliveryInfoFields" :key="field.name">
              <div v-if="i % 2 === 0" class="column">
                <span class="fieldName">{{ t[replaceLocationAddress(field.name)] ?? replaceLocationAddress(field.name) }}:</span>
                <span>{{ getField(order.delivery, field) }}</span>
              </div>
              <div v-else-if="i % 2 === 1" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.delivery, field) }}</span>
              </div>
            </div>
          </div>
          <!-- delivery address-->
          <div v-if="order.delivery.address" class="details">
            <div v-for="(field, i) in deliveryAddressFields.filter(f => f.visible !== false)" :key="field.name">
              <div v-if="i % 2 === 0" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.delivery.address, field) }}</span>
              </div>
              <div v-else-if="i % 2 === 1" class="column">
                <span class="fieldName">{{ t[field.name] ?? field.name }}:</span>
                <span>{{ getField(order.delivery.address, field) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TRADE IN INFO -->
      <div class="reviewSection" v-if="order.tradeIn">
        <div class="titleCard">
          {{ t.tradeInInfoTitle ?? 't.tradeInInfoTitle' }}
          <button @click="edit('tradeIn')">
            {{ t.edit ?? 't.edit' }}
            <div />
          </button>
        </div>
        <div>
          <TradeInDetailCard v-if="order.tradeIn" :trade-in="order.tradeIn" :selected="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import TradeInDetailCard from './Forms/TradeIn/TradeInDetailCard.ce.vue'

export default {
  name: 'OrderReview',
  components: { TradeInDetailCard },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true }
  },
  emits: ['onEditComponent'],
  data() {
    const customerInfoFields = this.order?.listing?.owner?.layoutTemplates?.find(sc => sc.name === 'customerInfo')?.fields
    const customerAddressFields = customerInfoFields?.find(f => f.name === 'address')?.fields
    const coOwnerInfoFields = customerInfoFields?.find(f => f.name === 'coOwnerInfo')?.fields
    const coOwnerAddressFields = coOwnerInfoFields?.find(f => f.name === 'address')?.fields
    const deliveryInfoFields = this.order.listing?.owner?.layoutTemplates?.find(sc => sc.name === 'delivery')?.fields?.find(f => f.name === this.order?.delivery?.type)?.fields
    const deliveryAddressFields = deliveryInfoFields.find(f => f.name === 'address')?.fields

    return {
      customerInfoFields: customerInfoFields,
      customerAddressFields: customerAddressFields,
      coOwnerInfoFields: this.order.coOwnerInfo ? coOwnerInfoFields : [],
      coOwnerAddressFields: this.order.coOwnerInfo ? coOwnerAddressFields : [],
      deliveryInfoFields: deliveryInfoFields?.filter(item => !(item.visible === false)),
      deliveryAddressFields: deliveryAddressFields ?? [],
      showroomAddress: ''
    }
  },
  mounted() {
    this.getLocationAddress()
  },
  methods: {
    replaceLocationAddress(fieldName) {
      return fieldName === 'showroomId' ? 'showroomAddress' : fieldName
    },
    async getLocationAddress() {
      if (this.order.delivery.location?.id) {
        const { dealerships } = await this.queryApi('query location($ids: [ID!]) { dealerships(ids: $ids) { id plainAddress }}', { ids: [this.order.delivery.location?.id] })

        if (dealerships?.[0]) this.showroomAddress = dealerships?.[0]?.plainAddress
      }
    },
    getField(model, field) {
      const customAttribute = model.customAttributes?.find(ca => ca.key === field.name)

      if (field.type?.toLowerCase().includes('date')) {
        const date = new Date(customAttribute?.value ?? model[field.name])
        return this.dateFromValue(date)
      }
      if (field.name === 'showroomId') {
        return this.showroomAddress
      }
      return customAttribute?.value ?? model[field.name]
    },
    addZero(i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    dateFromValue(date) {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} | ${this.addZero(date.getHours())}:${this.addZero(date.getMinutes())}`
    },
    edit(salesComponent) {
      this.$emit('onEditComponent', salesComponent)
    }
  }
}
</script>
