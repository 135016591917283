<template>
  <div ref="chatMessageList" class="chatMessageList">
    <div class="system">
      <div class="messageWrapper">
        <slot name="automatedMessage" />
      </div>
    </div>
    <div v-for="message in messages" :key="message.id" :id="message.id" :class="[getChatMessageListClass(message.author.role)]">
      <div class="messageWrapper">
        <ChatMessage :ref="message.id" :message="message.message" :words="message.words" :role="message.author.role" :id="message.id" :date="message.date" :chat-avatar-styles="theme.chatAvatarStyles" :chat-message-styes="theme.chatMessageStyles" :bot-config="botConfig" />
      </div>
    </div>
    <slot name="autoScrollObserver" />
    <div class="messageFadeEffect" />
  </div>
</template>

<script>
import ChatMessage from './ChatMessage.ce.vue'

export default {
  name: 'ChatMessageList',
  components: { ChatMessage },
  props: { messages: { type: Array, default: () => [] }, theme: { type: Object, required: true }, botConfig: { type: Object, default: () => {} } },
  emits: ['scroll', 'scrollToBottom'],
  methods: {
    getChatMessageListClass(role) {
      return role === 'user' ? ' user' : 'system'
    }
  }
}
</script>

<style>
.chatMessageList {
  width: 100%;
  height: 100%;
  position: relative;
  direction: var(--text-direction);
}

.messageWrapper {
  max-width: 60.625em;
  margin: 0 auto;
  padding: 18px;
}

.messageFadeEffect {
  position: sticky;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), white);
  width: 100%;
  height: 2em;
}
</style>
