<template>
  <div class="miniListing">MINI LISTING externalId: {{ id }} - internalId: {{ listingId }}</div>
  <LoanCalculator :listing-id="listingId" />
  <button @click="$emit('buy', { listingId: listingId })">{{ t.startOrder ?? 't.startOrder' }}</button>
  <button @click="showTestDriveModal = true">{{ t.startTestDrive ?? 't.startTestDrive' }}</button>
  <Modal v-if="showTestDriveModal" @close="showTestDriveModal = false"> DYNAMIC INQUIRY FORM HERE </Modal>
</template>

<script>
import SeezSdk from '@/sdk.js'
import LoanCalculator from '../FinancingProvider/LoanCalculator.ce.vue'
import Modal from '../Modal.ce.vue'
import { langMixin } from '../lang'

export default {
  name: 'MiniListing',
  components: { LoanCalculator, Modal },
  mixins: [langMixin('MINI_LISTING_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    id: { type: String, required: true }
  },
  emits: ['buy'],
  data() {
    return {
      listingId: null,
      listingDetails: null,
      showTestDriveModal: false,
      loading: false
    }
  },
  mounted() {
    this.loadExternalListing()
  },
  methods: {
    async loadExternalListing() {
      const query = `
        { listingsByExternalId(externalId: ["${this.id}"]) }
      `

      this.loading = true

      try {
        const { listingsByExternalId } = await window.seezSdk.queryApi(query)

        if (listingsByExternalId?.length) {
          this.listingId = listingsByExternalId[0]

          const listingQuery = `
            {
              listing(id: ${this.listingId}) {
                inspectionReport { url label }
                id name model { id name family { id name brand { id name }}} bodyType { name } targetSite { name logo brandingHighlightColor }
                variant year currency kilometrage owner { id name description logoUrl financing { logo }}
                images color hp transmission {name} fuelType { id name } registrationDate state reservedBy
                equipment { comfort design optional safety standard uncategorized } referenceNumber acceleration numAirbags numGears
                numCylinders weight loadCapacity maxAttachment numDoors fuelConsumption range description locatedAt { plainAddress }
                customBadge isNoviceDrivable
                fullColor image360 { exteriorClosedDoors interiorPanorama }
                listPrice { value currency }
                usage
                typeName leasePrice { value currency type lease { duration downPayment residualValue type audience }}
                wholesaleLeasePrice { value currency type lease { duration downPayment residualValue type audience }}
                retailPrice { value currency type disclaimer } wholesalePrice { value currency type }
                emiPrice { value currency type emi { loanAmount apr disclaimer paymentTerms }}
                videos greenTax batteryConsumption certified interiorMaterial interiorColor rimSize warranty engineSize numSeats
                promotions { title description priceB2c disclaimer discount discounted_price start_date end_date}
                reservation {id isActive reservedFor { id }} reservationConfig { requiresPaymentIdToReserve enabledTestDriveReservations }
                externalId
                referenceNumber
                driveType
                bodyType { id name }
                nextInspectionDate
                registrationDate
                registrationType
              }
            }
          `

          const { listing } = await window.seezSdk.queryApi(listingQuery)
          if (listing) this.listingDetails = listing
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
