<template>
  <Modal v-if="isOpen" @close="$emit('close')">
    <div class="confirm-delete">
      <h4 class="title">{{ t.confirmTitle }}</h4>
      <p class="subtitle">{{ t.confirmSubtitle }}</p>
      <div class="buttons">
        <button class="cancel-btn" @click="$emit('close')">{{ t.confirmCancelText }}</button>
        <button class="submit-btn" @click="handleDelete">{{ t.confirmOkText }}</button>
      </div>
    </div>
    <Loader v-if="loading" />
  </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import Loader from '../Loader.ce.vue'
import { langMixin } from '../lang'
import SeezSdk from '../../sdk'

export default {
  name: 'DeleteUserModal',
  components: { Modal, Loader },
  mixins: [langMixin('DELETE_ACCOUNT_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {
      userId: '',
      loading: false
    }
  },
  async mounted() {
    this.getUserId()
  },
  methods: {
    async getUserId() {
      this.loading = true
      try {
        const user = await window.seezSdk.getCurrentUser()
        this.userId = user.id
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async handleDelete() {
      this.loading = true

      try {
        await this.queryApi(
          `
          mutation deleteUser($id: ID!) {
            deleteUser(id: $id)
          }`,
          {
            id: this.userId
          }
        )

        await window.seezSdk.logout()
        this.$emit('close')
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../base.scss';

.seezModal {
  @include theme;
  position: relative;

  > .loader {
    position: absolute;
    inset: 0;
  }
}

.title {
  margin: 0;
  text-align: start;
}

.subtitle {
  margin: 60px 0;
}

.buttons {
  display: flex;
  gap: 1rem;

  > button {
    flex: 1;
  }
}
</style>
