<template>
  <ul class=faqList>
    <li v-for="(faq, index) in faqs" :key="'faq' + (index + 1)" class="faqListItem" @click="handleFaqClicked(faq)" :style="faqStyles">
      <div>{{ faq }}</div>
      <svg class="faq-icon" width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.60156 6.14844C7.95703 6.47656 7.95703 7.05078 7.60156 7.37891L2.35156 12.6289C2.02344 12.9844 1.44922 12.9844 1.12109 12.6289C0.765625 12.3008 0.765625 11.7266 1.12109 11.3984L5.74219 6.75L1.12109 2.12891C0.765625 1.80078 0.765625 1.22656 1.12109 0.898438C1.44922 0.542969 2.02344 0.542969 2.35156 0.898438L7.60156 6.14844Z" fill="black"/>
      </svg>

    </li>
  </ul>
</template>

<script>
import { hexToRgba } from './helpers'
import light from './themes/light.json'

export default {
  name: 'FAQs',
  props: {
    faqs: { type: Array, default: () => [] },
    botConfig: { type: Object, default: () => ({}) }
  },
  emits: ['question-selected'],
  data() {
    return {
      theme: light.chatConfig.theme.chatFAQStyles
    }
  },
  computed: {
    faqStyles() {
      return {
        '--faq-bg': this.checkColor(this.botConfig?.primaryColor) ? hexToRgba(this.botConfig?.primaryColor, 0.1) :  hexToRgba(this.theme.itemBgColor, 0.1),
        '--faq-border-color': this.checkColor(this.botConfig?.primaryColor) ? hexToRgba(this.botConfig?.primaryColor, 0.2) : hexToRgba(this.theme.itemBgColor, 0.2),
        '--faq-border-radius': this.botConfig?.borderRadius && this.botConfig.borderRadius === 'square' ? '6px' : '0.5em'
      }
    }  
  },
  methods: {
    handleFaqClicked(faq) {
      this.$emit('question-selected', {text: faq, sender: 'user'})
    },
    checkColor(color) {
      if (!color || color == 'Transparent') return false
      return true
    }
  }
}
</script>

<style>
.faqList {
  padding: 0;
  margin: 0;
}

.faqListItem {
  margin-top: 0.5em;
  border-radius: var(--faq-border-radius);
  padding: 0.75em;
  background: var(--faq-bg);
  border: 1px solid var(--faq-border-color);
  
  display: flex;
  gap: 1.5em;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14);
  }
}

.faq-icon {
  min-width: 17px;
  fill: var(--faq-arrow-fill);
  transform: var(--icon-rotate);
}

</style>
