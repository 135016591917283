<template>
  <div :class="{ toggle: true, on: internalValue }" tabindex="0" @click="toggle" />
</template>

<script>
export default {
  name: 'ToggleInput',
  props: {
    on: { type: Boolean, default: false }
  },
  emits: ['update:on', 'change'],
  data() {
    return {
      internalValue: this.on
    }
  },
  watch: {
    on(v) {
      if (v != this.internalValue) this.internalValue = v
    }
  },
  methods: {
    toggle() {
      this.internalValue = !this.internalValue
      this.$emit('update:on', this.internalValue)
      this.$emit('change', this.internalValue)
    }
  }
}
</script>

<style lang="scss">
@import '../base';

.toggle {
  @include theme;

  width: 3em;
  height: 1.6em;
  border-radius: 2em;
  background-color: var(--highlight);
  cursor: pointer;
  position: relative;
  filter: saturate(0);

  &::after {
    height: 1.2em;
    width: 1.2em;
    border-radius: 1em;
    background-color: var(--background);
    content: '';
    position: absolute;
    inset: 0.2em auto 0.2em 0.2em;
    transform: translateX(0);
    transition: transform 250ms, filter 250ms;
    transition-timing-function: ease-in-out;
  }

  &.on {
    filter: saturate(1);

    &::after {
      transform: translateX(1.4em);
    }
  }
}
</style>
