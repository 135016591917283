<template>
  <div data-test-id="dataTestId" class="lookupField" :class="{ open: open }">
    <input
      :name="name"
      :data-test-id="dataTestId"
      @input="getSearchResults"
      @click="open = !open"
      @focus="searchResults = searchOptions"
      :class="{ 'has-value': selectedOption }"
      v-model="selectedOption"
      :required="required"
      type="text"
      :placeholder="placeholder"
      @invalid="
        e => {
          myError(e, field)
        }
      "
    />
    <ul v-if="open && searchResults?.length">
      <li v-for="result in searchResults" :key="result" :class="{ selected: result === selectedOption && searchResults?.includes(result) }">
        <span @click="handleSelection($event, result)">
          {{ result }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    name: { type: String, default: null },
    dataTestId: { type: String, default: null },
    modelValue: { type: String, default: null },
    required: { type: Boolean, default: null },
    placeholder: { type: String, default: null },
    searchOptions: { type: Array, default: null },
    onSelect: { type: Function, default: null }
  },
  emits: ['update:modelValue', 'onSelect'],
  data() {
    return {
      open: false,
      selectedOption: null,
      searchResults: []
    }
  },
  watch: {
    open(value) {
      if (value) {
        document.addEventListener('mousedown', this.handleClickOutside)
      } else {
        document.removeEventListener('mousedown', this.handleClickOutside)
      }
    },
    selectedOption(v) {
      this.$emit('update:modelValue', v)
    }
  },
  mounted() {
    this.selectedOption = this.modelValue
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      if (this.$el == null) return
      for (const child of this.$el.children) {
        const b = child.getBoundingClientRect()
        if (event.clientX >= b.left && event.clientX < b.right && event.clientY >= b.top && event.clientY < b.bottom) return
      }
      this.open = false
    },
    getSearchResults(e) {
      this.selectedOption = e.target.value
      const searchResults = this.searchOptions.filter(v => v.includes(e.target.value))
      if (searchResults.length) {
        this.open = true
        this.searchResults = searchResults
      }
    },
    handleSelection(e, v) {
      e.preventDefault()
      e.stopPropagation()
      this.searchResults = this.searchOptions
      this.selectedOption = v
      this.$emit('onSelect', v)
      this.open = false
    }
  }
}
</script>
<style lang="scss">
.lookupField {
  position: relative;
}
</style>
