<template>
  <Modal :closable="true" @close="close">
    <div class="logoutModal">
      <h1>{{ t['logout'] }}</h1>
      <p>{{ t['comfirm_logout'] }}</p>
      <p class="bold">{{ t['login_back'] }}</p>
      <button class="main" aria-label="Logout" @click="close(true)">{{ t['logout'] }}</button>
      <button @click="close(false)">{{ t['cancel'] }}</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import { langMixin } from '../lang'

export default {
  name: 'SeezLogoutModal',
  components: { Modal },
  mixins: [langMixin('LOGOUT_COMPONENT_TRANSLATIONS')],
  emits: ['close'],
  methods: {
    close(response) {
      this.$emit('close', response)
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.logoutModal {
  @include theme;

  display: grid;
  gap: 1em;
  justify-items: stretch;
  margin: 0 auto;
  max-width: 30ch;

  > h1 {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0;
  }

  > p {
    font-size: 1em;
    margin: 0;

    &.bold {
      font-weight: bold;
    }
  }

  > button.main {
    --highlight: #ff0009;
    @include primaryButton;
  }

  > button:not(.main) {
    --highlight: #757575;
    @include secondaryButton;
  }

  > button {
    font-size: min(1.1em, 4.5vw);
    font-weight: bold;
  }

  @media (min-width: 30rem) {
    padding: 1em;
  }
}
</style>
