<template>
  <div class="customerInfo">
    <div>
      <span v-if="formFields.coOwner">{{ t.howManyOwners ?? 't.howManyOwners' }}</span>
      <div v-if="formFields.coOwner" class="selector">
        <input type="radio" id="singleOwner" value="singleOwner" v-model="input.numberOfOwners" />
        <label for="singleOwner"> {{ t.singleOwner ?? 't.singleOwner' }} </label>
        <input type="radio" id="dualOwner" value="dualOwner" v-model="input.numberOfOwners" />
        <label for="dualOwner"> {{ t.dualOwner ?? 't.dualOwner' }} </label>
      </div>
    </div>
    <div class="singleOwner">
      <span>{{ t.singleOwnerDetails ?? 't.singleOwnerDetails' }}</span>
      <FormTemplate :preloaded-data="customerInfoModel" :fields="formFields.customer" :errors="appState.state === 'incomplete' ? appState?.errorsObject.filter(e => e.key !== 'co-owner') : null" @updated="updateCustomerInput" />
      <span v-if="formFields.customerAddressLookup" class="formAddressLabel">{{ t.address ?? 't.address' }}</span>
      <FormTemplateAddress v-if="formFields.customerAddressLookup" :order-id="order.id" :preloaded-data="customerInfoModel?.address" :address-lookup-field="formFields.customerAddressLookup" @updated="updateCustomerAddressInput" />
    </div>
    <div v-if="input.numberOfOwners === 'dualOwner' && formFields.coOwner" class="dualOwner">
      <span>{{ t.dualOwnerDetails ?? 't.dualOwnerDetails' }}</span>
      <FormTemplate :preloaded-data="coOwnerInfoModel" :fields="formFields.coOwner" :errors="appState.state === 'incomplete' ? appState?.errorsObject?.find(e => e.key === 'co-owner')?.value?.nestedErrors : null" @updated="updateCoOwnerInput" />
      <span v-if="formFields.coOwnerAddressLookup" class="formAddressLabel">{{ t.address ?? 't.address' }}</span>
      <FormTemplateAddress v-if="formFields.coOwnerAddressLookup" :order-id="order.id" :preloaded-data="coOwnerInfoModel?.address" :address-lookup-field="formFields.coOwnerAddressLookup" @updated="updateCoOwnerAddressInput" />
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../sdk.js'
import { langMixin } from '../../lang'
import { mergeObjects } from '../../../logic.js'
import FormTemplate from '@/components/FormTemplate/FormTemplate.ce.vue'
import FormTemplateAddress from '@/components/FormTemplateAddress/FormTemplateAddress.ce.vue'

const STANDARD_PAYLOAD = ['firstName', 'middleName', 'lastName', 'email', 'phone', 'nationalID', 'age', 'address', 'insurance', 'insuranceDetails', 'dateOfBirth', 'customAttributes', 'coOwnerInfo', 'id', 'streetName', 'streetNumber', 'door', 'floor', 'buildingNumber', 'street', 'street2', 'postalCode', 'province', 'city', 'zone', 'state', 'country', 'dawaUrl', 'data']

export default {
  name: 'CustomerInfo',
  components: { FormTemplate, FormTemplateAddress },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true }
  },
  emits: ['persistInput'],
  data() {
    let customerDataModel = this.order.customerInfo
    let coOwnerDataModel = this.order.coOwnerInfo

    const customerFields = this.order.listing.owner.layoutTemplates.find(sc => sc.name === 'customerInfo')?.fields
    let customerAddressLookup = customerFields?.find(f => f.name === 'address')

    if (customerAddressLookup?.type !== 'addressLookup') {
      customerFields.push(...customerAddressLookup.fields.map(f => ({ ...f, parent: 'address' })))
      customerDataModel = this.formatData(customerDataModel, customerFields)
      customerAddressLookup = null
    }

    const coOwnerFields = customerFields.find(f => f.name === 'coOwnerInfo')?.fields
    let coOwnerAddressLookup = coOwnerFields?.find(f => f.name === 'address')
    if (coOwnerFields && coOwnerAddressLookup && coOwnerAddressLookup?.type !== 'addressLookup') {
      coOwnerFields.push(...coOwnerAddressLookup.fields.map(f => ({ ...f, parent: 'address' })))
      coOwnerDataModel = this.formatData(coOwnerDataModel, coOwnerFields)
      coOwnerAddressLookup = null
    }

    return {
      formFields: {
        customer: customerFields,
        customerAddressLookup: customerAddressLookup,
        coOwner: coOwnerFields,
        coOwnerAddressLookup: coOwnerAddressLookup
      },
      customerInfoModel: customerDataModel,
      coOwnerInfoModel: coOwnerDataModel,
      input: {
        customerInfo: { ...this.order.customerInfo },
        coOwnerInfo: this.order.coOwnerInfo ? { ...this.order.coOwnerInfo } : null,
        numberOfOwners: this.order.coOwnerInfo !== null ? 'dualOwner' : 'singleOwner'
      }
    }
  },
  computed: {
    appState() {
      return this.order?.appState?.steps?.find(s => s.name === 'customerInfo')
    }
  },
  watch: {
    input: {
      deep: true,
      handler: function () {
        this.$emit('persistInput', { ...this.input })
      }
    }
  },
  methods: {
    formatData(dataModel, fields) {
      let formattedDataModel = {}
      if (dataModel) {
        for (const field of fields) {
          if (!STANDARD_PAYLOAD.includes(field.name) && dataModel.customAttributes) {
            formattedDataModel[field.name] = dataModel.customAttributes.find(ca => ca.key === field.name)?.value
          } else if (field.parent) {
            formattedDataModel[field.name] = formattedDataModel[field.name] ? formattedDataModel[field.name] : dataModel[field.parent][field.name]
            if (field.type === 'lookup' && dataModel[field.parent][field.name] && field.function && window[field.function]) {
              formattedDataModel = window[field.function](formattedDataModel)
            }
          } else {
            formattedDataModel[field.name] = dataModel[field.name]
          }
        }
      }
      return formattedDataModel
    },
    updateCustomerInput(formInput) {
      let customerFormInput = {}
      let addressFormInput
      let customAttributes
      for (const field of this.formFields.customer) {
        if (field.parent) {
          if (!addressFormInput) {
            addressFormInput = {}
          }
          let newObj = {}
          newObj[field.name] = formInput[field.name]
          addressFormInput[field.parent] = { ...addressFormInput[field.parent], ...newObj }
        } else {
          if (STANDARD_PAYLOAD.includes(field.name)) {
            customerFormInput[field.name] = formInput[field.name]
          } else {
            if (!customAttributes) {
              customAttributes = []
            }
            customAttributes.push({ key: field.name, value: formInput[field.name] })
          }
        }
      }

      this.input.customerInfo = mergeObjects(this.input.customerInfo, { ...customerFormInput })

      if (customAttributes) {
        this.input.customerInfo.customAttributes = customAttributes.map(ca => {
          return { key: ca.key, value: ca.value }
        })
      }

      if (addressFormInput) {
        this.updateCustomerAddressInput({ ...addressFormInput.address })
      }
    },
    updateCustomerAddressInput(formInput) {
      this.input.customerInfo.address = mergeObjects(this.input.customerInfo.address, { ...formInput })
    },
    updateCoOwnerInput(formInput) {
      let coOwnerFormInput = {}
      let addressFormInput
      let customAttributes
      for (const field of this.formFields.coOwner) {
        if (field.parent) {
          if (!addressFormInput) {
            addressFormInput = {}
          }
          let newObj = {}
          newObj[field.name] = formInput[field.name]
          addressFormInput[field.parent] = { ...addressFormInput[field.parent], ...newObj }
        } else {
          if (STANDARD_PAYLOAD.includes(field.name)) {
            coOwnerFormInput[field.name] = formInput[field.name]
          } else {
            if (!customAttributes) {
              customAttributes = []
            }
            customAttributes.push({ key: field.name, value: formInput[field.name] })
          }
        }
      }

      this.input.coOwnerInfo = mergeObjects(this.input.coOwnerInfo, { ...coOwnerFormInput })

      if (customAttributes) {
        this.input.coOwnerInfo.customAttributes = customAttributes.map(ca => {
          return { key: ca.key, value: ca.value }
        })
      }

      if (addressFormInput) {
        this.updateCoOwnerAddressInput({ ...addressFormInput.address })
      }
    },
    updateCoOwnerAddressInput(formInput) {
      this.input.coOwnerInfo.address = mergeObjects(this.input.coOwnerInfo.address, { ...formInput })
    }
  }
}
</script>

<style lang="scss">
.customerInfo {
  @media screen and (max-width: 36rem) {
    .formTemplate .formContainer {
      display: flex;
      flex-direction: column;
    }
    .dualOwner {
      margin-top: 1rem;
    }
  }

  .singleOwner .formAddressLabel,
  .dualOwner .formAddressLabel {
    display: none !important;
  }
}
</style>
