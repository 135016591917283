<template>
  <button class="contactButton" @click="onClick">{{ buttonLabel || t.contactUs }}</button>
</template>
<script>
import { langMixin } from '@/components/lang.js'
import { analyticsMixin } from '@/analytics.js'

export default {
  name: 'ContactButton',
  mixins: [langMixin('general'), analyticsMixin],
  props: {
    id: { type: String, default: '' },
    url: { type: String, default: '' },
    type: { type: String, default: '' },
    label: { type: String, default: '' }
  },
  computed: {
    buttonLabel() {
      return this.label
    }
  },
  methods: {
    trackClick() {
      if (this.id && this.type) {
        this.track('chat_contact_button_click',  { id: this.id, type: this.type })
      }
    },
    onClick() {
      if (this.url) {
        const link = document.createElement('a')
        link.href = this.url
        link.rel = 'noopener noreferrer'
        link.click()
        this.trackClick()
      }
    }
  }
}
</script>
<style>
.contactButton {
  padding: 0.8em 1.5em;
  border-radius: 1.5em;
  border-radius: var(--cta-border-radius);
  cursor: pointer;
  font-size: 0.8em;
  font-weight: 700;
  background-color: var(--cta-bg-color);
  color: var(--cta-text-color);
  opacity: 0.75;
  border: none;
  display: block;
  font-family: var(--font-family), sans-serif;
}

.contactButton:hover {
  opacity: 0.5;
  transition: opacity 1s;
}
</style>
