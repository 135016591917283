<template>
  <div :data-test-id="testId" class="multiselect" :class="{ open: open }" @keydown="keyPressed">
    <button type="button" aria-haspopup="true" :aria-expanded="open.toString()" @click="open = !open">
      <span :class="selectedItem">{{ selectedName }}</span>
      <svg width="10" height="6" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 1.56 7.53 7.28C7.34 7.44 7.16 7.5 7 7.5 6.81 7.5 6.63 7.44 6.47 7.31L.47 1.56C.16 1.28.16.78.44.5.72.19 1.22.19 1.5.47L7 5.72 12.47.47C12.75.19 13.25.19 13.53.5 13.81.78 13.81 1.28 13.5 1.56Z" fill="#757575" />
      </svg>
    </button>
    <input type="hidden" :name="name" :value="externalValue" />
    <ul v-if="open && options.length > 0" :data-test-id="carID">
      <li v-if="internalValue.length > 0 && !required" :key="null" class="clear" @click="clearAll">
        <span>{{ clearPlaceholder }}</span>
      </li>
      <li v-for="o in internalOptions" :key="o.id" :class="{ multi: multi, selected: internalValue && internalValue.includes(o.id), parent: o.children && o.children.length > 0, preselected: preSelected === o.id }" :data-id="o.id">
        <!-- Collapsible section if it's needed. Set the attribute where the component was included. Example :collapsible="true" -->
        <template v-if="collapsible">
          <details class="dropdownAccordion" :open="setOpenDetails(o.name)">
            <summary class="dropdownAccordion--summary" @focus="preSelected = o.id">
              <span tabindex="0" @click="select($event, o.id)">
                {{ o.name }}
                <b v-if="o.count > 0">{{ o.count }}</b>
              </span>
            </summary>
            <ul v-if="o.children">
              <li v-for="c in o.children" :key="c.id" :class="{ multi: multi, selected: internalValue && (internalValue.includes(c.id) || internalValue.includes(o.id)), preselected: preSelected === c.id }" :data-id="c.id">
                <span tabindex="0" @click="select($event, c.id)" @focus="preSelected = c.id">
                  {{ c.name }}
                  <b v-if="c.count > 0">{{ c.count }}</b>
                </span>
              </li>
            </ul>
          </details>
        </template>
        <!-- Otherwise use the default behavior -->
        <template v-else>
          <span tabindex="0" @click="select($event, o.id)" @focus="preSelected = o.id">
            {{ o.name }}
            <b v-if="o.count > 0">{{ o.count }}</b>
          </span>
          <ul v-if="o.children">
            <li v-for="c in o.children" :key="c.id" :class="{ multi: multi, selected: internalValue && (internalValue.includes(c.id) || internalValue.includes(o.id)), preselected: preSelected === c.id }" :data-id="c.id">
              <span tabindex="0" @click="select($event, c.id)" @focus="preSelected = c.id">
                {{ c.name }}
                <b v-if="c.count > 0">{{ c.count }}</b>
              </span>
            </li>
          </ul>
        </template>
        <div v-if="o.name === 'Other' && otherRegion" class="otherSelect"><InfoIcon /> {{ otherRegion }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import InfoIcon from '@/assets/information.svg'

export default {
  components: { InfoIcon },
  props: {
    name: { type: String, default: null },
    options: { type: Array, required: true },
    modelValue: { type: [String, Number, Array], default: null },
    multi: { type: Boolean, default: true },
    placeholder: { type: String, default: 'Select an option' },
    clearPlaceholder: { type: String, default: 'Clear' },
    required: { type: Boolean, default: false },
    isDefaultSelected: { type: Boolean, default: false },
    testId: { type: String, default: null },
    carID: { type: String, default: null },
    collapsible: { type: Boolean, default: false },
    otherRegion: { type: String, default: '' }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      open: false,
      internalValue: [],
      searchText: '',
      searchTimeout: null,
      preSelected: null
    }
  },
  computed: {
    internalOptions() {
      if (typeof this.options === 'object' && !Array.isArray(this.options)) return Object.entries(this.options).map(p => ({ id: p[0], name: p[1] }))
      else
        return this.options.map(o => {
          if (typeof o === 'object') return o
          else return { id: o, name: o }
        })
    },
    externalValue() {
      if (this.modelValue == null) return []
      if (Array.isArray(this.modelValue)) return this.modelValue
      return [this.modelValue]
    },
    selectedName() {
      if (this.internalValue == null || this.internalValue.length === 0) return this.placeholder
      const names = []
      for (const o of this.internalOptions) {
        if (this.internalValue.some(v => v == o.id)) names.push(o.name)
        if (o.children?.length > 0)
          for (const c of o.children) {
            if (this.internalValue.some(v => v == c.id)) names.push(c.name)
          }
      }
      return names.length > 0 ? names.join(', ') : this.placeholder
    },
    flattenIds() {
      return this.internalOptions.flatMap(o => [o.id, ...(o.children?.map(c => c.id) ?? [])])
    },
    selectedItem() {
      return (this.internalValue.length === 0 && this.preSelected === null) || this.isDefaultSelected ? '' : 'selected'
    }
  },
  watch: {
    open(value) {
      if (value) {
        document.addEventListener('mousedown', this.handleClickOutside)
        this.$nextTick(() => {
          const listItem = this.$el.querySelector('li.selected')
          if (listItem == null) return
          listItem.scrollIntoView({ block: 'nearest', inline: 'nearest' })
          listItem.children[0].focus()
        })
      } else {
        document.removeEventListener('mousedown', this.handleClickOutside)
      }
    },
    internalValue(v) {
      if (v.length > 1 && !this.multi) throw new Error('Multiple values for single selector')

      let newValue = v
      if (!this.multi) newValue = v.length === 0 ? null : v[0]
      if (newValue !== this.modelValue) this.$emit('update:modelValue', newValue)
    },
    externalValue(v) {
      this.internalValue = v
    },
    searchText(v) {
      if (v) this.focusInOption(v)
    },
    preSelected(v) {
      if (v == null) return
      this.$nextTick(() => {
        const listItem = this.$el.querySelector(`li[data-id="${v}"]`)
        listItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
        listItem.children[0].focus()
      })
    }
  },
  beforeMount() {
    this.internalValue = this.externalValue
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  },
  methods: {
    setOpenDetails(name) {
      return name?.toLowerCase()?.match('default')?.length ? true : false
    },
    clearAll() {
      this.internalValue = []
      this.preSelected = null
      if (!this.multi) this.open = false
    },
    select(e, id) {
      if (id == null) return
      e.preventDefault()
      e.stopPropagation()

      if (!this.multi) this.internalValue = [id]
      else if (!this.internalValue.includes(id) && this.getParentId(id) != null && this.internalValue.includes(this.getParentId(id))) {
        const pId = this.getParentId(id)
        const newValue = this.internalValue.filter(v => v != pId)
        const sibilins = this.internalOptions
          .find(p => p.id === pId)
          .children.map(c => c.id)
          .filter(c => c != id)
        newValue.push(...sibilins)
        newValue.sort()
        this.internalValue = newValue
      } else if (this.internalValue.includes(id)) {
        this.internalValue = this.internalValue.filter(i => i !== id)
      } else {
        const children = this.internalOptions.find(p => p.id === id)?.children?.map(c => c.id) ?? []
        let newValue = [...this.internalValue, id].filter(v => !children.includes(v))
        const pId = this.getParentId(id)
        if (pId != null) {
          const sibilins = this.internalOptions.find(p => p.id === pId).children.map(c => c.id)
          if (sibilins.every(s => newValue.includes(s))) {
            newValue = newValue.filter(v => !sibilins.includes(v))
            newValue.push(pId)
          }
        }
        newValue.sort()
        this.internalValue = newValue
        this.$nextTick(() => this.$el.querySelector('button[aria-haspopup="true"]').focus())
      }
      this.preSelected = null
      if (!this.multi) this.open = false
    },
    getParentId(id) {
      return this.internalOptions.find(p => p?.children?.some(c => c.id === id))?.id
    },
    handleClickOutside(event) {
      //the normal way to check for "click outside" does not work through shadow-dom, so here we go
      if (this.$el == null) return
      for (const child of this.$el.children) {
        const b = child.getBoundingClientRect()
        if (event.clientX >= b.left && event.clientX < b.right && event.clientY >= b.top && event.clientY < b.bottom) return
      }
      this.open = false
    },
    keyPressed(e) {
      if (!this.open) return
      else if (e.key === 'Escape') this.open = false
      else if (e.key === 'Enter' && this.preSelected) {
        this.select(this.preSelected)
        e.preventDefault()
        e.stopPropagation()
      } else if (e.key === 'ArrowDown') {
        if (this.preSelected == null || this.preSelected === this.flattenIds[this.flattenIds.length - 1]) this.preSelected = this.flattenIds[0]
        else this.preSelected = this.flattenIds[this.flattenIds.indexOf(this.preSelected) + 1]
        e.preventDefault()
        e.stopPropagation()
      } else if (e.key === 'ArrowUp') {
        if (this.preSelected == null || this.preSelected === this.flattenIds[0]) this.preSelected = this.flattenIds[this.flattenIds.length - 1]
        else this.preSelected = this.flattenIds[this.flattenIds.indexOf(this.preSelected) - 1]
        e.preventDefault()
        e.stopPropagation()
      } else if (e.key.length === 1) {
        this.searchText += e.key.toLowerCase()
        if (this.searchTimeout) clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(this.clearSearch, 2000)
      }
    },
    clearSearch() {
      this.searchText = ''
      this.searchTimeout = null
    },
    focusInOption(partial) {
      for (const o of this.internalOptions) {
        if (o.name.toLowerCase().startsWith(partial)) {
          this.preSelected = o.id
          return
        }
        if (o.children) {
          for (const c of o.children) {
            if (c.name.toLowerCase().startsWith(partial)) {
              this.preSelected = c.id
              return
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.multiselect {
  position: relative;

  > button {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75em 2.75em 0.75em 1.75em;
    margin: 0;
    background: var(--background);
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    font-family: var(--base-font);
    font-size: 0.75rem;
    cursor: pointer;
    text-align: start;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #757575;

    @media screen and (max-width: 42rem) {
      font-size: 1em;
    }

    .selected {
      color: black;
      font-weight: 400;
    }

    > svg {
      position: absolute;
      inset-inline-end: 1em;
      transition: transform 50ms;
    }
  }

  ul {
    box-sizing: border-box;
    list-style-type: none;
    margin: 0;
    padding-inline-start: 1em;

    > li {
      margin: 0;
      padding: 0;
      position: relative;
      font-family: var(--base-font);
      font-weight: 500;
      color: black;

      &:not(:last-child) {
        border-bottom: 1px solid #efefef;
      }

      &.clear {
        font-style: italic;
        color: #757575;
        font-weight: 400;
        font-size: 0.9em;
      }

      &.multi.selected::after {
        content: '◉';
        position: absolute;
        top: 0.25em;
        inset-inline-end: 0.25em;
        color: var(--highlight);
      }

      span {
        padding: 0.25em;
        border-radius: 1em;
        cursor: pointer;
        display: block;

        > b {
          color: var(--background);
          background-color: var(--highlight);
          opacity: 0.6;
          margin-inline-start: 0.75em;
          line-height: 1em;
          font-weight: normal;
          font-size: 0.7em;
          padding: 0.2em 0.5em;
          border-radius: 2em;
        }
      }

      span:hover,
      &.selected:not(.multi) span {
        color: var(--highlight);
        background-color: #cce1ff;
      }

      > details.dropdownAccordion {
        > ul {
          margin-inline-start: 0.625rem;
        }
        > summary {
          list-style: none;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &::marker,
          &::-webkit-details-marker {
            display: none;
          }

          &::before {
            cursor: pointer;
            content: '+';
            font-size: 1.2rem;
            font-weight: bold;
            width: 1.2rem;
            height: 1.2rem;
            line-height: 1.2rem;
            display: flex;
            align-self: center;
            color: currentColor;
          }
          span {
            flex-grow: 1;
          }
        }

        &.dropdownAccordion[open] summary:before {
          content: '-';
          margin-block-start: -4px;
        }
      }
    }
  }

  > ul {
    position: absolute;
    inset-inline-start: 0;
    top: 2.5rem;
    padding: 0.5em;
    box-shadow: 1px 4px 12px 4px rgba(204, 225, 255, 0.34);
    border-radius: 1.2em;
    max-height: 17em;
    overflow: auto;
    overscroll-behavior: contain;
    font-size: 0.9rem;
    scrollbar-width: 0;
    z-index: 1;
    background-color: var(--background);
    text-align: start;
    line-height: 2.5em;
    text-indent: 0.5em;
    border: 1px solid var(--highlight);
    width: max-content;
    min-width: 100%;

    @media (min-width: 48rem) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.open {
    > button {
      border-color: var(--highlight);

      > svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
