<template>
  <div class="formTemplateAddress">
    <div>
      <div class="formContainer">
        <div :style="{ 'grid-area': addressLookupField?.area }" class="dynamicInputField">
          <div>
            <label>
              {{ t.addressLookup ?? 't.addressLookup' }}
              <input v-model="searchText" type="text" :placeholder="t[addressLookupField.name] ?? addressLookupField.name" />
              <ul v-if="searchResults?.length">
                <li v-for="res in searchResults" :key="res" @click="selectAddress(res)">
                  {{ res.address?.street ?? res.searchText }}
                </li>
              </ul>
            </label>
          </div>
        </div>
        <div v-for="field in addressLookupField?.fields?.filter(f => f?.visible !== false)" :key="field.name" :style="{ 'grid-area': field.area }">
          <div v-if="field.visible !== false">
            <label>
              {{ t[field.name] ?? field.name }}
              <input v-model="dataModel[field.name]" :required="field.required" :type="field.type" :placeholder="t[field.name] ?? field.name" />
            </label>
          </div>
          <div v-if="field.validationError">{{ field.validationError }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'

export default {
  name: 'FormTemplateAddress',
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    orderId: { type: String, required: true },
    addressLookupField: { type: Object, required: true },
    preloadedData: { type: Object, default: null }
  },
  emits: ['updated'],
  data() {
    return {
      loading: false,
      dataModel: { ...this.preloadedData },
      searchText: this.preloadedData?.street,
      searchResults: [],
      address: null
    }
  },
  watch: {
    searchText: {
      deep: true,
      handler: function () {
        this.lookupAddresses()
      }
    },
    dataModel: {
      deep: true,
      handler: function () {
        this.$emit('updated', { ...this.dataModel })
      }
    }
  },
  methods: {
    async lookupAddresses() {
      try {
        this.loading = true
        const query = `query lookupAddresses($orderId: ID!, $addressLookup: AddressLookupInput!) {
          searchExternalAddresses(
            orderId: $orderId,
            addressLookup: $addressLookup) {
            searchText
            address {
              streetName
              streetNumber
              door
              floor
              street
              street2
              postalCode
              city
              state
              country
              data
            }
          }
        }`
        const { searchExternalAddresses } = await this.queryApi(query, {
          orderId: this.orderId,
          addressLookup: {
            searchText: this.searchText,
            address: this.address
          }
        })
        this.searchResults = searchExternalAddresses
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    selectAddress(address) {
      this.searchText = address.searchText
      this.address = address.address

      if (this.address) {
        this.dataModel = address.address
        this.searchResults = []
      }
    }
  }
}
</script>

<style lang="scss">
@import './../../base';

.formTemplateAddress {
  @include theme;

  --fieldsPerRow: 6;

  .formContainer {
    display: grid;
    grid-template-columns: repeat(var(--fieldsPerRow), 1fr);

    > div > div {
      > label {
        display: flex;
        flex-direction: column;
      }

      > div.question {
        > label {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
