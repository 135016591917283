<template>
  <div class="legalDocumentsUpload" v-if="order">
    <Loader v-if="loading" />
    <DocumentSection v-for="doc in requiredDocuments" :key="doc.type" :order-id="order.id" :required-document="doc" :documents="documents" @file-selected="onFileSelected" @documents-removed="onDocumentsRemoved" />
  </div>
</template>

<script>
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import DocumentSection from './DocumentSection.ce.vue'

export default {
  name: 'LegalDocumentsUpload',
  components: { DocumentSection, Loader },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true },
    requiredDocuments: { type: Object, required: true }
  },
  emits: ['documentsUpdated'],
  data() {
    return {
      loading: false,
      documents: this.order.documents
    }
  },
  methods: {
    async onFileSelected(files, documentType) {
      try {
        this.loading = true

        const uploads = await Promise.all([...files].map(f => window.seezSdk.uploadOrderDocument(this.order.id, f, documentType)))
        this.updateOrder([...this.order.documents, ...uploads])
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    onDocumentsRemoved(e) {
      const names = e.map(x => x.name)
      this.updateOrder(this.order.documents.filter(d => !names.includes(d.name)))
    },
    async updateOrder(docs) {
      this.loading = true

      try {
        const query = 'mutation uploadDocuments($orderId: ID!, $documents: [OrderDocumentInput]) { updateOrderDocuments(orderId: $orderId, documents: $documents) { documents { name fileName fileSize fileType createdOn type }}}'
        const {
          updateOrderDocuments: { documents }
        } = await this.queryApi(query, { orderId: this.order.id, documents: docs })

        this.documents = documents
        this.$emit('documentsUpdated', this.documents)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
