<template>
  <div class="tradeInDetailCard" @click="select">
    <div v-if="tradeIn">
      <div class="radio">
        <div :class="{ selected: selected }" />
      </div>
      <div class="tradeInDetail">
        <div class="title">
          <span>
            {{ tradeIn.make }} {{ tradeIn.model }}
            <button :disabled="!['pending', 'inReview'].includes(tradeIn.state)" class="editTradeIn" data-test-id="editTradeIn" @click.stop="edit">{{ t.editInfo ?? 't.editInfo' }}</button>
          </span>
          <div v-if="tradeIn.offerAmount || Number(tradeIn.maxExternalValuationPrice)">
            <span class="fieldName">{{ t.offer ?? 't.offer' }}:</span>
            <span>{{ formatNumber(tradeIn.offerAmount ?? tradeIn.maxExternalValuationPrice, 0, tradeIn.currency) }}</span>
          </div>
        </div>
        <div v-if="tradeInIncomplete" class="statusLabel">
          <CircleIcon />
          <p>{{ t.incomplete || 't.incomplete' }}</p>
        </div>
        <br />
        <div class="details">
          <div class="spec" v-if="tradeIn.registrationDate && new Date(tradeIn.registrationDate)">
            <CalendarIcon />
            <div>
              <span class="fieldName">{{ t.registrationDate ?? 't.registrationDate ' }}</span>
              <span>{{ `${new Date(tradeIn.registrationDate).getMonth() + 1} / ${new Date(tradeIn.registrationDate).getFullYear()}` }}</span>
            </div>
          </div>
          <div class="spec" v-if="tradeIn.kilometrage">
            <MileageIcon />
            <div>
              <span class="fieldName">{{ t.mileage ?? 't.mileage' }}</span>
              <span>{{ tradeIn.kilometrage?.toLocaleString(language) }} {{ t['km'] }}</span>
            </div>
          </div>
          <div class="spec" v-if="tradeIn.seats">
            <PowerIcon />
            <div>
              <span class="fieldName">{{ t.seats ?? 't.seats' }}</span>
              <span>{{ tradeIn.seats }}</span>
            </div>
          </div>
          <div class="spec" v-if="tradeIn.fuelType">
            <FuelTypeIcon />
            <div>
              <span class="fieldName">{{ t.fuelType ?? 't.fuelType' }}</span>
              <span>{{ tradeIn.fuelType }}</span>
            </div>
          </div>
          <div class="spec" v-if="tradeIn.engine">
            <EngineIcon />
            <div>
              <span class="fieldName">{{ t.engine ?? 't.engine' }}</span>
              <span>{{ tradeIn.engine }}</span>
            </div>
          </div>
          <div class="spec" v-if="tradeIn.transmission">
            <GearIcon />
            <div>
              <span class="fieldName">{{ t.transmission ?? 't.transmission' }}</span>
              <span>{{ tradeIn.transmission }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="viewDetails">
          <button @click="onViewDetails">{{ t.viewDetails ?? 't.viewDetails' }}</button>
        </div> -->
      </div>
    </div>
    <div v-else>
      <div class="radio">
        <div :class="{ selected: selected }" />
      </div>
      <div class="tradeInDetail">
        <div class="title">
          <span>{{ t.newTradeIn ?? 't.newTradeIn' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../../sdk.js'
import { langMixin } from '../../../lang'
import CalendarIcon from '@/assets/calendar.svg'
import MileageIcon from '@/assets/mileage.svg'
import FuelTypeIcon from '@/assets/fueltype.svg'
import CircleIcon from '@/assets/dot.svg'
import EngineIcon from '@/assets/engine.svg'
import PowerIcon from '@/assets/thunder-icon.svg'
import GearIcon from '@/assets/gear.svg'

export default {
  name: 'TradeInDetailCard',
  components: { CalendarIcon, MileageIcon, FuelTypeIcon, CircleIcon, EngineIcon, PowerIcon, GearIcon },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    tradeIn: { type: Object, default: null },
    selected: { type: Boolean, default: false }
  },
  emits: ['onSelect', 'onViewDetails', 'editTradeIn'],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    tradeInIncomplete() {
      return ['pending', 'draft', 'inReview'].includes(this.tradeIn.state)
    }
  },
  methods: {
    select() {
      this.$emit('onSelect')
    },
    onViewDetails() {
      this.$emit('onViewDetails')
    },
    edit() {
      this.$emit('editTradeIn', this.tradeIn.id)
    }
  }
}
</script>

<style lang="scss">
.tradeInDetailCard {
  > div {
    display: flex;
    gap: 1rem;

    > .radio {
      height: 1rem;
      width: 1rem;
      border: 1px solid;
      padding: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        height: 0.7rem;
        width: 0.7rem;

        &.selected {
          border: 1px solid;
        }
      }
    }

    > .tradeInDetail {
      width: 100%;

      > .statusLabel {
        display: flex;
        background-color: #ffa2001f;
        color: #cc8200;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: fit-content;
        padding: 0.3rem 0.625rem;
        border-radius: 20px;
        margin-top: 0.688rem;
        display: none;

        p {
          margin: 0;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }

      > .title {
        display: flex;
        justify-content: space-between;

        > span {
          display: flex;
          flex-direction: column;

          .editTradeIn {
            background-color: transparent;
            cursor: pointer;
            border: none;
            margin-top: 0.3rem;
            text-align: start;
            text-decoration: underline;
            display: none;

            &:active {
              opacity: 0.75;
            }

            &:disabled {
              cursor: not-allowed;
            }
          }
        }
      }

      > .details {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.6rem;

        > .spec {
          display: flex;
          align-items: center;
          gap: 0.6rem;
          // padding: 0.4rem;
          // border: 1px solid;

          > svg {
            width: 1rem;
            height: 1rem;
          }

          > div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
