<template>
  <div :class="['tradeinCard', tradein.state]">
    <img :src="picture" @error="imageError = true" />
    <div class="details pb">
      <div>
        <h3>{{ tradein.make }} &middot; {{ tradein.model }}</h3>
        <span>{{ tradein.variant }} &middot; {{ tradein.year }} &middot; {{ tradein.kilometrage?.toLocaleString(language) }} {{ t['km'] }}</span>
      </div>
      <div class="offerAmount">
        <span>{{ t['offer'] }}</span>
        <span v-if="tradein.offerAmount" class="big">{{ formatPrice({ value: tradein.offerAmount }, tradein.currency) }}</span>
        <div v-else>
          <span class="big">{{ t['pending'] }}</span>
        </div>
      </div>
      <span v-if="tradein.state !== 'ongoing'" class="status--absolute" :class="['status', tradein.state]">{{ t.states?.[tradein.state] || tradein.state }}</span>
      <div class="actionButtons">
        <button v-if="tradein.listingId" class="gotoButton" @click="goToListing(true)">
          {{ t['view_listing_details'] }}
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 0.5H11.25C11.6484 0.5 12 0.851562 12 1.25V5C12 5.42188 11.6484 5.75 11.25 5.75C10.8281 5.75 10.5 5.42188 10.5 5V3.07812L5.76562 7.78906C5.48438 8.09375 4.99219 8.09375 4.71094 7.78906C4.40625 7.50781 4.40625 7.01562 4.71094 6.73438L9.42188 2H7.5C7.07812 2 6.75 1.67188 6.75 1.25C6.75 0.851562 7.07812 0.5 7.5 0.5ZM1.875 1.25H4.5C4.89844 1.25 5.25 1.60156 5.25 2C5.25 2.42188 4.89844 2.75 4.5 2.75H1.875C1.66406 2.75 1.5 2.9375 1.5 3.125V10.625C1.5 10.8359 1.66406 11 1.875 11H9.375C9.5625 11 9.75 10.8359 9.75 10.625V8C9.75 7.60156 10.0781 7.25 10.5 7.25C10.8984 7.25 11.25 7.60156 11.25 8V10.625C11.25 11.6797 10.4062 12.5 9.375 12.5H1.875C0.820312 12.5 0 11.6797 0 10.625V3.125C0 2.09375 0.820312 1.25 1.875 1.25Z" fill="black" />
          </svg>
        </button>
        <button v-if="listingReserved" :class="['button', 'reserved']" :disabled="true">{{ t['listing_unavailable'] }}</button>
        <button v-if="canStartOrder" :class="['button', tradein.state]" @click="createOrder">{{ t['start_order'] }}</button>
        <button v-if="viewable" class="button" @click="viewDetails">{{ t['view_details'] }}</button>
        <button v-if="offerAvailable" class="button" @click="viewDetails">{{ t['view_offer'] }}</button>
        <button v-if="canSubmitNewTradein" :class="['button', 'buyerRejected']" @click="goToListing(false)">{{ t['submit_new_tradein'] }}</button>
      </div>
    </div>
    <Modal v-if="selectedTradein" @close="selectedTradein = null">
      <TradeInDetails :tradein-id="tradein.id" :tradein="tradein" @tradein-updated="tradeinUpdated" :to-listing="toListing" />
    </Modal>
  </div>
</template>

<script>
import { analyticsEvents } from '../../analytics.js'
import SeezSdk from '../../sdk'
import { langMixin } from '../lang'
import TradeInDetails from './TradeinDetails.ce.vue'
import Modal from '../Modal.ce.vue'

export default {
  name: 'SeezTradeinCard',
  components: { TradeInDetails, Modal },
  mixins: [langMixin('TRADEIN_CARD_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    tradein: { type: Object, required: true },
    // onTradeinUpdated: { type: Function, required: true },
    toCheckout: { type: String, required: true },
    toListing: { type: String, required: true },
    placeholder: { type: String, default: null }
  },
  emits: ['confirmCancel', 'createOrder'],
  data() {
    return {
      events: analyticsEvents,
      imageError: false,
      askConfirmation: false,
      loading: false,
      selectedTradein: null,
      buyerRejected: this.tradein.state === 'buyerRejected'
    }
  },
  computed: {
    listingReserved() {
      return this.tradein.state === 'accepted' && this.tradein.listing.state !== 'available'
    },
    canStartOrder() {
      return this.tradein.state === 'accepted' && !this.listingReserved && !this.buyerRejected
    },
    viewable() {
      return ['draft', 'pending', 'inReview', 'negotiation'].includes(this.tradein.state) && !this.buyerRejected
    },
    offerAvailable() {
      return ['offerSent'].includes(this.tradein.state) && !this.buyerRejected
    },
    canSubmitNewTradein() {
      return this.buyerRejected
    },
    picture() {
      const url = this.tradein?.pictures?.[0]?.url
      if (!url) return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      return `${import.meta.env.VITE_SEEZ_BASE_URL}/image/500x0/${url}`
    },
    listingUrl() {
      // starmark listing url /{brand}/{model}-{variant}/{id}/
      // marketplace listing url /listing/{id}/
      let listingUrl = this.toListing
      if (listingUrl.includes('{id}')) {
        listingUrl = listingUrl.replace('{id}', this.tradein.listingId)
      }
      if (listingUrl.includes('{brand}')) {
        listingUrl = listingUrl.replace('{brand}', this.tradein.listing.brand.name)
      }
      if (listingUrl.includes('{model}')) {
        listingUrl = listingUrl.replace('{model}', this.tradein.listing.model.name)
      }
      if (listingUrl.includes('{variant}')) {
        listingUrl = listingUrl.replace('{variant}', this.tradein.listing.variant)
      }

      return listingUrl
    }
  },
  methods: {
    savePageReferrer() {
      localStorage.setItem('page_referrer', window.location.href)
    },
    viewDetails() {
      this.selectedTradein = this.tradein
    },
    goToListing(newTab) {
      if (newTab) {
        window.open(this.listingUrl, '_blank').focus()
      } else {
        window.location = this.listingUrl
      }
    },
    tradeinUpdated(args) {
      if (args.newState === 'accepted') {
        this.$emit('createOrder', { ...args })
      } else {
        this.buyerRejected = args.newState === 'buyerRejected'
        this.selectedTradein = null
      }
    },
    createOrder() {
      this.$emit('createOrder', { tradeinId: this.tradein.id, listingId: this.tradein.listingId })
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.tradeinCard {
  @include theme;
  --statusTextColor: var(--highlight);
  --statusBackgroundColor: var(--accented-background);
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 0.75rem;
  position: relative;
  transition: transform 0.15s ease-in-out 0s;

  &:hover {
    box-shadow: 0 0 10px #b3b3b399;
  }

  &.buyerRejected,
  &.dealerRejected {
    --statusTextColor: #ff0009;
    --statusBackgroundColor: #ffd2d4;
  }

  &.accepted {
    --statusTextColor: #007b29;
    --statusBackgroundColor: #b3e1c2;
  }

  &.cancelled > img,
  &.dealerRejected > img,
  &.buyerRejected > img {
    border-color: #ff333a;
  }

  &.accepted > img {
    border-color: #007b29;
  }

  > img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-top: 6px solid var(--highlight);
  }

  &.cancelled > img {
    border-color: #ff333a;
  }

  h3 {
    margin: 0 0 1px;
    color: #000000;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;

    a {
      color: inherit;
      text-decoration: none;
    }

    .titleIcon {
      margin-inline-start: 0.313rem;
    }
  }

  .offerAmount {
    margin-block-start: 1.625rem;
    margin-block-end: 0.75rem;
    display: flex;
    flex-direction: row;
    gap: 0.438rem;
    align-items: center;
  }

  span {
    color: #333333;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.063rem;

    &.big {
      color: #000000;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &.status {
      border-radius: 2em;
      padding: 0.188rem 0.625rem;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.125rem;
      align-self: start;
      color: var(--statusTextColor);
      background-color: var(--statusBackgroundColor);
      text-transform: capitalize;

      &.ongoing {
        display: none;
      }

      &.draft,
      &.pending {
        display: none;
      }

      &.status--absolute {
        position: absolute;
        inset-inline-end: 1rem;
        inset-block-start: 1.25rem;
        z-index: 10;
        pointer-events: none;
      }
    }

    .confirmed {
      background-color: #b3e1c2;
      color: #007b29;
      border: #007b29;
    }
  }

  > .summary {
    color: var(--highlight);
    cursor: pointer;
    grid-area: 2 / 2;
    align-self: center;
  }

  > .details {
    padding: 0.875rem 1.063rem 0.313rem;
    &.pb {
      padding-block-end: 1.063rem;
    }

    .actionButtons {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      > .button {
        font-family: var(--font-family), sans-serif;
        text-decoration: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.532rem 0.5rem;
        border: none;
        border-radius: 2.75rem;
        background-color: var(--highlight);
        color: var(--background);
        cursor: pointer;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.188rem;

        &.reserved {
          opacity: 20%;
          cursor: auto;
        }

        &.buyerRejected {
          background-color: #ffffff;
          border: solid 1px var(--highlight);
          color: var(--highlight);
        }

        &.accepted {
          background-color: #007b29;
          color: #ffffff;
        }

        &.button--cancel {
          margin-block-start: 0.188rem;
          font-size: 0.75rem;
          background-color: #ffffff;
          color: #545454;

          &:hover {
            background-color: #f2f2f2;
          }

          svg {
            margin-inline-end: 0.25rem;
            margin-block-start: -2px;
            display: block;
          }
        }
      }

      > .gotoButton {
        font-family: var(--font-family), sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: #000000;
        font-size: 0.7rem;
        background-color: #f6f6f6;
        border: solid #00000018 1px;
        border-radius: 0.4rem;
        font-weight: 400;
        padding: 0.4rem;

        svg {
          margin-inline-end: 0.25rem;
          margin-block-start: -2px;
        }
      }
    }
  }
}
</style>
