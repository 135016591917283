<template>
  <div :class="['seezModalBackground', closing ? 'closing' : null]" v-if="open" @click="handleClickOutside">
    <div :class="['seezModal', position, closing ? 'closing' : null]" @cancel="cancelled" role="dialog">
      <slot />
      <button v-if="canClose" class="closer" aria-label="Close" @click.stop.prevent="closeRequested"><CrossIcon /></button>
    </div>
  </div>
</template>

<script>
import CrossIcon from '../assets/cross.svg'

export default {
  name: 'SeezModal',
  components: { CrossIcon },
  inheritAttrs: false,
  props: {
    closable: { type: [Boolean, String], default: true },
    position: { type: String, default: 'center' },
    clickableOutside: { type: Boolean, default: true }
  },
  emits: ['close'],
  data() {
    return {
      oldBodyOverflow: document.body.style.overflow,
      closing: false,
      open: true
    }
  },
  computed: {
    canClose() {
      if (typeof this.closable === 'string') return this.closable.toLocaleLowerCase() !== 'false'
      if (typeof this.closable === 'boolean') return this.closable
      return true
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
  },
  beforeUnmount() {
    document.body.style.overflow = this.oldBodyOverflow
    document.body.style.paddingRight = 0
  },
  methods: {
    handleClickOutside(e) {
      if (!this.clickableOutside) return
      this.clicked(e)
    },
    clicked(e) {
      if (e.target === this.$el && this.canClose) {
        e.preventDefault()
        this.closeRequested()
      }
    },
    cancelled(e) {
      e.preventDefault()
      if (this.canClose) this.closeRequested()
    },
    closeRequested() {
      setTimeout(() => {
        document.body.style.overflow = this.oldBodyOverflow
        this.open = false
        this.$emit('close')
      }, 300)
      this.closing = true
    }
  }
}
</script>

<style lang="scss">
@import '../base';

.seezModalBackground {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(4px) brightness(75%) saturate(50%);
  display: grid;
  place-items: center;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  z-index: 9999;

  &.closing {
    animation-name: fadeOut;
    opacity: 0;
  }
}

.seezModal {
  --br: 0.5em;

  background-color: white;
  padding: 1em;
  box-sizing: border-box;
  border: none;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: thin;
  text-align: center;
  overflow: auto;
  position: relative;

  @media (min-width: 30rem) {
    height: max-content;
    width: max-content;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    border-radius: var(--br);
    display: block;

    &.center {
      animation-name: fadeIn;
      animation-duration: 0.3s;

      &.closing {
        animation-name: fadeOut;
        opacity: 0;
      }
    }

    &.right {
      border-radius: var(--br) 0 0 var(--br);
      margin: 1rem 0 1rem auto;
      height: calc(100% - 2rem);
      animation-name: expandFromRight;
      animation-duration: 0.3s;

      &.closing {
        animation-name: collapseToRight;
        transform: translateX(100%);
      }
    }

    &.left {
      border-radius: 0 var(--br) var(--br) 0;
      margin: 1rem auto 1rem 0;
      height: calc(100% - 2rem);
      animation-name: expandFromLeft;
      animation-duration: 0.3s;

      &.closing {
        animation-name: collapseToLeft;
        transform: translateX(-100%);
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: var(--border-color) ccc;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--border-color) ccc;
  }

  > .closer {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    inset-inline-end: 0;
    inset-block-start: 0;
    font-size: 2em;
    line-height: 0.5em;
    margin: 0;
    padding: 0.75rem;
    z-index: 2;
    background-color: white;
    border-radius: 0 0 0 0.66em;

    > svg {
      height: 0.5em;
    }
  }

  &::backdrop {
    backdrop-filter: blur(4px) brightness(75%) saturate(50%);
    animation-name: fadeIn;
    animation-duration: 0.3s;
  }

  &.closing::backdrop {
    animation-name: fadeOut;
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes expandFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes collapseToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes expandFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes collapseToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
